import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import PageBreadcrumbs from 'components/pages/pageBreadcrumbs'
import ViewableByLink from 'components/pages/viewableByLink'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import { useSelector } from 'react-redux'

const I18N = i18nPath('views.pages')
const pencilIconPath = '/images/pencilIcon.svg'

const PageHeader = ({
  page,
}) => {
  const isArchived = page?.archived
  const hasEditPermission = page?.permittedActions?.edit
  const canEditPage = hasEditPermission && !isArchived
  const editPageLink = useWorkspaceUrls().generatePathToPage(page, 'edit')
  const workspace = useSelector(pageWorkspaceSlice.selectors.getWorkspace(page.pageWorkspaceId))

  return (
    <div className='PageHeader d-flex align-items-center justify-content-between py-3 px-5 background-color-white'>
      <PageBreadcrumbs workspace={workspace} page={page} />
      {canEditPage && (
        <div className='EditButtons d-none d-sm-flex flex-shrink-0 align-items-center gap-3 text-smallest'>
          <Link to={editPageLink} className='EditPageLink d-flex align-items-center m-0'>
            <CdnSvg src={pencilIconPath} className='PencilIcon mr-1' />
            <span className='font-weight-500' data-testid='cy_edit_page'>{I18N('edit')}</span>
          </Link>
          <ViewableByLink
            linkClassName='font-weight-500 align-items-start'
            page={page}
          />
        </div>
      )}
    </div>
  )
}

export default PageHeader
