import React, {
  useState, useRef, createRef, useEffect
} from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import getElementDimensions from 'utils/getElementDimensions'
import isMobile from 'utils/isMobile'

import Modal from 'components/common/modal'
import ScrollToSections from 'components/common/scrollToModal/scrollToSections'
import DesktopSectionHeaders from 'components/common/scrollToModal/desktopSectionHeaders'
import MobileSectionHeaders from 'components/common/scrollToModal/mobileSectionHeaders'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'

const I18NCommon = i18nPath('views.common')

const SCROLL_OFFSET = isMobile() ? 130 : 40

const ScrollToModal = ({
  className = undefined,
  isSaveButtonDisabled,
  isSaving,
  onClose,
  onSave,
  sections,
  visible,
}) => {
  const [currentSection, setCurrentSection] = useState(sections[0].id)
  const [isScrollingSectionActive, setIsScrollingSectionHovered] = useState(false)
  const headers = sections.map(section => ({ header: section.header, id: section.id }))
  const sectionRefs = useRef(sections.reduce((accum, current) => ({ ...accum, [current.id]: createRef() }), {}))
  const sectionsContainerRef = useRef()

  const handleOnChange = async (sectionId) => {
    await sectionsContainerRef.current.scrollTo({
      behavior: 'smooth',
      top: sectionRefs.current[sectionId].current.offsetTop - SCROLL_OFFSET,
    })

    setCurrentSection(sectionId)
  }

  useEffect(() => {
    // Allows cleanup fucntion to access it later (react-hooks/exhaustive-deps)
    const sectionsRef = sectionsContainerRef

    const handleScroll = () => {
      const scrollPosition = sectionsContainerRef.current.scrollTop + SCROLL_OFFSET // section headers' height

      // Returns an array in the form of:
      // [ "general", {current: div.mb-5}]
      const activeSection = Object.entries(sectionRefs.current).find(([_, ref]) => {
        const element = ref.current
        if (element) {
          const { offsetBottom, offsetTop } = getElementDimensions(element)
          return scrollPosition > offsetTop && scrollPosition < offsetBottom
        }
        return undefined // consistent return required by ESLint
      })

      // Only handle scroll if we're inside
      // the scrollable section. Otherwise
      // the navigation section will jump
      if (!isScrollingSectionActive) return

      if (activeSection && activeSection[0]) {
        setCurrentSection(activeSection[0])
      }
    }

    const element = sectionsRef.current
    element.addEventListener('scroll', handleScroll)
    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [isScrollingSectionActive])

  return (
    <Modal className={classNames('ScrollToModal', className)} size='xl' visible={visible} toggle={onClose}>
      <div className='d-flex'>
        <span className='d-none d-lg-inline'>
          <DesktopSectionHeaders
            headers={headers}
            handleOnChange={handleOnChange}
            currentSection={currentSection}
            onMouseEnter={() => setIsScrollingSectionHovered(false)}
          />
        </span>

        <div className='ScrollToSectionsContainer w-100'>
          <BootstrapModal.Header closeButton style={{ borderBottom: 'none' }} />
          <div className='d-block d-lg-none px-4 mb-5'>
            <MobileSectionHeaders currentSection={currentSection} headers={headers} handleOnChange={handleOnChange} />
          </div>
          <ScrollToSections
            currentSection={currentSection}
            sectionRefs={sectionRefs.current}
            headers={headers}
            sections={sections}
            sectionsContainerRef={sectionsContainerRef}
            onMouseEnter={() => setIsScrollingSectionHovered(true)}
            onTouchStart={() => setIsScrollingSectionHovered(true)}
            handleOnChange={handleOnChange}
          />

          <div className='ModalActions d-flex align-items-center justify-content-end pt-3 mb-3 mb-3 pr-3'>
            <CancelButton onClick={onClose} />

            <Button className='ml-4' onClick={onSave} disabled={isSaveButtonDisabled} showLoadingSpinner={isSaving}>
              {I18NCommon('save_changes')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ScrollToModal
