import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import appSignal from 'services/appSignal'

export const initialState = {
  calendars: [],
  meta: {
    isLoading: false,
  },
}

const calendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {
    setCalendars(state, action) {
      state.calendars = action.payload
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
  },
})

//
//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(calendarsSlice, {
  asyncActions: {
    admin: {
      fetchCalendars: () => async (dispatch) => {
        dispatch(calendarsSlice.actions.setIsLoading(true))

        try {
          const response = await API.admin.calendars.fetchCalendars()
          const calendars = response?.data?.calendars || []

          dispatch(calendarsSlice.actions.setCalendars(calendars))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(calendarsSlice.actions.setIsLoading(false))
        }
      },
    },
  },
})

_.assign(calendarsSlice, {
  selectors: {
    admin: {
      getMetaData: () => state => state.calendars.meta,

      getCalendars: () => state => state.calendars.calendars || [],
    },
  },
})

export default calendarsSlice
