import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import { isAudienceValid } from 'utils/audience'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import PageWorkspaceType from 'types/pageWorkspace'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.pages.manage_workspace_permissions_modal')

interface Props {
  workspaceId: string
  visible: boolean
  toggle: () => void
}

const ManageWorkspacePermissionsModal = ({
  workspaceId,
  visible = false,
  toggle = () => { },
}: Props) => {
  const dispatch = useDispatch()

  const { isSaving, isLoading } = useSelector(pageWorkspaceSlice.selectors.getMetaData())
  const workspace = useSelector(pageWorkspaceSlice.selectors.getWorkspace(workspaceId))
  const [workingCopy, setWorkingCopy] = useState<PageWorkspaceType>(workspace)
  const viewersTargetingRuleSet = workingCopy?.viewersTargetingRuleSet
  const viewersTargetingRulesValid = !!viewersTargetingRuleSet && isAudienceValid(viewersTargetingRuleSet)
  const pageEditorsTargetingRuleSet = workingCopy?.pageEditorsTargetingRuleSet
  const pageEditorsTargetingRulesValid = !!pageEditorsTargetingRuleSet && isAudienceValid(pageEditorsTargetingRuleSet)
  const isDisabled = !viewersTargetingRulesValid || !pageEditorsTargetingRulesValid

  const updateWorkingCopy = (changes: Partial<PageWorkspaceType>) => {
    setWorkingCopy({ ...workingCopy, ...changes })
  }

  const onTargetingRulesChange = (changes) => {
    updateWorkingCopy({
      viewersTargetingRuleSet: {
        ...viewersTargetingRuleSet,
        ...changes,
      },
      restrictAccess: !changes?.targetingRules?.targetEntireCompany,
    })
  }

  const updateWorkspace = () => {
    dispatch(pageWorkspaceSlice.asyncActions.update(workingCopy, toggle))
  }

  useEffect(() => {
    if (visible && workspaceId) {
      dispatch(pageWorkspaceSlice.asyncActions.fetchWorkspace(workspaceId))
    }
  }, [visible, workspaceId])


  useEffect(() => {
    if (workspace) {
      setWorkingCopy(workspace)
    }
  }, [JSON.stringify(workspace?.viewersTargetingRuleSet), JSON.stringify(workspace?.pageEditorsTargetingRuleSet)])

  return (
    <Modal
      className='ManageWorkspacePermissionsModal'
      visible={visible}
      size='lg'
      modalContentClassName='container d-flex flex-column w-100 h-100'
      toggle={toggle}
    >
      <>
        {isLoading || !workingCopy ? (
          <div className='h-100 d-flex align-items-center justify-content-center'>
            <CirclesLoadingIndicator />
          </div>
        ) : (
          <>
            <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
              <h3 className='mb-0'>{I18N('manage_permissions')}</h3>
              <p className='text-secondary m-0 mt-1'>{I18N('header_secondary_text')}</p>
            </div>
            {!!viewersTargetingRuleSet && (
              <AudienceToggleSelector
                workingCopy={workingCopy?.viewersTargetingRuleSet}
                updateWorkingCopy={onTargetingRulesChange}
                canTargetSpecificUsers
                showAllTargetedDescription
                translationsPath='views.pages.manage_workspace_permissions_modal.audience_toggle_selector'
                className='mb-5'
                hasEstimatedAudienceAvatars={false}
              />
            )}
            {!!pageEditorsTargetingRuleSet && (
              <AudienceToggleSelector
                workingCopy={workingCopy?.pageEditorsTargetingRuleSet}
                updateWorkingCopy={changes => updateWorkingCopy({
                  pageEditorsTargetingRuleSet: {
                    ...pageEditorsTargetingRuleSet,
                    ...changes,
                  },
                })}
                canTargetSpecificUsers
                showAllTargetedDescription
                translationsPath='views.pages.manage_workspace_permissions_modal.edit_audience_toggle_selector'
                className='mb-5'
                hasEstimatedAudienceAvatars={false}
              />
            )}
            <div className='d-flex justify-content-end align-items-center'>
              <>
                <CancelButton
                  onClick={toggle}
                />
                <Button
                  onClick={updateWorkspace}
                  disabled={isDisabled || isSaving}
                  showLoadingSpinner={isSaving}
                >
                  {I18NCommon('save_changes')}
                </Button>
              </>
            </div>
          </>
        )}
      </>
    </Modal>
  )
}

export default ManageWorkspacePermissionsModal
