import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import getValueOrTemplateValue from 'utils/journeys/getValueOrTemplateValue'
import { buildStepDifferenceParams, checkStepDifferences } from 'utils/admin/journeys/buildDifferenceParams'
import { STEP_ATTRIBUTES } from 'redux/slices/journeys/steps'
import { CORRESPONDENT_ATTRIBUTES } from 'redux/slices/journeys/stepTemplates'
import {
  transformCcCorrespondentsForUser,
  transformStepCcsForUser,
  transformVariablesForUser
} from 'utils/journeys/journeyVariablesHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import currentTimezone from 'utils/currentTimezone'
import useTransformInterpolatedFields from 'components/admin/journeys/hooks/useTransformInterpolatedFields'

const TASK_TYPE = 'Journey::Task'
const INTRODUCTION_TYPE = 'Journey::Introduction'

const isTaskable = entityType => [TASK_TYPE, INTRODUCTION_TYPE].includes(entityType)

const useStepManagement = ({
  id,
  entity,
  entityTemplate,
  entityAttributes,
  fetchEntity,
  updateEntity,
  interpolatedFields = [],
}) => {
  const dispatch = useDispatch()
  const interpolateParams = useTransformInterpolatedFields()

  const step = entity?.step
  const stepTemplate = entityTemplate?.stepTemplate
  const fromUserAttributes = step?.fromUser ? { role: 'specific_employee', user: step?.fromUser } : null
  const toUserAttributes = step?.toUser ? { role: 'specific_employee', user: step?.toUser } : null
  const fromCorrespondent = fromUserAttributes || getValueOrTemplateValue(step, stepTemplate, 'fromCorrespondent')
  const toCorrespondent = toUserAttributes || getValueOrTemplateValue(step, stepTemplate, 'toCorrespondent')

  const stepCcs = step?.stepCcs || []
  const useStepCcs = !_.isEmpty(stepCcs)
  const ccCorrespondents = getValueOrTemplateValue(step, stepTemplate, 'ccCorrespondents')
  const ccAttrs = useStepCcs ? transformStepCcsForUser(stepCcs) : transformCcCorrespondentsForUser(ccCorrespondents)

  const questionVariables = stepTemplate?.journeyBlueprint?.questionVariables || []

  const initialState = {
    fromCorrespondentAttributes: { role: fromCorrespondent?.role, user: fromCorrespondent?.user },
    toCorrespondentAttributes: {
      role: toCorrespondent?.role,
      user: toCorrespondent?.user,
      group: toCorrespondent?.group,
    },
    state: step?.state,
    ccCorrespondentsAttributes: ccAttrs,
    isCustom: step?.custom,
    forUser: step?.forUser,
    isTaskable: isTaskable(step?.entityType),
  }

  STEP_ATTRIBUTES.forEach((attribute) => {
    initialState[attribute] = getValueOrTemplateValue(step, stepTemplate, attribute)
  })

  if (!initialState.triggerTimezone) {
    initialState.triggerTimezone = currentTimezone()
  }

  entityAttributes.forEach((attribute) => {
    initialState[attribute] = getValueOrTemplateValue(entity, entityTemplate, attribute)
  })

  const {
    atsIntegrationName,
    hrisIntegrationName,
  } = useCurrentCompany()

  interpolatedFields.forEach((attr) => {
    const value = getValueOrTemplateValue(entity, entityTemplate, attr)
    initialState[attr] = transformVariablesForUser(value, {
      questionVariables,
      atsIntegrationName,
      hrisIntegrationName,
    })
  })

  const [workingCopy, setWorkingCopy] = useState(initialState)
  const updateWorkingCopy = (updatedWorkingCopy) => {
    setWorkingCopy({ ...workingCopy, ...updatedWorkingCopy })
  }

  const { hasChanges } = checkStepDifferences(
    initialState, workingCopy, entityAttributes, [...STEP_ATTRIBUTES, ...CORRESPONDENT_ATTRIBUTES]
  )

  useEffect(() => {
    setWorkingCopy(initialState)
  }, [entity?.updatedAt, entityTemplate?.updatedAt, step?.updatedAt])

  useEffect(() => {
    dispatch(fetchEntity(id))
  }, [])

  const onSave = () => {
    if (hasChanges) {
      const params = buildStepDifferenceParams(id,
        step?.id,
        initialState,
        workingCopy,
        entityAttributes,
        [...STEP_ATTRIBUTES, ...CORRESPONDENT_ATTRIBUTES]
      )

      params.stepAttributes = {
        ...params.stepAttributes,
        state: step?.state,
      }

      const interpolatedParams = interpolateParams(interpolatedFields, params, questionVariables)

      dispatch(updateEntity(interpolatedParams))
    }
  }


  return {
    workingCopy,
    updateWorkingCopy,
    hasChanges,
    onSave,
  }
}

export default useStepManagement
