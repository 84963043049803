import React from 'react'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import Overview from 'components/analytics/ai_answers/aiAnswersOverview'
import Answers from 'components/analytics/ai_answers/aiAnswers'
import AiAnswersInsights from 'components/analytics/ai_answers/aiAnswersInsights'

const AiAnswersTab = () => {
  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  return (
    <main className='AiAnswersTab'>
      <div className='d-flex grid-span-12 justify-content-end align-items-center'>
        <TabHeader
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          periodEnd={periodEnd}
          setPeriodEnd={setPeriodEnd}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
        />
      </div>

      <AiAnswersInsights
        startDate={periodStart}
        endDate={periodEnd}
      />

      <Overview
        periodStart={periodStart}
        periodEnd={periodEnd}
      />

      <Answers
        params={{
          period: { periodStart, periodEnd },
        }}
      />
    </main>
  )
}

export default AiAnswersTab
