import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import Tooltip from 'components/common/clearyTooltip'
import useApi from 'components/common/hooks/useApi'
import AdditionalInformationModal from 'components/feedback/additionalInformationModal'
import React, { useState } from 'react'
import API from 'services/api'
import { AssistantAnswerStatuses, AssistantAnswerType } from 'types/ai/assistantAnswer'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.search_results.ai_answer')

type Props = {
  assistantAnswer: AssistantAnswerType
  setAssistantAnswer: (AssistantAnswerType) => void
}

const Feedback = ({
  assistantAnswer,
  setAssistantAnswer,
}: Props) => {
  const [status, setStatus] = useState(assistantAnswer.status)
  const [isAdditionalInformationModalOpen, setIsAdditionalInformationModalOpen] = useState(false)

  const [markCorrect, { isLoading: isMarkingCorrect }] = useApi(API.ai.assistant.answers.markCorrect, {
    onSuccess: setAssistantAnswer,
  })
  const [markIncorrect, { isLoading: isMarkingIncorrect }] = useApi(API.ai.assistant.answers.markIncorrect, {
    onSuccess: (data) => {
      setAssistantAnswer(data)
      setIsAdditionalInformationModalOpen(true)
    },
  })
  const [removeFeedback, { isLoading: isRemovingFeedback }] = useApi(API.ai.assistant.answers.removeFeedback, {
    onSuccess: setAssistantAnswer,
  })
  const isLoading = isMarkingCorrect || isMarkingIncorrect || isRemovingFeedback

  const [createAnswerFeedback] = useApi(API.ai.assistant.answerFeedbacks.create, {
    toastSuccessMessage: I18N('thank_you_for_feedback'),
  })

  const saveAnswerFeedback = (answerFeedback) => {
    const options = Object.entries(answerFeedback)
      .filter(([key, value]) => key !== 'comment' && value === true)
      .map(([key, _value]) => _.snakeCase(key))

    createAnswerFeedback(assistantAnswer.id, { comment: answerFeedback.comment, options })
  }

  const provideFeedback = async (newStatus) => {
    if (isLoading) return

    if (status === newStatus) {
      newStatus = AssistantAnswerStatuses.AnsweredNoFeedback
    }

    setStatus(newStatus)

    switch (newStatus) {
    case AssistantAnswerStatuses.AnsweredCorrect:
      markCorrect(assistantAnswer.id)
      break
    case AssistantAnswerStatuses.AnsweredIncorrect:
      markIncorrect(assistantAnswer.id)
      break
    case AssistantAnswerStatuses.AnsweredNoFeedback:
      removeFeedback(assistantAnswer.id)
      break
    default:
      break
    }
  }


  return (
    <div className='AiAssistantAnswerFeedback d-flex gap-3 align-items-center'>
      <div className='text-small text-secondary'>
        {I18N('was_the_answer_helpful')}
      </div>
      <FeedbackButton
        id='feedback-thumbs-up'
        className={status === AssistantAnswerStatuses.AnsweredCorrect ? 'helpful' : ''}
        iconPath='/images/thumbsUpIcon.svg'
        tooltip={I18N('mark_as_correct')}
        onClick={() => provideFeedback(AssistantAnswerStatuses.AnsweredCorrect)}
      />
      <FeedbackButton
        id='feedback-thumbs-down'
        className={status === AssistantAnswerStatuses.AnsweredIncorrect ? 'not-helpful' : ''}
        iconPath='/images/thumbsDownIcon.svg'
        tooltip={I18N('mark_as_incorrect')}
        onClick={() => provideFeedback(AssistantAnswerStatuses.AnsweredIncorrect)}
      />
      {isAdditionalInformationModalOpen && (
        <AdditionalInformationModal
          target='feedback-thumbs-down'
          isOpen={isAdditionalInformationModalOpen}
          setIsOpen={setIsAdditionalInformationModalOpen}
          updateFeedback={saveAnswerFeedback}
          checkboxOptions={['needMoreInformation', 'inaccurate', 'irrelevant']}
          i18nPrefix='ai_assistant_answers'
        />
      )}
    </div>
  )
}

const FeedbackButton = ({
  id,
  className,
  onClick,
  iconPath,
  tooltip,
}) => (
  <div
    id={id}
    className={classNames('FeedbackButton', className)}
    onClick={onClick}
  >
    <Tooltip
      content={<CdnSvg src={iconPath} />}
      placement='bottom'
      hide={0}
    >
      <span className='text-small'>{tooltip}</span>
    </Tooltip>
  </div>
)

export default Feedback
