import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import StepTemplateType from 'types/journeys/stepTemplate'
import stepTemplateTriggerText, { customStepTriggerText } from 'utils/journeys/stepTemplateTriggerText'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import { audienceSummaryText, targetingRulesPresent } from 'utils/audience'
import { StepType } from 'types/journeys/step'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.journeys.journeys.table')

interface StepTemplateTableHeaderProps {
  stepOrStepTemplate: StepTemplateType | StepType,
  stepTemplates?: StepTemplateType[],
  isCustom?: boolean
}

interface StepTemplateLinkPopoverProps {
  stepOrStepTemplate: StepTemplateType | StepType,
  forName?: string
}

const StepTemplateTableHeader = ({
  stepOrStepTemplate,
  stepTemplates = [],
  isCustom = false,
}: StepTemplateTableHeaderProps) => {
  const toCorrespondent = stepOrStepTemplate?.toCorrespondent
  const role = toCorrespondent?.role
  const forName = (
    (role === 'specific_employee' && toCorrespondent?.user?.preferredFullName)
    || (role === 'specific_group' && toCorrespondent?.group?.name)
    || toCorrespondent?.roleText
  )

  // eslint-disable-next-line max-len
  const triggerText = isCustom ? customStepTriggerText(stepOrStepTemplate) : stepTemplateTriggerText(stepOrStepTemplate, stepTemplates)

  return (
    <div className='StepTemplateTableHeader w-100 d-flex flex-column'>
      <div className='trigger-info-container d-flex justify-content-center align-items-center'>
        <span className='trigger-info text-white truncate-text-at-2-lines'>
          {triggerText}
        </span>
      </div>
      <StepTemplateLinkPopover stepOrStepTemplate={stepOrStepTemplate} forName={forName} />
      <span className='text-secondary text-small for-role truncate-text-at-2-lines'>{I18N('for_role', { forName })}</span>
    </div>
  )
}

const StepTemplateLinkPopover = ({
  stepOrStepTemplate,
  forName = '',
}: StepTemplateLinkPopoverProps) => {
  const name = stepOrStepTemplate?.name
  const targetingRules = (stepOrStepTemplate as StepTemplateType)?.targetingRules || {}

  const targetingOptions = useTargetingOptions()

  return (
    <HoverAndClickPopover
      target={(
        <div className='step-link-container d-flex justify-content-center align-items-center'>
          <a className='step-name truncate-text-at-3-lines' target='_blank' rel='noopener noreferrer' href={stepOrStepTemplate?.adminDisplayPath}>
            {name}
          </a>
        </div>
      )}
      id={stepOrStepTemplate?.id}
      placement='bottom'
      popoverClassName='StepTemplateLinkPopover'
    >
      <div className='p-2'>
        <p className='text-white font-weight-500'>{name}</p>
        <p className='text-white text-small mb-0'>{I18N('assigned_to')}</p>
        <p className='text-white text-small mb-0'>{forName}</p>
        {targetingRulesPresent(targetingRules) && (
          <>
            <p className='text-white text-small mb-0 mt-1'>{I18N('applies_to')}</p>
            <p className='text-white text-small mb-0'>{audienceSummaryText(targetingRules, targetingOptions)}</p>
          </>
        )}
      </div>
    </HoverAndClickPopover>
  )
}


export default StepTemplateTableHeader
