import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { URL_PATTERN_REGEX } from 'utils/constants'
import {
  I18NCommon, i18nLabelWithTimeZone, i18nMoment, i18nPath
} from 'utils/i18nHelpers'
import currentTimezone from 'utils/currentTimezone'
import Banners from 'components/banners/banners'
import { LoadingContainer } from 'components/common/loadingContainer'
import DatePicker from 'components/form_fields/datePicker'
import FormErrorList from 'components/errors/formErrorList'
import { Button } from 'components/common/buttons'
import { isAudienceValid } from 'utils/audience'
import AudienceSelector from 'components/common/audience/audienceSelector'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.banner_editor')
const defaultWorkingCopy = {
  targetingRules: TARGET_ENTIRE_COMPANY,
}

const BannerEditorPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)

  const [createBanner, {
    error: errorCreate,
    isLoading: isCreating,
  }] = useApi(API.admin.banners.create, {
    onSuccess: () => history.push('/admin/banners'),
    toastSuccessMessage: I18NCommon('created_successfully'),
  })

  const [updateBanner, {
    error: errorUpdate,
    isLoading: isUpdating,
  }] = useApi(API.admin.banners.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
  })

  const [destroyBanner, {
    isLoading: isDeleting,
  }] = useApi(API.admin.banners.destroy, {
    onSuccess: () => history.push('/admin/banners'),
    toastSuccessMessage: I18NCommon('deleted_successfully'),
  })

  const [fetchBanner, { data: banner, isNotFound, isLoading: isFetching }] = useApi(API.admin.banners.fetch)
  const targetingOptions = useTargetingOptions()
  const bannerUpdatedAt = banner?.updatedAt || null

  const { bannerId } = useParams()
  const isNewBanner = bannerId === 'new'

  useEffect(() => {
    if (!isNewBanner) {
      fetchBanner(bannerId)
    }
  }, [])

  useEffect(() => {
    if (banner && !isNewBanner) {
      setWorkingCopy(banner)
    }
  }, [bannerUpdatedAt])

  const updateWorkingCopy = (values = {}) => {
    setWorkingCopy({ ...workingCopy, ...values })
  }

  const generateChangeHandler = fieldName => (e) => {
    updateWorkingCopy({ ...workingCopy, [fieldName]: e.target.value })
  }

  const generateTimeChangeHandler = fieldName => (e) => {
    updateWorkingCopy({ [fieldName]: e && e.format() })
  }

  const setStartDateToNow = (e) => {
    e.preventDefault()

    updateWorkingCopy({ startTime: i18nMoment().format() })
  }

  const saveBanner = async () => {
    if (workingCopy.id) {
      updateBanner(workingCopy)
    } else {
      createBanner(workingCopy)
    }
  }

  const deleteBanner = () => destroyBanner(banner)

  const changesPresent = !_.isEqual(banner, workingCopy)
  const isMissingReqFields = !workingCopy.text || !workingCopy.startTime || !workingCopy.endTime
  const isSaveButtonDisabled = isMissingReqFields || !changesPresent || !isAudienceValid(workingCopy)
  const isLoading = isCreating || isUpdating || isFetching || isDeleting
  const error = errorCreate || errorUpdate

  return (
    <div className='BannerEditorPage'>
      <header className='AdminHeader'>
        <h3 className='mb-0'>{I18N('banner_editor')}</h3>
      </header>

      <main className='AdminContent'>
        {error && <FormErrorList error={error} />}

        <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
          <div className='container my-4'>
            <div className='row mb-3 mt-3'>
              <div className='col'>
                <label>{I18N('preview_banner')}</label>
                <Banners adminBanner={workingCopy} adminPreview />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-7'>
                <div className='row'>
                  <div className='form-group col'>
                    <label className='required-form-label'>{I18N('title')}</label>
                    <input value={workingCopy.title} onChange={generateChangeHandler('title')} />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group col'>
                    <label className='required-form-label'>{I18N('text')}</label>
                    <input value={workingCopy.text} onChange={generateChangeHandler('text')} />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group col'>
                    <label>{I18N('link')}</label>
                    <input
                      type='url'
                      value={workingCopy.link}
                      pattern={URL_PATTERN_REGEX}
                      placeholder={I18N('link_example')}
                      onChange={generateChangeHandler('link')}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group col'>
                    <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('start_time'), currentTimezone())}</label>
                    <DatePicker
                      id='cy_banner_editor_start_time'
                      selected={workingCopy.startTime && i18nMoment(workingCopy.startTime)}
                      onChange={generateTimeChangeHandler('startTime')}
                      showTimeSelect
                      isClearable
                      dateFormat='LLL'
                    >
                      <div className='react-datepicker-children text-center p-2'>
                        <a href='#' onMouseDown={setStartDateToNow}>
                          {I18NCommon('start_immediately')}
                        </a>
                      </div>
                    </DatePicker>
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group col'>
                    <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('end_time'), currentTimezone())}</label>
                    <DatePicker
                      id='cy_banner_editor_end_time'
                      selected={workingCopy.endTime && i18nMoment(workingCopy.endTime)}
                      onChange={generateTimeChangeHandler('endTime')}
                      showTimeSelect
                      isClearable
                      dateFormat='LLL'
                    />
                  </div>
                </div>

                <div id='banner-buttons' className='row'>
                  <div className='col'>
                    <Button disabled={isSaveButtonDisabled} onClick={saveBanner}>
                      {I18N('save_banner')}
                    </Button>
                    {!isNewBanner && (
                      <Button variant='danger' className='ml-3' onClick={deleteBanner}>
                        {I18N('delete')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-5'>
                <AudienceSelector
                  workingCopy={workingCopy}
                  updateWorkingCopy={updateWorkingCopy}
                  targetingOptions={targetingOptions}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default BannerEditorPage
