import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { targetingRulesPresent } from 'utils/audience'

import { ButtonSmallNarrow } from 'components/common/buttons'
import AccessLevelToggleGroup from 'components/common/access_level_toggle_group'
import Modal from 'components/common/modal'
import PartyHatIcon from 'components/icons/partyHatIcon'

import userSlice from 'redux/slices/users'
import pageSlice from 'redux/slices/pages'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import useAudienceUserIds from 'components/common/hooks/useAudienceUserIds'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import { areNormalizedTargetingRulesEqual } from 'utils/normalizeTargetingRules'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.pages')

const PUBLIC_ACCESS = 'public_access'
const PRIVATE_ACCESS = 'private_access'
export const AUDIENCE_MODAL_DISABLED = 'disabled'
export const AUDIENCE_MODAL_PUBLISH = 'publish'
export const AUDIENCE_MODAL_SHARED = 'shared'

const AudienceSelectorModal = ({
  modalMode = AUDIENCE_MODAL_DISABLED,
  onCloseModal,
  page,
}) => {
  const { generatePathToPage } = useWorkspaceUrls()
  const [targetingRules, setTargetingRules] = useState(page.targetingRules)
  const [restrictAccess, setRestrictAccess] = useState(page.restrictAccess)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const targetingOptions = useTargetingOptions()
  const { isPublishing } = useSelector(pageSlice.selectors.getPagesMetadata())

  const workspaceId = page?.pageWorkspaceId
  const workspaces = useSelector(pageWorkspaceSlice.selectors.getPageWorkspaces())
  const workspaceTitle = workspaces.find(workspace => workspace.id === workspaceId)?.title || ''
  const publicTitle = workspaceTitle ? I18N('public_title_with_workspace', { workspaceTitle }) : undefined
  const publicDescription = I18N('public_description_with_workspace')

  const isModalVisible = [
    AUDIENCE_MODAL_SHARED,
    AUDIENCE_MODAL_PUBLISH,
  ].includes(modalMode)

  const updateDisableButtonStatus = (rules, isPrivate) => {
    if (isPrivate) {
      const isAudienceEmpty = !targetingRulesPresent(rules)
      setIsSaveButtonDisabled(isAudienceEmpty)
    } else {
      setIsSaveButtonDisabled(false)
    }
  }

  useEffect(() => {
    dispatch(userSlice.asyncActions.fetchActiveUsersCount())
    updateDisableButtonStatus(page.targetingRules, page.restrictAccess)
  }, [])

  // if the event from props changes, update the local audience state
  useEffect(() => {
    setTargetingRules(page.targetingRules)
  }, [page.targetingRules])

  useEffect(() => {
    setRestrictAccess(page.restrictAccess)
  }, [page.restrictAccess])

  const {
    audienceUserIds,
    isLoading: isLoadingAudienceUserIds,
  } = useAudienceUserIds({ targetingRules })

  const sameAudience = page.restrictAccess === restrictAccess && areNormalizedTargetingRulesEqual(
    targetingRules, page.targetingRules
  )
  const audienceHelpCountKey = sameAudience ? 'audience_help_count' : 'edit_audience_help_count'

  const handleTargetingRulesChange = (newTargetingRules) => {
    setTargetingRules(newTargetingRules)
    updateDisableButtonStatus(newTargetingRules, restrictAccess)
  }

  const onClose = () => {
    onCloseModal()
  }

  const onPublish = () => {
    const onSuccess = (newSlug, meta = {}) => {
      let searchParams = null

      const {
        publishingFromDraft,
        firstPublishedPage,
        pageHasExpiration,
      } = meta

      const canArchivePage = page?.permittedActions?.canArchivePage
      const showPublishedModal = publishingFromDraft && !pageHasExpiration && firstPublishedPage && canArchivePage

      if (showPublishedModal) {
        searchParams = new URLSearchParams(location.search)
        searchParams.set('showPublishedSuccessModal', true)
        searchParams = searchParams.toString()
      }

      if (page.slug !== newSlug) {
        history.replace({
          pathname: generatePathToPage({ slug: newSlug }),
          search: searchParams,
        })
      }
    }

    const updatedPage = {
      ...page,
      targetingRules,
      restrictAccess,
      draft: false,
    }

    dispatch(pageSlice.asyncActions.publish(updatedPage, onSuccess)).then(onClose)
  }

  const onSave = () => {
    const updatedPage = {
      ...page,
      targetingRules,
      restrictAccess,
    }

    dispatch(pageSlice.asyncActions.update(updatedPage, onClose))
  }

  const onChangeAccessLevel = (newAccessLevel) => {
    const isPrivate = newAccessLevel === PRIVATE_ACCESS
    setTargetingRules({
      targetEntireCompany: !isPrivate,
      rules: [],
      users: [],
    })
    setRestrictAccess(isPrivate)
    updateDisableButtonStatus({}, isPrivate)
  }

  return (
    <Modal
      className={classNames('AudienceSelectorModal', modalMode)}
      visible={isModalVisible}
      toggle={onClose}
    >
      {modalMode === AUDIENCE_MODAL_PUBLISH && (
        <header className='d-flex align-items-center flex-column pb-4 pt-1 publish-header'>
          <PartyHatIcon className='mb-4' />
          <h2 className='mb-2'>{I18N('ready_to_publish')}</h2>
          <span>{I18N('select_audience')}</span>
        </header>
      )}

      {(modalMode === AUDIENCE_MODAL_SHARED) && (
        <header className='d-flex align-items-center pt-1 px-4'>
          <h4 className='mb-0'>{I18N('shared_with')}</h4>
        </header>
      )}

      <main className='pt-4 px-4 pb-2'>
        <h5 className='mt-2'>{I18N('audience')}</h5>
        <AccessLevelToggleGroup
          onChange={onChangeAccessLevel}
          guidanceText={I18N('audience_guidance')}
          className='mb-3'
          selectedValue={restrictAccess ? PRIVATE_ACCESS : PUBLIC_ACCESS}
          publicTitle={publicTitle}
          publicDescription={publicDescription}
        />

        {restrictAccess && (
          <>
            <AudienceRulesSelector
              targetingRules={targetingRules}
              onChange={handleTargetingRulesChange}
              targetingOptions={targetingOptions}
              className='mb-3 compact'
            />

            <div className='d-flex justify-content-start flex-row-responsive-column align-items-center bg-light p-2'>

              {isLoadingAudienceUserIds ? (
                <CirclesLoadingIndicator width={30} height={30} />
              ) : (
                <>
                  <RoundedAvatarsWithAsyncUserFetching userIds={audienceUserIds} popOverPlacement='top-start' />

                  <span className='text-secondary font-weight-500 text-smallest ml-2'>
                    {I18N(audienceHelpCountKey, { count: audienceUserIds.length })}
                  </span>
                </>
              )}
            </div>
          </>
        )}
      </main>

      <footer className='pb-4 px-4 d-flex justify-content-end align-items-center'>
        <a onClick={onClose} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </a>
        {modalMode === AUDIENCE_MODAL_PUBLISH && (
          <ButtonSmallNarrow onClick={onPublish} disabled={isSaveButtonDisabled} showLoadingSpinner={isPublishing}>
            {I18NCommon('publish')}
          </ButtonSmallNarrow>
        )}
        {(modalMode === AUDIENCE_MODAL_SHARED) && (
          <ButtonSmallNarrow onClick={onSave} disabled={isSaveButtonDisabled}>
            {I18NCommon('save_changes')}
          </ButtonSmallNarrow>
        )}
      </footer>
    </Modal>
  )
}

export default AudienceSelectorModal
