import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import taskableStepSlice from 'redux/slices/journeys/taskableSteps'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import StepLink from 'components/admin/journeys/stepLink'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import DueAtTextComponent from 'components/journeys/tasks/dueAtTextComponent'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import TaskableDetailsButtonAndModal from 'components/journeys/modals/taskableDetailsButtonAndModal'
import classNames from 'classnames'
import useCompleteTaskables from 'components/admin/journeys/hooks/useCompleteTaskables'
import userSlice from 'redux/slices/users'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import AssignTaskSelect from 'components/admin/journeys/tasks/assignTaskSelect'

export const MY_TASKS = 'my_tasks'
export const ALL_OPEN_TASKS = 'open'
export const ALL_COMPLETED_TASKS = 'completed'

const I18N = i18nPath('views.admin.journeys.tasks.task_list_page')

const TasksAdminTable = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { selectedTab } = useParams()

  // Not passing on a function because the request is already done in TaskListPage.
  const { data: groupCorrespondents = [] } = useGlobalStateBucket(() => {}, 'groupCorrespondents')

  const selectedToCorrespondent = groupCorrespondents.find(c => `group-${c.group?.id}` === selectedTab)
  const isGroupTab = !!selectedToCorrespondent

  const [toUserFilter, setToUserFilter] = useQueryParamState({
    param: 'toUserFilter',
    initialValue: null,
  })

  const [forUserFilter, setForUserFilter] = useQueryParamState({
    param: 'forUserFilter',
    initialValue: null,
  })

  const [searchQuery, setSearchQuery] = useQueryParamState({
    param: 'searchQuery',
    initialValue: null,
  })

  const isCompletedTaskTabSelected = selectedTab === ALL_COMPLETED_TASKS
  const isMyTasksTabSelected = selectedTab === MY_TASKS

  const { queryParams, isLoading } = useSelector(taskableStepSlice.selectors.getMetaData())

  const selectedToUserFilter = useSelector(userSlice.selectors.getSimpleUserByUsername(toUserFilter))
  const selectedForUserFilter = useSelector(userSlice.selectors.getSimpleUserByUsername(forUserFilter))
  const { isLoading: isLoadingUsers } = useSelector(userSlice.selectors.getMetaData())
  const areUsersFromUrlLoaded = useIsLoaded(isLoadingUsers) || selectedToUserFilter?.id || selectedForUserFilter?.id


  useEffect(() => {
    const users = []
    if (toUserFilter && !selectedToUserFilter?.id) {
      users.push(toUserFilter)
    }

    if (forUserFilter && !selectedForUserFilter?.id) {
      users.push(forUserFilter)
    }

    if (users.length > 0) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        [], () => {},
        { includeInactiveUsers: true, includeHiddenUsers: true },
        users
      ))
    }
  }, [toUserFilter, forUserFilter])


  const onToUserFilterChange = (user) => {
    setToUserFilter(user?.username)
  }

  const onForUserFilterChange = (user) => {
    setForUserFilter(user?.username)
  }

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const buildParams = (newQueryParams) => {
    // Open tasks are tasks with status of pending
    const selectedStatusFilter = isCompletedTaskTabSelected ? 'completed' : 'pending'
    const selectedToUserIdFilter = isMyTasksTabSelected ? currentUser.id : selectedToUserFilter?.id

    return {
      ...queryParams,
      status: selectedStatusFilter,
      q: searchQuery,
      userId: selectedForUserFilter?.id,
      toUserId: selectedToUserIdFilter,
      toCorrespondentId: selectedToCorrespondent?.id,
      ...newQueryParams,
      sortBy: 'due_at',
      sortDir: 'asc',
    }
  }

  const onParamsChange = (newQueryParams) => {
    dispatch(taskableStepSlice.asyncActions.admin.fetchAll(buildParams(newQueryParams)))
  }

  useEffect(() => {
    if ((toUserFilter || forUserFilter) && !areUsersFromUrlLoaded) return

    onParamsChange({ page: 1 })
  }, [selectedTab, selectedToUserFilter?.id, selectedForUserFilter?.id, searchQuery, areUsersFromUrlLoaded])

  const handlePaginationClick = (params) => {
    onParamsChange(params)
  }

  const {
    filteredSteps,
    rowClassName,
  } = useCompleteTaskables({
    onParamsChange,
    isCompletedTaskTabSelected,
  })

  const columns = [
    !isCompletedTaskTabSelected && {
      header: I18N('list.completed'),
      accessor: d => (
        <TaskableDetailsButtonAndModal
          showCompleteCheckbox={true}
          showTaskableName={false}
          className='justify-content-center'
          step={d}
        />
      ),
    },
    {
      header: I18N('list.name'),
      accessor: d => <StepLink step={d} />,
    },
    {
      header: I18N('list.assigned_to'),
      accessor: (d) => {
        if (isGroupTab) {
          return (
            <AssignTaskSelect
              key={d.id}
              step={d}
              toCorrespondent={selectedToCorrespondent}
            />
          )
        } else {
          return (
            <EmployeeOrCorrespondentLink
              linkToAdmin
              user={d?.toUserOrToCorrespondentUser}
              correspondent={d?.toCorrespondent}
            />
          )
        }
      },
    },
    {
      header: I18N('list.for'),
      accessor: d => <EmployeeOrCorrespondentLink linkToAdmin user={d?.forUser} />,
    },
    !isCompletedTaskTabSelected && {
      header: I18N('list.due_date'),
      accessor: d => <DueAtTextComponent className='mb-0' task={d?.entity} hasDuePrefix={false} />,
    },
    isCompletedTaskTabSelected && {
      header: I18N('list.completion_date'),
      accessor: d => <p className='mb-0'>{i18nMoment(d?.entity?.completedAt).format('lll')}</p>,
    },
  ].filter(Boolean)

  return (
    <>
      <div className='d-flex justify-content-between mt-3 mb-3'>
        <AsyncSearchInput
          placeholder={I18N('search_by_task_name')}
          onKeyUp={setSearchQuery}
          className='mb-0'
          defaultValue={searchQuery}
        />
        <div className='d-flex justify-content-center align-items-center'>
          {!isMyTasksTabSelected && (
            <>
              <p className='text-secondary mb-0 mr-2'>{I18N('assigned_to')}</p>
              <EmployeeSearch
                className='assigned-to-filter'
                placeholder={I18N('all_assignees')}
                selectedEmployee={selectedToUserFilter}
                onChange={onToUserFilterChange}
                includeInactiveUsers
                includeHiddenUsers
              />
            </>
          )}
          <p className='text-secondary mb-0 mx-2'>{I18N('for')}</p>
          <EmployeeSearch
            className='for-filter'
            placeholder={I18N('all_assignees')}
            selectedEmployee={selectedForUserFilter}
            onChange={onForUserFilterChange}
            includeInactiveUsers
            includeHiddenUsers
          />
        </div>
      </div>
      <TableLoadingContainer isLoading={isLoading || isLoadingUsers}>
        <SmartTable
          className={classNames('mt-3 white-bg-table TasksAdminTable', { isCompletedTaskTabSelected })}
          data={filteredSteps}
          page={page}
          pages={totalPages}
          perPage={perPage}
          totalCount={total}
          showPagination={true}
          columns={columns}
          onPaginationClick={handlePaginationClick}
          rowClassName={rowClassName}
        />
      </TableLoadingContainer>
    </>
  )
}

export default TasksAdminTable
