import React from 'react'
import classNames from 'classnames'

import DoubleArrowheadLeftIcon from 'components/icons/doubleArrowheadLeftIcon'

const ToggleLeftNavButton = ({
  onClick,
  className = '',
  isOpen = true,
}) => (
  <button
    className={classNames('ToggleLeftNavButton', { isOpen }, className)}
    onClick={onClick}
  >
    <DoubleArrowheadLeftIcon className={classNames('flex-shrink-0', { isOpen })} />
  </button>
)

export default ToggleLeftNavButton
