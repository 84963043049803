import React from 'react'
import { Link } from 'react-router-dom'

const StepLink = ({ step }) => {
  const path = step?.surveyResponseDisplayPath || step?.adminDisplayPath

  return (
    <div className='StepLink d-flex justify-content-between align-items-center w-100'>
      <Link to={path}>{step?.name}</Link>
    </div>
  )
}

export default StepLink
