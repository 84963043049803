import React, { useState } from 'react'
import Card from 'components/common/card'
import { I18NCommon } from 'utils/i18nHelpers'
import ReactShowMoreText from 'react-show-more-text'
import RichTextView from 'components/common/richTextView'
import FAQDropdown from 'components/pages/faqs/faqDropdown'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import FAQForm from 'components/pages/faqs/faqForm'
import { PageFAQType } from 'types/page/faq'
import FAQLastUpdater from 'components/pages/faqs/faqLastUpdater'

type Props = {
  pageId: string
  faq: PageFAQType
  onDelete?: () => void
  onUpdate?: (updatedFAQ: PageFAQType) => void
  canManageFAQs?: boolean
  collapsibleContent?: boolean
}

const FAQItem: React.FC<Props> = ({
  pageId,
  faq,
  onDelete = () => {},
  onUpdate = () => {},
  canManageFAQs = false,
  collapsibleContent = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { question } = faq

  const [updateFAQ, { isLoading: isSaving }] = useApi(updatedFAQ => API.pagesFAQs.update(pageId, updatedFAQ), {
    onSuccess: (data) => {
      onUpdate(data)
      setIsEditing(false)
    },
  })

  return (
    <Card>
      <FAQDropdown
        pageId={pageId}
        faq={faq}
        onDelete={onDelete}
        setIsEditing={setIsEditing}
        canManageFAQs={canManageFAQs}
      />
      <FAQLastUpdater faq={faq} className='mb-3' />

      {isEditing ? (
        <FAQForm
          onSave={updateFAQ}
          faq={faq}
          onCancel={() => setIsEditing(false)}
          isSaving={isSaving}
        />
      ) : (
        <>
          <h4>{question}</h4>
          {collapsibleContent ? (
            <ReactShowMoreText
              lines={3}
              more={<span className='link-color pointer'>{I18NCommon('more')}</span>}
              less={<span className='link-color pointer'>{I18NCommon('show_less')}</span>}
              className='w-100'
              anchorClass='ShowMoreText__anchor'
            >
              <RichTextView record={faq} richTextKey='answer' recordType='pagesFAQ' />
            </ReactShowMoreText>
          ) : (
            <RichTextView record={faq} richTextKey='answer' recordType='pagesFAQ' />
          )}
        </>
      )}
    </Card>
  )
}

export default FAQItem

