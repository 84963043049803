import React from 'react'

import { Button, ButtonSecondary } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.step_templates.invalid_fields')

const InvalidFieldsPopover = ({
  invalidFields,
  children,
}) => (
  <HoverAndClickPopover target={children}>
    <div className='p-2'>
      <div className='text-smallest font-weight-600 mb-2'>
        {I18N('missing_fields')}
      </div>
      {invalidFields.map(field => (
        <div key={field} className='p-2'>
          <CdnSvg className='CircledCrossIcon' src='/images/circledCrossIcon.svg' />
          <span className='ml-2'>{I18N(_.snakeCase(field))}</span>
        </div>
      ))}
    </div>
  </HoverAndClickPopover>
)

interface SaveTemplateButtonProps {
  onSave: (state: 'draft' | 'active') => void
  isValid: boolean
  isDraft: boolean
  areChangesPresent?: boolean
  isSaving: boolean
  invalidFields: string[]
}

export const SaveDraftTemplateButton = ({
  onSave,
  isValid,
  isDraft = true,
  areChangesPresent = true,
  isSaving,
  invalidFields = [],
}: SaveTemplateButtonProps) => {
  const disableSaveAsDraftButton = !isValid || (isDraft && !areChangesPresent)

  const button = (
    <ButtonSecondary
      className='draft-button'
      disabled={disableSaveAsDraftButton || isSaving}
      onClick={() => onSave('draft')}
      showLoadingSpinner={isSaving}
    >
      {I18NCommon('save_as_draft')}
    </ButtonSecondary>
  )

  if (isValid) {
    return button
  }

  return (
    <InvalidFieldsPopover invalidFields={invalidFields}>
      {button}
    </InvalidFieldsPopover>
  )
}

const SaveTemplateButton = ({
  onSave,
  isDraft = true,
  areChangesPresent = true,
  isSaving,
  isValid,
  invalidFields = [],
}: SaveTemplateButtonProps) => {
  const disablePublishButton = !isValid || (!isDraft && !areChangesPresent)

  const button = (
    <Button
      className='save-button'
      disabled={disablePublishButton || isSaving}
      onClick={() => onSave('active')}
      showLoadingSpinner={isSaving}
    >
      {I18NCommon(isDraft ? 'publish' : 'save_updates')}
    </Button>
  )

  if (isValid) {
    return button
  }

  return (
    <InvalidFieldsPopover invalidFields={invalidFields}>
      {button}
    </InvalidFieldsPopover>
  )
}

export default SaveTemplateButton
