import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import { useCallback } from 'react'
import { audienceSummaryText } from 'utils/audience'

const useAudienceSummaryText = () => {
  const targetingOptions = useTargetingOptions()

  const generateAudienceSummaryText = useCallback((targetingRules) => {
    if (_.isEmpty(targetingOptions)) {
      return ''
    } else {
      return audienceSummaryText(targetingRules, targetingOptions)
    }
  }, [targetingOptions])

  return generateAudienceSummaryText
}

export default useAudienceSummaryText
