import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { CORRESPONDENT_ATTRIBUTES, STEP_TEMPLATE_ATTRIBUTES, buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'

import { buildStepTemplateDifferenceParams, checkStepDifferences } from 'utils/admin/journeys/buildDifferenceParams'
import { transformCcCorrespondentsForUser, transformVariablesForUser } from 'utils/journeys/journeyVariablesHelpers'
import getDiff from 'utils/getDiff'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import currentTimezone from 'utils/currentTimezone'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import useFetch from 'components/common/hooks/useFetch'
import useApi from 'components/common/hooks/useApi'
import { i18nPath } from 'utils/i18nHelpers'
import { showToastMessage } from 'redux/slices/toasts'
import useTransformInterpolatedFields from 'components/admin/journeys/hooks/useTransformInterpolatedFields'

const I18N = i18nPath('views.admin.journeys.step_templates')

const TASK_TEMPLATE_TYPE = 'Journey::TaskTemplate'
const INTRODUCTION_TEMPLATE_TYPE = 'Journey::IntroductionTemplate'

const isTaskable = templateType => [TASK_TEMPLATE_TYPE, INTRODUCTION_TEMPLATE_TYPE].includes(templateType)

const useStepTemplateManagement = ({
  entityTemplateAttributes,
  updateApiPath,
  fetchApiPath,
  interpolatedFields = [],
}) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const interpolateParams = useTransformInterpolatedFields()
  const isPublishingRef = useRef(false)

  const onUpdateSuccess = () => {
    const message = isPublishingRef.current ? I18N('successfully_published') : I18N('successfully_updated')
    dispatch(showToastMessage({ message, type: 'success' }))
    isPublishingRef.current = false
  }

  const { data: originalEntityTemplate, isLoading: isFetching } = useFetch(
    () => fetchApiPath(id),
    [id],
    { addEntitySlice: true }
  )
  const [updateEntityTemplate, { data: updatedEntityTemplate, isLoading: isSaving }] = useApi(updateApiPath, {
    onSuccess: onUpdateSuccess,
  })
  const entityTemplate = updatedEntityTemplate || originalEntityTemplate
  const isLoading = isFetching || isSaving

  const stepTemplate = entityTemplate?.stepTemplate
  // We need to use the originalEntityTemplate to get the journeyBlueprint because the journeyBlueprint is not returned in the updateEntityTemplate
  const journeyBlueprint = originalEntityTemplate?.stepTemplate?.journeyBlueprint

  const questionVariables = journeyBlueprint?.questionVariables || []
  const { fromCorrespondent, toCorrespondent } = stepTemplate || {}

  const initialState = {
    fromCorrespondentAttributes: { role: fromCorrespondent?.role, user: fromCorrespondent?.user },
    toCorrespondentAttributes: {
      role: toCorrespondent?.role,
      user: toCorrespondent?.user,
      group: toCorrespondent?.group,
    },
    stepTemplateId: stepTemplate?.id,
    isTaskable: isTaskable(entityTemplate?.stepTemplate?.templateType),
  }

  if (stepTemplate?.ccCorrespondents) {
    initialState.ccCorrespondentsAttributes = transformCcCorrespondentsForUser(stepTemplate.ccCorrespondents)
  }

  entityTemplateAttributes.forEach((attr) => {
    initialState[attr] = entityTemplate?.[attr]
  })

  STEP_TEMPLATE_ATTRIBUTES.forEach((attr) => {
    initialState[attr] = stepTemplate?.[attr]
  })

  if (!initialState.triggerTimezone) {
    initialState.triggerTimezone = currentTimezone()
  }

  const {
    atsIntegrationName,
    hrisIntegrationName,
  } = useCurrentCompany()

  interpolatedFields.forEach((attr) => {
    initialState[attr] = transformVariablesForUser(
      entityTemplate?.[attr],
      {
        questionVariables,
        atsIntegrationName,
        hrisIntegrationName,
      }
    )
  })

  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState({})

  useEffect(() => {
    if (!isLoading) {
      updateWorkingCopy(getDiff(workingCopy, initialState))
    }
  }, [entityTemplate?.updatedAt, stepTemplate?.updatedAt, isLoading])

  const handleSave = (state) => {
    const params = buildStepTemplateDifferenceParams(
      id,
      stepTemplate?.id,
      initialState,
      workingCopy,
      entityTemplateAttributes,
      [...STEP_TEMPLATE_ATTRIBUTES, ...CORRESPONDENT_ATTRIBUTES]
    )

    params.stepTemplateAttributes = params.stepTemplateAttributes || {}

    if (stepTemplate?.state !== state || state !== params.stepTemplateAttributes.state) {
      params.stepTemplateAttributes.id = stepTemplate?.id
      params.stepTemplateAttributes.state = state
    }

    if (!stepTemplate.triggerTimezone && !params.stepTemplateAttributes.triggerTimezone) {
      params.stepTemplateAttributes.triggerTimezone = currentTimezone()
    }

    params.stepTemplateAttributes = buildStepTemplatePayload(params.stepTemplateAttributes)

    const interpolatedParams = interpolateParams(interpolatedFields, params, questionVariables)

    // Only send request when there are actual changes
    if (Object.keys(params).length > 1) {
      isPublishingRef.current = state === 'active' && stepTemplate?.state !== state

      updateEntityTemplate(interpolatedParams)
    }
  }

  const handleNameSave = (name) => {
    updateWorkingCopy({ name })
    const params = {
      id,
      stepTemplateAttributes: {
        id: stepTemplate?.id,
        name,
      },
    }

    return updateEntityTemplate(params)
  }

  const { hasChanges } = checkStepDifferences(
    initialState,
    workingCopy,
    entityTemplateAttributes,
    [...STEP_TEMPLATE_ATTRIBUTES, ...CORRESPONDENT_ATTRIBUTES]
  )

  return {
    workingCopy,
    updateWorkingCopy,
    handleSave,
    handleNameSave,
    hasChanges,
    journeyBlueprint,
    isSaving,
    isFetching,
    stepTemplate,
    entityTemplate,
  }
}

export default useStepTemplateManagement
