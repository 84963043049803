import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { isAudienceValid } from 'utils/audience'

import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import pageWorkspaceSlice, { defaultWorkingCopy } from 'redux/slices/pageWorkspaces'

import PageWorkspaceType from 'types/pageWorkspace'
import EmojiPicker from 'components/common/emojiPicker'
import CdnSvg from 'components/common/cdnSvg'

const plusIconPath = '/images/plusIcon.svg'

const I18N = i18nPath('views.pages.create_workspace_modal')

export const CreateWorkspaceModal = ({
  visible,
  toggle,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState<PageWorkspaceType>(defaultWorkingCopy)

  const { isSaving } = useSelector(pageWorkspaceSlice.selectors.getMetaData())

  const isDisabled = _.isEmpty(workingCopy.title) || !isAudienceValid(workingCopy.viewersTargetingRuleSet || {})

  const updateWorkingCopy = (changes: Partial<PageWorkspaceType>) => {
    setWorkingCopy({ ...workingCopy, ...changes })
  }

  const onTargetingRulesChange = (changes) => {
    updateWorkingCopy({
      viewersTargetingRuleSet: changes,
      restrictAccess: !changes?.targetingRules?.targetEntireCompany,
    })
  }

  const createWorkspace = () => {
    const onSuccess = (response) => {
      // For now we'll redirect to the first page of the workspace manually
      const included = response?.data?.included || []
      const draftPage = included.find(item => item.type === 'page')
      if (draftPage) {
        history.push(draftPage.attributes.displayPath)
      }

      toggle()
    }

    dispatch(pageWorkspaceSlice.asyncActions.create(workingCopy, onSuccess))
  }

  useEffect(() => {
    if (!visible) {
      setWorkingCopy(defaultWorkingCopy)
    }
  }, [visible])

  return (
    <Modal
      className='CreatePageWorkspaceModal'
      visible={visible}
      toggle={() => toggle()}
      fullscreen
      modalContentClassName='container d-flex flex-column w-100'
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h1>{I18N('create_a_new_workspace')}</h1>
          <p className='text-secondary m-0 mt-1'>{I18N('header_secondary_text')}</p>
        </div>
        <div className='mb-3'>

          <label className='required-form-label'>{I18NCommon('name')}</label>
          <div className='d-flex align-items-center'>
            <EmojiPicker
              onEmojiSelect={emoji => updateWorkingCopy({ emojiCode: emoji.native })}
              trigger={<EmojiOrPlaceholder selectedEmoji={workingCopy.emojiCode} />}
              triggerClassName='mr-2 h-100'
              disableCustomReactions
              className='mb-1'
            />
            <input
              className='w-100'
              value={workingCopy.title}
              onChange={e => updateWorkingCopy({ title: e.target.value })}
              placeholder={I18N('workspace_title_placeholder')}
            />
          </div>
        </div>
        <AudienceToggleSelector
          workingCopy={workingCopy.viewersTargetingRuleSet}
          updateWorkingCopy={onTargetingRulesChange}
          canTargetSpecificUsers
          showAllTargetedDescription
          hasEstimatedAudienceAvatars={false}
          translationsPath='views.pages.create_workspace_modal.audience_toggle_selector'
          className='mb-5'
        />
        <div className='d-flex justify-content-end align-items-center'>
          <>
            <CancelButton
              onClick={() => toggle()}
            />
            <Button
              onClick={createWorkspace}
              disabled={isDisabled || isSaving}
              showLoadingSpinner={isSaving}
            >
              {I18N('create_workspace')}
            </Button>
          </>
        </div>
      </>
    </Modal>
  )
}


const CreateWorkspaceButtonAndModal = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='CreateWorkspaceButtonAndModal'>
      <IconButtonWithPopover
        id='create-workspace-button'
        text={I18N('create_workspace')}
        icon={<CdnSvg src={plusIconPath} className='PlusIcon' />}
        onClick={() => setShowModal(true)}
        className='CreateWorkspaceButton'
        popoverClassName='CreateWorkspacePopover p-2'
        textClassName='text-white text-smallest'
      />
      <CreateWorkspaceModal
        visible={showModal}
        toggle={() => setShowModal(false)}
      />
    </div>
  )
}

export default CreateWorkspaceButtonAndModal
