import { ButtonSmallNarrow } from 'components/common/buttons'
import useFetch from 'components/common/hooks/useFetch'
import FAQItem from 'components/pages/faqs/faqItem'
import NewFAQ from 'components/pages/faqs/newFAQ'
import React, { useState } from 'react'
import API from 'services/api'
import PageType from 'types/page'
import { PageFAQType } from 'types/page/faq'
import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages.faqs')

type Props = {
  page: PageType
}

const FAQsList: React.FC<Props> = ({ page }) => {
  const [faqs, setFAQs] = useState<PageFAQType[]>([])

  const { isLoading } = useFetch(() => API.pagesFAQs.fetchAll(page.id), [], {
    onSuccess: setFAQs,
  })

  const removeFAQ = (faqId: string) => {
    setFAQs(faqs.filter(faq => faq.id !== faqId))
  }

  const updateFAQ = (updatedFAQ: PageFAQType) => {
    setFAQs(faqs.map(faq => (faq.id === updatedFAQ.id ? updatedFAQ : faq)))
  }

  const createFAQ = (newFAQ: PageFAQType) => {
    setFAQs([newFAQ, ...faqs])
    setIsAddingFAQ(false)
  }

  const canManageFAQs = page.permittedActions?.edit || false
  const [isAddingFAQ, setIsAddingFAQ] = useState(false)
  const isEmpty = !faqs || faqs.length === 0

  if (isLoading) return null
  if (!canManageFAQs && isEmpty) return null

  return (
    <div className='FAQsList mt-3'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h6 className='font-weight-600 text-secondary'>{I18N('faq')}</h6>
        {canManageFAQs && !isAddingFAQ && !isEmpty && (
          <ButtonSmallNarrow
            onClick={() => setIsAddingFAQ(true)}
          >
            {I18N('new_faq')}
          </ButtonSmallNarrow>
        )}
      </div>
      <div className='d-flex flex-column gap-3'>
        {isEmpty && !isAddingFAQ && (
          <EmptyState setIsAddingFAQ={setIsAddingFAQ} />
        )}
        {isAddingFAQ && (
          <NewFAQ
            onCancel={() => setIsAddingFAQ(false)}
            pageId={page.id}
            onSave={createFAQ}
          />
        )}
        {faqs?.map(faq => (
          <FAQItem
            key={faq.id}
            pageId={page.id}
            faq={faq}
            onDelete={() => removeFAQ(faq.id)}
            onUpdate={updateFAQ}
            canManageFAQs={canManageFAQs}
            collapsibleContent
          />
        ))}
      </div>
    </div>
  )
}

const EmptyState = ({ setIsAddingFAQ }: { setIsAddingFAQ: (isAddingFAQ: boolean) => void }) => (
  <Card>
    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center gap-2'>
      <div>
        <h3>{I18N('build_your_knowledge_base')}</h3>
        <p>{I18N('build_your_knowledge_base_description')}</p>
        <ButtonSmallNarrow
          onClick={() => setIsAddingFAQ(true)}
        >
          {I18N('new_faq')}
        </ButtonSmallNarrow>
      </div>
      <div className='confetti-background position-relative'>
        <CdnSvg src='/images/illustrations/peopleWithSpeechBubbles.svg' className='EmptyStateSvg' />
      </div>
    </div>
  </Card>
)

export default FAQsList
