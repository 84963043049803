import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import pageSlice from 'redux/slices/pages'
import PageHeader from 'components/pages/pageHeader'
import PageContent from 'components/pages/pageContent'
import useContentTracking from 'components/common/hooks/useContentTracking'
import useShowPublishedSuccessModal from 'components/pages/hooks/useShowPublishedSuccessModal'
import PagePublishedModal from 'components/pages/pagePublishedModal'
import PageArchiveBanner from 'components/pages/pageArchiveBanner'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import useQueryParams from 'components/common/hooks/useQueryParams'

const PagePage = () => {
  const dispatch = useDispatch()
  const { pageSlug, workspaceSlug } = useParams()
  const { isPreviewing } = useQueryParams()
  const page = useSelector(pageSlice.selectors.getPage(pageSlug))

  const isArchived = page?.archived

  useEffect(() => {
    dispatch(pageSlice.asyncActions.fetch(pageSlug))
  }, [pageSlug])

  useContentTracking('page', page?.id)

  const { isPublishedSuccessModalOpen, closePublishedSuccessModal } = useShowPublishedSuccessModal()

  if (!isPreviewing && page?.draft && page?.permittedActions?.edit && !page?.archived) {
    const newUrl = workspaceSlug ? `/workspaces/${workspaceSlug}/${page?.id}/edit` : `/pages/${page?.id}/edit`

    return <Redirect to={newUrl} />
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{page?.title}</title>
        </Helmet>
      </HelmetProvider>
      <div className='PagePage h-100 d-flex flex-column'>
        <PageHeader page={page} />
        {isArchived && <PageArchiveBanner page={page} />}
        <PageContent page={page} />
      </div>
      {isPublishedSuccessModalOpen && (
        <PagePublishedModal
          visible={isPublishedSuccessModalOpen}
          page={page}
          toggle={closePublishedSuccessModal}
        />
      )}
    </>
  )
}

export default PagePage
