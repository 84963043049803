import React, { useEffect, useState } from 'react'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import StatusLabel from 'components/common/status_label'
import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import useQueryParams from 'components/common/hooks/useQueryParams'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useFetch from 'components/common/hooks/useFetch'
import UserLink from 'components/common/userLink'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { useDebounce } from 'usehooks-ts'
import { RootSidebar } from 'components/common/sidebar'
import AiAnswerSidebarTitle from 'components/analytics/ai_answers/aiAnswerSidebarTitle'
import AiAnswersOrigin from 'components/analytics/ai_answers/aiAnswersOrigin'
import AiAnswerSidebar from 'components/analytics/ai_answers/aiAnswerSidebar'
import useApi from 'components/common/hooks/useApi'

const I18N = i18nPath('views.platform_analytics.ai_answers.answers')

const statusLabelColor = {
  answered_correct: 'green',
  answered_incorrect: 'red',
  not_answered: 'orange',
  answered_no_feedback: 'blue',
}

export const AnswerStatusLabel = ({ answer }) => (
  <StatusLabel color={statusLabelColor[answer?.status]}>
    {I18N(`statuses.${answer?.status}`)}
  </StatusLabel>
)

const tabs = [
  {
    id: 'all',
    text: I18N('tabs.show_all'),
  },
  {
    id: 'answered_correct',
    text: I18N('tabs.answered_correct'),
  },
  {
    id: 'answered_incorrect',
    text: I18N('tabs.answered_incorrect'),
  },
  {
    id: 'answered_no_feedback',
    text: I18N('tabs.answered_no_feedback'),
  },
  {
    id: 'not_answered',
    text: I18N('tabs.not_answered'),
  },
]

const columns = setSelectedAnswerId => ([
  {
    header: I18N('table.headers.status'),
    col: 'col-status',
    accessor: answer => <AnswerStatusLabel answer={answer} />,
    style: { width: '15%' },
  },
  {
    header: I18N('table.headers.question'),
    col: 'col-question',
    accessor: answer => (
      <div
        className='AiAnswersTab__Answers__query'
        onClick={() => setSelectedAnswerId(answer.id)}
      >
        {answer.queryWithoutMentions}
      </div>
    ),
  },
  {
    header: I18N('table.headers.origin'),
    col: 'col-origin',
    accessor: (answer: any) => <AiAnswersOrigin answer={answer} />,
  },
  {
    header: I18N('table.headers.submitted_by'),
    style: { width: '20%' },
    col: 'col-author',
    accessor: answer => <UserLink user={answer.user} />,
  },
  {
    header: I18N('table.headers.date'),
    style: { width: 200 },
    col: 'col-date',
    id: 'created_at',
    accessor: answer => i18nMoment(answer.createdAt).format('lll'),
  },
])

interface AnswersProps {
  params: {
    period?: {
      periodStart: string
      periodEnd?: string
    }
    topicId?: string
  }
  showTabs?: boolean
  title?: string
}

const Answers = ({
  params,
  showTabs = true,
  title = I18N('title'),
}: AnswersProps) => {
  const { selectedTab } = useQueryParams()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const [pagination, setPagination] = useState({ page: 1, sortBy: 'created_at', sortDir: 'desc' })
  const status = selectedTab === 'all' ? undefined : selectedTab

  const {
    data: answers,
    isLoading,
    paginationData,
  } = useFetch(
    () => API.admin.analytics.aiAnswers.fetchAnswers({
      ...pagination,
      status,
      query: debouncedQuery,
      ...params,
    }),
    [
      pagination.sortBy,
      pagination.sortDir,
      pagination.page,
      debouncedQuery,
      JSON.stringify(params),
      selectedTab,
    ]
  )

  const [selectedAnswerId, setSelectedAnswerId] = useQueryParamState<string>({ param: 'answerId', initialValue: '' })
  const [selectedAnswer, setSelectedAnswer] = useState<any>(null)
  const [fetchAnswer] = useApi(API.admin.analytics.aiAnswers.fetchAnswer, { onSuccess: setSelectedAnswer })

  useEffect(() => {
    if (!selectedAnswerId) {
      setSelectedAnswer(null)
      return
    }

    const answer = answers?.find(a => a.id === selectedAnswerId)
    if (answer) {
      setSelectedAnswer(answer)
    } else {
      fetchAnswer(selectedAnswerId)
    }
  }, [selectedAnswerId])

  const [isRemovingSelectedAnswer, setIsRemovingSelectedAnswer] = useState(false)
  const debouncedSelectedAnswer = useDebounce(selectedAnswer, 500)

  /**
   * We don't remove the answer immediately in the onClose of the sidebar
   * because a user may click a different answer (outside the sidebar so calling onClose)
   * and we don't want to remove the newly selected answer in that case
   */
  useEffect(() => {
    if (isRemovingSelectedAnswer) {
      setIsRemovingSelectedAnswer(false)
      if (selectedAnswer?.id === debouncedSelectedAnswer?.id) {
        setSelectedAnswerId('')
      }
    }
  }, [isRemovingSelectedAnswer])

  return (
    <div className='AiAnswersTab__Answers grid-span-12'>
      <CardWithPaginatedTable
        title={title}
        tableColumns={columns(setSelectedAnswerId)}
        tableData={answers}
        isLoading={isLoading}
        showPagination={(paginationData.totalPages || 0) > 1}
        page={paginationData.page || 1}
        totalPages={paginationData.totalPages || 0}
        onPaginationClick={setPagination}
        onSortChange={setPagination}
        sortKey='created_at'
        sortedAsc={false}
        headerActions={(
          <input
            type='text'
            placeholder={I18NCommon('search')}
            onChange={e => setQuery(e.target.value)}
          />
        )}
      >
        {showTabs && (
          <HistoryChangingPillTabSelector
            pillClasses='text-normal'
            className='mb-3'
            tabs={tabs}
          />
        )}
      </CardWithPaginatedTable>

      <RootSidebar
        onClose={() => setIsRemovingSelectedAnswer(true)}
        title={<AiAnswerSidebarTitle answer={selectedAnswer || debouncedSelectedAnswer} />}
        isOpen={!!selectedAnswer}
        className='AiAnswersTab__RootSidebar'
      >
        <AiAnswerSidebar answer={selectedAnswer || debouncedSelectedAnswer} />
      </RootSidebar>
    </div>
  )
}

export default Answers
