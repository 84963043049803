/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import {
  Redirect, useParams, useLocation, useHistory
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import TabSelector from 'components/common/tabSelector'
import InfiniteScroller from 'components/common/infiniteScroller'
import Tag from 'components/common/tag'
import ArticleCard from 'components/articles/articleCard'
import ReactSelect from 'components/common/react_select'
import { components } from 'react-select'

import newsSlice from 'redux/slices/news'
import tagSlice from 'redux/slices/tags'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { trackEvent } from 'services/tracker'

const I18N = i18nPath('views.news')

const NewsPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { articleTypeName } = useParams()
  const { inMobileApp } = useCurrentUser()

  const [selectedTag, setSelectedTag] = useState()
  const isTagSelected = !_.isEmpty(selectedTag)

  const tags = useSelector(tagSlice.selectors.getTags())
  const { isLoading: isLoadingTags } = useSelector(tagSlice.selectors.getMetaData())

  const articleTypes = useSelector(newsSlice.selectors.getArticleTypes())

  const articles = useSelector(newsSlice.selectors.getArticles())
  const {
    isLoading: isLoadingNews,
    queryParams,
    isLoadingArticleTypes,
  } = useSelector(newsSlice.selectors.getMetaData())
  const showNoResults = !isLoadingNews && _.isEmpty(articles)

  const articleTypesTabNames = articleTypes.map(articleType => ({
    linkPath: articleType.nameParam,
    linkName: articleType.name,
  }))
  const articleTypeTabs = [{ linkPath: 'all', linkName: I18N('all') }, ...articleTypesTabNames]

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tagId = searchParams.get('tagId')
    const tagName = searchParams.get('tagName')

    tagId && setSelectedTag({ id: tagId, name: tagName })

    dispatch(tagSlice.asyncActions.fetchTags())
    dispatch(newsSlice.asyncActions.fetchArticleTypes())

    trackEvent('article:view_all')

    return () => {
      dispatch(newsSlice.actions.clearArticles())
      dispatch(newsSlice.actions.clearQueryParams())
    }
  }, [])

  useEffect(() => {
    if (!isLoadingArticleTypes) {
      dispatch(newsSlice.asyncActions.selectArticleType(articleTypeName, selectedTag?.id))
    }
  }, [articleTypeName, isLoadingArticleTypes])

  const handleTagChange = (tag) => {
    const tagId = tag ? tag.id : null

    // tag name must be encoded by encodeURIComponent so ampersand becomes %26
    // For example, a tag named 'Analytics & Reporting' if not encoded would be interpreted
    // as 2 different query parameters
    const queryParam = tagId ? `?tagId=${tagId}&tagName=${encodeURIComponent(tag.name)}` : ''
    history.push({ search: queryParam })

    setSelectedTag(tag)

    dispatch(newsSlice.actions.clearArticles())
    dispatch(newsSlice.actions.clearQueryParams())
    dispatch(newsSlice.asyncActions.fetchArticles(articleTypeName, { ...queryParams, page: 1, tagId }))
  }

  const handleFetch = () => {
    dispatch(
      newsSlice.asyncActions.fetchArticles(
        articleTypeName,
        {
          ...queryParams,
          page: queryParams.page + 1,
          tagId: selectedTag?.id,
        })
    )
  }

  const hasMoreContent = () => queryParams.page < queryParams.totalPages

  if (!articleTypeName) {
    const { search } = location
    return <Redirect exact from='/news/' to={{ pathname: '/news/all', search }} />
  }

  return (
    <div className='NewsPage'>
      <TabSelector
        className={classNames(!inMobileApp && 'mt-5')}
        baseUrl='/news/'
        centerTabs
        tabs={articleTypeTabs}
        showTabs={!inMobileApp}
      >
        <div className='NewsPage-TagFilter d-flex mt-4'>
          <label className='Filter-label label m-0 mr-2 p-1 d-flex align-items-center'>{I18N('filter_by')}</label>

          <div className='Article-filtering-container position-relative'>
            <div
              className={classNames('d-flex align-items-center NewsPage-filter-select position-absolute', {
                'filter-select-fadeIn zindex-base': !isTagSelected,
                'filter-select-fadeOut': isTagSelected,
              })}
            >
              <ReactSelect
                isLoading={isLoadingTags}
                id='cy_news_page_tag_select'
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={tags}
                placeholder={I18N('select_tag')}
                components={{
                  Option: props => (
                    <components.Option {...props}>
                      <Tag className='ml-auto mb-1 mr-1' text={props.data.name} />
                    </components.Option>
                  ),
                }}
                value={null}
                onChange={handleTagChange}
              />
            </div>

            <div
              className={classNames('d-flex align-items-center NewsPage-filter-tag position-absolute', {
                'filter-tag-fadeIn': isTagSelected,
                'filter-tag-fadeOut': !isTagSelected,
              })}
            >
              <Tag
                className='h-auto m-0 mb-1 mr-1'
                text={_.get(selectedTag, 'name')}
                key={_.get(selectedTag, 'id')}
                deleteIcon
                onClick={() => handleTagChange(null)}
              />
            </div>
          </div>
        </div>
        <InfiniteScroller
          className='NewsPage-InfiniteScroller mt-4'
          isFetching={isLoadingNews}
          onFetch={handleFetch}
          hasMoreContent={hasMoreContent}
        >
          {articles?.map(article => (
            <div key={article.id} className='mb-4'>
              <ArticleCard article={article} tracking onTagClick={handleTagChange} readOnly />
            </div>
          ))}
        </InfiniteScroller>
        {showNoResults && (<NoResultsFound />)}
      </TabSelector>
    </div>
  )
}

const NoResultsFound = () => (
  <div className='NewsPage-NoResults'>
    <div className='text-normal text-secondary text-center h-100 w-100'>
      {I18n.t('views.common.no_results_found')}
    </div>
  </div>
)

export default NewsPage
