import React, { useMemo, useState } from 'react'

import BackButton from 'components/common/backButton'
import Card from 'components/common/card'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import ReactSelect from 'components/common/react_select'
import useApi from 'components/common/hooks/useApi'
import RoundedAvatars from 'components/common/roundedAvatars'
import TrashIcon from 'components/icons/trashIcon'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { i18nPath } from 'utils/i18nHelpers'
import CorrespondentType from 'types/journeys/correspondent'
import DeleteCorrespondentModal from 'components/admin/journeys/modals/deleteCorrespondentModal'
import ToggleSwitch from 'components/common/toggleSwitch'
import useIsCleary from 'components/common/hooks/useIsCleary'
import currentCompanySlice from 'redux/slices/currentCompany'
import { useDispatch } from 'react-redux'

const SPECIFIC_GROUP_ROLE = 'specific_group'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.settings')

const SMSNotificationSetting = () => {
  const dispatch = useDispatch()
  const { settings: { journeys } } = useCurrentCompany()
  const [isSmsEnabled, setIsSmsEnabled] = useState(journeys.smsNotifications.enabled)

  const onSuccess = (data) => {
    dispatch(currentCompanySlice.actions.updateCurrentCompanySetting({ key: data.name, value: data.value }))
  }

  const [updateConfig] = useApi(API.admin.companyConfigurations.update, {
    onSuccess,
    toastSuccessMessage: I18N('sms_setting_updated'),
  })

  const onSmsEnabledChange = () => {
    setIsSmsEnabled(!isSmsEnabled)
    updateConfig({
      id: 'journeys',
      value: {
        smsNotifications: { enabled: !isSmsEnabled },
      },
    })
  }

  return (
    <div className='SMSNotificationSetting mb-5'>
      <h5>{I18N('sms')}</h5>
      <p className='text-small text-secondary'>{I18N('sms_description')}</p>

      <ToggleSwitch
        checked={isSmsEnabled}
        onChange={onSmsEnabledChange}
        text={I18N('enable_sms')}
        textPosition='after'
      />
    </div>
  )
}

const JourneyBlueprintSettingsPage = () => {
  const isCleary = useIsCleary()
  const { groupDisplayName, pluralGroupDisplayName } = useCurrentCompany()
  const [journeyCorrespondents, setJourneyCorrespondents] = useState<CorrespondentType[]>([])
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [correspondentToDelete, setCorrespondentToDelete] = useState<any>(null)
  const isDeleteModalOpen = correspondentToDelete?.stepTemplatesCount > 0

  const { data: groups = [], isLoading: isLoadingGroups } = useFetch(() => API.admin.groups.fetchAll({ filter: 'active' }), [])

  const options = useMemo(() => groups.filter(
    group => !journeyCorrespondents.some(correspondent => correspondent.group?.id === group.id
    )), [groups, journeyCorrespondents])

  const { isLoading: isLoadingJourneyCorrespondents } = useFetch(
    () => API.admin.journey.correspondents.fetchAll({ role: SPECIFIC_GROUP_ROLE, includeGroupMembers: true }),
    [],
    { onSuccess: setJourneyCorrespondents }
  )

  const isLoading = isLoadingGroups || isLoadingJourneyCorrespondents

  const [createCorrespondent] = useApi(API.admin.journey.correspondents.create, {
    onSuccess: (data) => {
      setJourneyCorrespondents(prev => [...prev, data])
      setSelectedGroup(null)
    },
    toastSuccessMessage: I18N('group_added', { groupDisplayName }),
  })

  const [deleteCorrespondent, { isLoading: isDeleting }] = useApi(API.admin.journey.correspondents.destroy, {
    toastSuccessMessage: I18N('group_removed', { groupDisplayName }),
    onSuccess: () => {
      removeItem(correspondentToDelete?.id)
      setCorrespondentToDelete(null)
    },
  })

  const onGroupSelect = (value) => {
    setSelectedGroup(value)
    const params = { role: SPECIFIC_GROUP_ROLE, group_id: value.id, includeGroupMembers: true }
    createCorrespondent(params)
  }

  const removeItem = (correspondentId) => {
    setJourneyCorrespondents(prev => prev.filter(c => c.id !== correspondentId))
  }

  const onDeleteCorrespondent = (correspondent) => {
    setCorrespondentToDelete(correspondent)

    if (correspondent.stepTemplatesCount === 0) {
      deleteCorrespondent(correspondent.id)
      removeItem(correspondent.id)
    }
  }

  return (
    <div className='JourneyBlueprintSettingsPage'>
      <header className='AdminHeader'>
        <h3 className='mb-0'>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton
          url='/admin/journeys'
          className='link-color bold mb-4'
        />

        <Card>
          {isCleary && <SMSNotificationSetting />}

          <h5>{pluralGroupDisplayName}</h5>
          <p className='text-small text-secondary'>{I18N('select_groups', { pluralGroupDisplayName })}</p>

          {isLoading ? (
            <CirclesLoadingIndicator />
          ) : (
            <>
              <div className='GroupCorrespondentsGrid'>
                <div className='text-small font-weight-600 mb-2'>{groupDisplayName}</div>
                <div className='text-small font-weight-600 mb-2'>{I18N('group_members', { groupDisplayName })}</div>
                {journeyCorrespondents.map(correspondent => (
                  <React.Fragment key={correspondent.id}>
                    <ReactSelect
                      value={correspondent.group}
                      isDisabled
                      getOptionValue={group => group.id}
                      getOptionLabel={group => group?.name}
                    />

                    <div className='d-flex justify-content-between align-items-center'>
                      {_.isEmpty(correspondent.group?.users) ? (
                        <p className='m-0'>{I18N('no_members')}</p>
                      ) : (
                        <RoundedAvatars
                          users={correspondent.group?.users}
                          avatarSize='30px'
                        />
                      )}

                      <div className='pointer' onClick={() => onDeleteCorrespondent(correspondent)}>
                        <TrashIcon className='TrashIcon' />
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                <ReactSelect
                  options={options}
                  onChange={onGroupSelect}
                  value={selectedGroup}
                  isDisabled={false}
                  getOptionValue={group => group.id}
                  getOptionLabel={group => group?.name}
                />
              </div>
            </>
          )}

        </Card>

        {isDeleteModalOpen && (
          <DeleteCorrespondentModal
            visible
            correspondent={correspondentToDelete}
            closeModal={() => setCorrespondentToDelete(null)}
            deleteCorrespondent={deleteCorrespondent}
            journeyCorrespondents={journeyCorrespondents}
            isDeleting={isDeleting}
          />
        )}
      </main>
    </div>
  )
}

export default JourneyBlueprintSettingsPage
