type SourceType = {
  title: string
  iconUrl: string
  url: string
  type: string
  id: string
}

export enum AssistantAnswerStatuses {
  NotAnswered = 'not_answered',
  AnsweredNoFeedback = 'answered_no_feedback',
  AnsweredCorrect = 'answered_correct',
  AnsweredIncorrect = 'answered_incorrect',
}

export interface AssistantAnswerType {
  id: string
  sources: SourceType[]
  confidenceScore: number
  status: AssistantAnswerStatuses
}
