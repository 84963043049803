import React from 'react'
import { useDispatch } from 'react-redux'

import Session from 'services/session'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { i18nPath } from 'utils/i18nHelpers'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CdnSvg from 'components/common/cdnSvg'

const PersonIcon = '/images/personIcon.svg'

const I18N = i18nPath('views.navbar')

const ProfileDropdown = ({
  inPreboardingExperience,
}) => {
  const currentCompany = useCurrentCompany()
  const currentUser = useCurrentUser()

  const { hasEditableGroups, permissions } = currentUser
  const showMyPostsLink = currentCompany.teamPostsEnabled && hasEditableGroups
  const showMyTasksLink = currentCompany.settings?.journeys?.enabled
    && currentCompany.settings?.journeys?.stepTypes?.tasks?.enabled

  const showPlatformAnalytics = permissions?.analyticsAdmin
  const showHelpLink = Object.entries(permissions).some(([key, value]) => key.includes('Manager') && value === true)

  const handleLogout = (e) => {
    e.preventDefault()
    Session.logout()
  }

  const profileImage = (
    <div className='Navbar-navItemContainer' data-testid='cy_navbar_profile_image'>
      {currentUser.primaryPhotoThumbnailUrl.includes('images/profile_small-missing') ? (
        <CdnSvg className='PersonIcon' src={PersonIcon} />
      ) : (
        <EmployeeThumbnailPhoto className='Navbar-profileImage border-0 ' employee={currentUser} />
      )}
    </div>
  )

  const preboardingUserDropdownLinks = [
    showMyTasksLink && { linkTo: '/my_tasks', title: I18N('my_tasks') },
    { linkTo: '#', title: I18N('logout'), onClick: handleLogout },
  ].filter(Boolean)

  const userDropdownLinks = [
    { linkTo: `/profile/${currentUser.username}`, title: I18N('profile') },
    showMyPostsLink && { linkTo: '/my_posts', title: I18N('my_posts') },
    showMyTasksLink && { linkTo: '/my_tasks', title: I18N('my_tasks') },
    showPlatformAnalytics && { linkTo: '/platform_analytics', title: I18N('analytics') },
    { linkTo: '/settings', title: I18N('settings') },
    showHelpLink && { linkTo: 'https://gocleary.zendesk.com/hc/en-us', title: I18N('help'), externalUrl: true },
    { linkTo: '#', title: I18N('logout'), onClick: handleLogout },
  ].filter(Boolean)

  const userLinks = () => {
    if (inPreboardingExperience) {
      return preboardingUserDropdownLinks
    }

    return userDropdownLinks
  }

  return (
    <DropdownMenuContainer
      toggleContent={profileImage}
      dropdownOffsetPx={-1}
      navLinks={userLinks()}
    />
  )
}
export default ProfileDropdown
