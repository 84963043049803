import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import StepVariablesDropdown from 'components/admin/journeys/stepVariablesDropdown'
import CharsRemaining from 'components/common/charsRemaining'
import { i18nPath } from 'utils/i18nHelpers'
import communicationTemplateSlice from 'redux/slices/journeys/communicationTemplates'
import SendPreviewSmsButtonAndModal from 'components/admin/sendPreviewSmsButtonAndModal'
import communicationSlice from 'redux/slices/journeys/communications'
import CdnSvg from 'components/common/cdnSvg'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

const I18N = i18nPath('views.admin.journeys.communication_templates.content.sms')

const MAX_SMS_CHAR = 150

const stripHtml = (html: string): string => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html

  // Replace <br>, </p>, </div> tags with newlines
  tmp.innerHTML = tmp.innerHTML.replace(/<br\s*\/?>/gi, '\n')
  tmp.innerHTML = tmp.innerHTML.replace(/<\/p>/gi, '\n\n')
  tmp.innerHTML = tmp.innerHTML.replace(/<\/div>/gi, '\n')

  // Get the text content
  let text = tmp.textContent || tmp.innerText || ''

  // Trim extra whitespace and newlines
  text = text.replace(/\n{3,}/g, '\n\n') // Replace 3 or more newlines with 2
  text = text.trim()

  return text
}

const SmsContent = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  journeyBlueprint,
  isStepTemplate,
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const showLoadEmailContent = workingCopy.sendToEmail && !isHtmlStringEmpty(workingCopy.content) && !isDisabled

  const {
    isSendingPreviewSms: isSendingCommTemplatePreviewSms,
  } = useSelector(communicationTemplateSlice.selectors.getMetaData())

  const {
    isSendingPreviewSms: isSendingCommPreviewSms,
  } = useSelector(communicationSlice.selectors.getMetaData())

  const isSendingPreviewSms = isSendingCommTemplatePreviewSms || isSendingCommPreviewSms

  const onSendPreviewSms = (userIds, onSuccess) => {
    if (isStepTemplate) {
      dispatch(communicationTemplateSlice.asyncActions.admin.sendPreviewSms(id, userIds, onSuccess))
    } else {
      dispatch(communicationSlice.asyncActions.admin.sendPreviewSms(id, userIds, onSuccess))
    }
  }

  const copyContentFromEmail = () => {
    const strippedContent = stripHtml(workingCopy.content)
    const truncatedContent = strippedContent.slice(0, MAX_SMS_CHAR)
    updateWorkingCopy({ smsContent: truncatedContent })
  }

  useEffect(() => {
    if (workingCopy.content && !workingCopy.smsContent) {
      copyContentFromEmail()
    }
  }, [])

  return (
    <div>
      <div className='form-group'>
        <div className='d-flex justify-content-between align-items-end mb-2'>
          <label className='required-form-label mb-0'>{I18N('message')}</label>
          <div className='d-flex align-items-center'>
            {showLoadEmailContent && (
              <div className='d-flex align-items-center pointer link-color mr-2' onClick={copyContentFromEmail}>
                <CdnSvg src='/images/syncIcon.svg' className='SyncIcon' />
                <span className='ml-1 text-small'>{I18N('load_email_content')}</span>
              </div>
            )}
            <SendPreviewSmsButtonAndModal
              isSending={isSendingPreviewSms}
              onSend={onSendPreviewSms}
              buttonSecondary
            />
          </div>
        </div>
        <textarea
          placeholder={I18N('message_placeholder')}
          rows={5}
          onChange={({ target }) => {
            const truncatedValue = target.value.slice(0, MAX_SMS_CHAR)
            updateWorkingCopy({ smsContent: truncatedValue })
          }}
          value={workingCopy.smsContent}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
        <CharsRemaining maxLength={MAX_SMS_CHAR} text={workingCopy.smsContent} />

        {!isDisabled && (
          <StepVariablesDropdown journeyBlueprint={journeyBlueprint} />
        )}
      </div>
    </div>
  )
}

export default SmsContent
