import React, { useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'

import TasksAdminTable, { MY_TASKS, ALL_OPEN_TASKS, ALL_COMPLETED_TASKS } from 'components/admin/journeys/tasks/tasksAdminTable'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import API from 'services/api'

const I18N = i18nPath('views.admin.journeys.tasks.task_list_page')

const FIXED_TABS = [MY_TASKS, ALL_OPEN_TASKS, ALL_COMPLETED_TASKS]
const SPECIFIC_GROUP_ROLE = 'specific_group'

const TaskListPage = () => {
  const { selectedTab } = useParams()
  const { settings } = useCurrentCompany()

  const { data: groupCorrespondents = [] } = useGlobalStateBucket(
    () => API.admin.journey.correspondents.fetchAll({ role: SPECIFIC_GROUP_ROLE, includeGroupMembers: true }),
    'groupCorrespondents',
    settings.groups.enabled
  )

  const { filteredGroupCorrespondents, groupTabNames } = useMemo(() => {
    const filtered = groupCorrespondents.filter(c => c.group?.isCurrentUserMember)
    const names = filtered.map(c => `group-${c.group?.id}`)
    return { filteredGroupCorrespondents: filtered, groupTabNames: names }
  }, [groupCorrespondents])

  const possibleTabs = useMemo(() => [...FIXED_TABS, ...groupTabNames], [groupTabNames])

  if (!selectedTab || !possibleTabs.includes(selectedTab)) {
    return <Redirect exact to='/admin/tasks/my_tasks' />
  }

  const groupTabs = filteredGroupCorrespondents.map(c => ({ id: `group-${c.group?.id}`, text: I18N('group_tab_name', { groupName: c.group?.name }) }))

  return (
    <div className='TaskListPage'>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('tasks')}</h3>
      </header>
      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          baseUrl='/admin/tasks'
          historyKeyToChange='pathname'
          tabs={[
            { id: MY_TASKS, text: I18N(`${MY_TASKS}_tab_name`) },
            ...groupTabs,
            { id: ALL_OPEN_TASKS, text: I18N(`${ALL_OPEN_TASKS}_tab_name`) },
            { id: ALL_COMPLETED_TASKS, text: I18N(`${ALL_COMPLETED_TASKS}_tab_name`) },
          ]}
          pillClasses='text-normal'
        />
        <TasksAdminTable />
      </main>
    </div>
  )
}

export default TaskListPage
