import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

import { i18nPath } from 'utils/i18nHelpers'
import { Popover, PopoverBody } from 'reactstrap'
import ListToggle from 'components/icons/listToggle'
import FormErrorList from 'components/errors/formErrorList'
import linkSlice from 'redux/slices/links'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import ImageSelector from 'components/common/imageSelector'
import SuggestedIcon from 'components/common/suggestedIcon'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AliasSection from 'components/links/aliasSection'
import { useDebounce } from 'usehooks-ts'
import ExternalLinkIcon from 'components/icons/externalLinkIcon'
import NameTakenErrorMessage from 'components/links/nameTakenErrorMessage'
import classNames from 'classnames'

const I18N = i18nPath('views.golinks_editor')

const URL_PARAM_REGEX = /\{\d\}/

const LinkForm = ({
  link, setLink, disableInput = false, useGoLinksBranding = true,
}) => {
  const dispatch = useDispatch()

  const currentCompany = useCurrentCompany()
  const isNew = !link.id

  const { error, isClaimed } = useSelector(linkSlice.selectors.getMetaData())

  const [isUrlWithParamsOpen, setIsUrlWithParamsOpen] = useState(false)
  const [showUrlWithParamsTooltip, setShowUrlWithParamsTooltip] = useState(false)
  const [showMissingParamsTooltip, setShowMissingParamsTooltip] = useState(false)
  const [alreadyExistingUrlGoLink, setAlreadyExistingUrlGoLink] = useState(null)
  const [nameTaken, setNameTaken] = useState(false)

  const [checkNameAvailability] = useApi(API.golinks.checkNameAvailability, {
    onSuccess: response => setNameTaken(!response.data.result),
  })

  const debouncedName = useDebounce(link.name, 500)

  useEffect(() => {
    if (link.id || !debouncedName) return

    checkNameAvailability(debouncedName)
  }, [debouncedName])

  const showUrlWithParamsField = isUrlWithParamsOpen || link.parameterizedUrl

  const { iconUrl } = link

  useEffect(() => {
    dispatch(linkSlice.actions.setError(null))
  }, [])

  useEffect(() => {
    const shouldShowUrlWithParamsTooltip = link.url && link.url.match(/\{/) && !showUrlWithParamsTooltip
    const shouldHideUrlWithParamsTooltip = link.url && !link.url.match(/\{/) && showUrlWithParamsTooltip
    const shouldShowMissingParamsTooltip = link.parameterizedUrl && link.parameterizedUrl !== '' && !link.parameterizedUrl.match(URL_PARAM_REGEX) && !showMissingParamsTooltip

    const parameterizedUrl = link.parameterizedUrl || ''
    const shouldHideMissingParamsTooltip = (parameterizedUrl === '' || parameterizedUrl.match(URL_PARAM_REGEX)) && showMissingParamsTooltip

    // Present the user a Popover if they're trying to use {} params in the base URL
    if (shouldShowUrlWithParamsTooltip) {
      setShowUrlWithParamsTooltip(true)
    } else if (shouldHideUrlWithParamsTooltip) {
      setShowUrlWithParamsTooltip(false)
    }

    // Present the user a Popover if they are NOT using {} params into the parameterized URL
    if (shouldShowMissingParamsTooltip) {
      setShowMissingParamsTooltip(true)
    } else if (shouldHideMissingParamsTooltip) {
      setShowMissingParamsTooltip(false)
    }
  }, [link.url])

  const debouncedUrl = useDebounce(link.url, 500)

  const [checkGoLinkExistenceByUrl, { isLoading: isCheckingGoLinkExistence }] = useApi(API.golinks.findByUrl, {
    onSuccess: goLink => setAlreadyExistingUrlGoLink(goLink),
    onError: () => setAlreadyExistingUrlGoLink(null),
  })

  useEffect(() => {
    if (link.id || !debouncedUrl || isCheckingGoLinkExistence || debouncedUrl === 'https://') return

    checkGoLinkExistenceByUrl(debouncedUrl)
  }, [debouncedUrl])

  const generateChangeHandler = fieldName => (e) => {
    let value = e.target.value
    if (fieldName === 'name') {
      value = value.trim()
    }

    setLink({ ...link, [fieldName]: value })
  }

  const handleAdvancedClick = () => {
    setIsUrlWithParamsOpen(!isUrlWithParamsOpen)
  }

  const handleImageInputChange = (icon) => {
    setLink({ ...link, icon })
  }

  return (
    <>
      {error && <FormErrorList error={error} />}

      <div className='row'>
        <div className='form-group col-md-6'>
          <label className='required-form-label'>{I18N('url')}</label>

          <Popover
            style={{ width: '400px' }}
            trigger='focus'
            placement='top-end'
            isOpen={showUrlWithParamsTooltip}
            target='url'
          >
            <PopoverBody>
              <div className='text-secondary text-small text-left p-1'>
                {I18N('advanced_tooltip')}
              </div>
            </PopoverBody>
          </Popover>

          <input
            type='url'
            placeholder={I18N('url_example')}
            value={link.url}
            disabled={disableInput}
            name='url'
            id='url'
            onChange={generateChangeHandler('url')}
          />

          {alreadyExistingUrlGoLink && (
            <div className='text-small mt-2 text-danger'>
              {I18N('url_already_used')}
              <Link to={`/golinks/${alreadyExistingUrlGoLink.name}`} className='ml-1' target='_blank'>
                {alreadyExistingUrlGoLink.name}
                <ExternalLinkIcon fillColor='var(--link-color)' height={16} width={16} />
              </Link>
            </div>
          )}
        </div>
      </div>

      {!disableInput && useGoLinksBranding && (
        <div className='row'>
          <div className='form-group col-md-6'>
            <Link to='#' onClick={handleAdvancedClick}>
              {I18N('advanced')}
              <ListToggle className={`${showUrlWithParamsField ? 'rotated' : ''} ml-1`} />
            </Link>
          </div>
        </div>
      )}

      {showUrlWithParamsField && (
        <div className='row'>
          <div className='form-group col-md-6'>
            <label>{I18N('parameterized_url')}</label>

            <Popover
              style={{ width: '400px' }}
              trigger='focus'
              placement='top-end'
              isOpen={showMissingParamsTooltip}
              target='parameterized-url'
            >
              <PopoverBody>
                <div className='text-secondary text-small text-left p-1'>
                  {I18N('missing_url_params_tooltip')}
                </div>
              </PopoverBody>
            </Popover>

            <input
              type='text'
              placeholder={I18N('parameterized_url_example', { companySubdomain: currentCompany.subdomain.toLowerCase() })}
              value={link.parameterizedUrl}
              disabled={disableInput}
              name='parameterizedUrl'
              onChange={generateChangeHandler('parameterizedUrl')}
              id='parameterized-url'
            />
          </div>

          <div className='w-100' />

          {disableInput ? (
            <div className='col-md-6 text-secondary text-small mt-0 mb-3'>{I18N('parameterized_url_helper_viewing', { goLinkName: link.name })}</div>
          ) : (
            <div className='col-md-6 text-secondary text-small mt-0 mb-3'>{I18N('parameterized_url_helper', { companySubdomain: currentCompany.subdomain.toLowerCase() })}</div>
          )}
        </div>
      )}

      {isClaimed && (
        <div className='alert alert-info'>
          <div>{I18N('previous_golink_owner_notification')}</div>
        </div>
      )}

      {link.user && (
        <div className='row'>
          <div className='form-group col-md-6'>
            <label className='d-block font-weight-bold'>{I18N('owner')}</label>
            <EmployeeLinkWithPopover user={link.user} />
          </div>
        </div>
      )}

      {useGoLinksBranding && (
        <>
          <div className='row'>
            <div className='form-group col-md-6'>
              <label className='required-form-label'>{I18N('name')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text' id='golinknameAddon'>
                      go/
                  </span>
                </div>
                <input
                  className={classNames('form-control', nameTaken && 'is-invalid')}
                  aria-describedby='golinknameAddon'
                  value={link.name}
                  name='name'
                  onChange={generateChangeHandler('name')}
                  disabled={!isNew}
                />
              </div>
            </div>
          </div>
          {nameTaken && <NameTakenErrorMessage className='text-small mb-2 ml-4 text-danger' />}
        </>
      )}

      {link.id && <AliasSection goLinkId={link.id} />}

      <div className='row'>
        <div className='form-group col-md-6'>
          <label className='required-form-label'>{I18N('display_name')}</label>
          <div className='input-group'>
            <input
              className='form-control'
              value={link.displayName || link.name}
              name='displayName'
              onChange={generateChangeHandler('displayName')}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='form-group col-md-6'>
          <label>{I18N('description')}</label>
          <input
            value={link.description}
            disabled={disableInput}
            name='description'
            onChange={generateChangeHandler('description')}
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='form-group col-md-6'>
          <label>{I18N('icon')}</label>
          <ImageSelector
            placeholderUrl={iconUrl || null}
            onImageAdded={image => handleImageInputChange(image, 'icon')}
          />
          <div className='text-small mt-2'>{I18N('icon_size_instructions')}</div>
          <div className='text-small'>
            <span className='mr-2'>{I18N('default_icon_text')}</span>
            <SuggestedIcon name={link.displayName} url={link.url} iconUrl={link.iconUrl} ignoreCustom />
          </div>
        </div>
      </div>
    </>
  )
}

export default LinkForm
