import React from 'react'
import { connect } from 'react-redux'
import SVG from 'react-inlinesvg'

const DefaultBirthdayIcon = ({
  fill = 'none', width = '87px', height = '87px', className,
}) => (
  <svg className={className} width={width} height={height} viewBox='0 0 87 87' version='1.1'>
    <defs>
      <path d='M77.9166667,85 L7.08333333,85 C3.176875,85 0,81.668 0,77.5625 L0,32.9375 C0,28.8357187 3.176875,25.5 7.08333333,25.5 L77.9166667,25.5 C81.8266667,25.5 85,28.8357187 85,32.9375 L85,77.5625 C85,81.668 81.8266667,85 77.9166667,85 Z M7.08333333,29.21875 C5.131875,29.21875 3.54166667,30.8884687 3.54166667,32.9375 L3.54166667,77.5625 C3.54166667,79.6115312 5.131875,81.28125 7.08333333,81.28125 L77.9166667,81.28125 C79.868125,81.28125 81.4583333,79.6115312 81.4583333,77.5625 L81.4583333,32.9375 C81.4583333,30.8884687 79.868125,29.21875 77.9166667,29.21875 L7.08333333,29.21875 Z' id='path-1' />
      <path d='M68.2858809,51 C59.0644233,51 50.6719839,49.9173364 42.9697842,48.9276053 C28.5368336,47.0596621 16.0759967,45.4519299 2.34424199,50.591097 C1.37644546,50.9721203 0.364824168,50.2472468 0.0763112374,49.0205378 C-0.212201693,47.7891822 0.342911793,46.5020671 1.31070833,46.1349838 C15.7180945,40.730959 29.1321198,42.4595035 43.3349905,44.2949203 C55.1640206,45.8283065 67.3947776,47.3988657 81.984767,45.4658698 C83.0036924,45.326471 83.8874915,46.2604426 83.9897492,47.5336178 C84.095659,48.8067931 83.3688986,49.9545094 82.3645815,50.0846149 C77.4671658,50.7444356 72.7779176,51 68.2858809,51 Z' id='path-2' />
      <path d='M68.2858809,68 C59.0644233,68 50.6719839,66.9173364 42.9697842,65.9276053 C28.5368336,64.0596621 16.0759967,62.4519299 2.34424199,67.591097 C1.37644546,67.9674736 0.364824168,67.2472468 0.0763112374,66.0205378 C-0.212201693,64.7891822 0.342911793,63.5020671 1.31070833,63.1349838 C15.7180945,57.730959 29.1321198,59.4595035 43.3349905,61.2949203 C55.1640206,62.8283065 67.3947776,64.3988657 81.984767,62.4658698 C83.0036924,62.3078845 83.8874915,63.2604426 83.9897492,64.5336178 C84.095659,65.8067931 83.3688986,66.9545094 82.3645815,67.0846149 C77.4671658,67.7444356 72.7779176,68 68.2858809,68 Z' id='path-3' />
      <path d='M23.375,28.75 C22.202,28.75 21.25,28.0332 21.25,27.15 L21.25,14.35 C21.25,13.4668 22.202,12.75 23.375,12.75 C24.548,12.75 25.5,13.4668 25.5,14.35 L25.5,27.15 C25.5,28.0332 24.548,28.75 23.375,28.75 Z' id='path-4' />
      <path d='M40.375,28.75 C39.202,28.75 38.25,28.0332 38.25,27.15 L38.25,14.35 C38.25,13.4668 39.202,12.75 40.375,12.75 C41.548,12.75 42.5,13.4668 42.5,14.35 L42.5,27.15 C42.5,28.0332 41.548,28.75 40.375,28.75 Z' id='path-5' />
      <path d='M49.125,29 C47.95625,29 47,28.2832 47,27.4 L47,14.6 C47,13.7168 47.95625,13 49.125,13 C50.29375,13 51.25,13.7168 51.25,14.6 L51.25,27.4 C51.25,28.2832 50.29375,29 49.125,29 Z' id='path-6' />
      <path d='M65.875,28.75 C64.70625,28.75 63.75,28.0332 63.75,27.15 L63.75,14.35 C63.75,13.4668 64.70625,12.75 65.875,12.75 C67.04375,12.75 68,13.4668 68,14.35 L68,27.15 C68,28.0332 67.04375,28.75 65.875,28.75 Z' id='path-7' />
      <path d='M23.375,8.5 C22.202,8.5 21.25,7.548 21.25,6.375 L21.25,2.125 C21.25,0.952 22.202,0 23.375,0 C24.548,0 25.5,0.952 25.5,2.125 L25.5,6.375 C25.5,7.548 24.548,8.5 23.375,8.5 Z' id='path-8' />
      <path d='M40.375,8.5 C39.202,8.5 38.25,7.548 38.25,6.375 L38.25,2.125 C38.25,0.952 39.202,0 40.375,0 C41.548,0 42.5,0.952 42.5,2.125 L42.5,6.375 C42.5,7.548 41.548,8.5 40.375,8.5 Z' id='path-9' />
      <path d='M48.875,8.5 C47.70625,8.5 46.75,7.548 46.75,6.375 L46.75,2.125 C46.75,0.952 47.70625,0 48.875,0 C50.04375,0 51,0.952 51,2.125 L51,6.375 C51,7.548 50.04375,8.5 48.875,8.5 Z' id='path-10' />
      <path d='M65.875,8.5 C64.70625,8.5 63.75,7.548 63.75,6.375 L63.75,2.125 C63.75,0.952 64.70625,0 65.875,0 C67.04375,0 68,0.952 68,2.125 L68,6.375 C68,7.548 67.04375,8.5 65.875,8.5 Z' id='path-11' />
    </defs>
    <g stroke='none' strokeWidth='1' fill={fill} fillRule='evenodd' transform='translate(-531.000000, -1734.000000)'>
      <g transform='translate(152.000000, 1693.000000)' fillRule='nonzero'>
        <g>
          <g transform='translate(7.000000, 0.000000)'>
            <g transform='translate(373.000000, 42.000000)'>
              <g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-1' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M77.9166667,85.1 L7.08333333,85.1 C3.11931097,85.1 -0.1,81.7208903 -0.1,77.5625 L-0.1,32.9375 C-0.1,28.7815521 3.12052579,25.4 7.08333333,25.4 L77.9166667,25.4 C81.8818015,25.4 85.1,28.7802753 85.1,32.9375 L85.1,77.5625 C85.1,81.7221667 81.8830159,85.1 77.9166667,85.1 Z M7.08333333,29.31875 C5.18822421,29.31875 3.64166667,30.9426354 3.64166667,32.9375 L3.64166667,77.5625 C3.64166667,79.5573646 5.18822421,81.18125 7.08333333,81.18125 L77.9166667,81.18125 C79.8117758,81.18125 81.3583333,79.5573646 81.3583333,77.5625 L81.3583333,32.9375 C81.3583333,30.9426354 79.8117758,29.31875 77.9166667,29.31875 L7.08333333,29.31875 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-2' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M68.2858809,51.1 C61.104411,51.1 54.9296692,50.5559557 44.7967412,49.262681 C44.2888454,49.1978578 43.9396666,49.1530572 42.9569491,49.0267782 C32.4026671,47.6608205 27.9467649,47.2258525 22.3941697,47.2316847 C15.195918,47.2392455 8.79223006,48.2846842 2.38087531,50.6841454 C1.35773445,51.0869578 0.282605305,50.3344506 -0.0210519048,49.0433505 C-0.319722546,47.7686424 0.255041,46.4284446 1.27558884,46.0413535 C7.99630119,43.520501 14.8018569,42.4154447 22.3993416,42.4001617 C28.1930049,42.3885072 32.808743,42.8337967 43.3478458,44.1957501 C61.1857577,46.508062 69.8382076,46.9742672 81.9712123,45.3667927 C83.0468755,45.2196317 83.9822852,46.1916117 84.089405,47.5253279 C84.1995678,48.8496297 83.4386244,50.0463123 82.3779337,50.1837195 C77.7316887,50.8097004 73.078774,51.1 68.2858809,51.1 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-3' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M68.2858809,68.1 C61.104411,68.1 54.9296692,67.5559557 44.7967412,66.262681 C44.2888454,66.1978578 43.9396666,66.1530572 42.9569491,66.0267782 C32.4026671,64.6608205 27.9467649,64.2258525 22.3941697,64.2316847 C15.195918,64.2392455 8.79223006,65.2846842 2.38048756,67.6842971 C1.35614019,68.0826664 0.282139683,67.3324708 -0.0210519048,66.0433505 C-0.319722546,64.7686424 0.255041,63.4284446 1.27558884,63.0413535 C7.99630119,60.520501 14.8018569,59.4154447 22.3993416,59.4001617 C28.1930049,59.3885072 32.808743,59.8337967 43.3478458,61.1957501 C61.1857577,63.508062 69.8382076,63.9742672 81.969445,62.3670506 C83.0411271,62.2008853 83.9816962,63.1842781 84.089405,64.5253279 C84.1995678,65.8496297 83.4386244,67.0463123 82.3779337,67.1837195 C77.7316887,67.8097004 73.078774,68.1 68.2858809,68.1 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-4' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M23.375,28.85 C22.1519475,28.85 21.15,28.0955925 21.15,27.15 L21.15,14.35 C21.15,13.4044075 22.1519475,12.65 23.375,12.65 C24.5980525,12.65 25.6,13.4044075 25.6,14.35 L25.6,27.15 C25.6,28.0955925 24.5980525,28.85 23.375,28.85 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-5' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M40.375,28.85 C39.1519475,28.85 38.15,28.0955925 38.15,27.15 L38.15,14.35 C38.15,13.4044075 39.1519475,12.65 40.375,12.65 C41.5980525,12.65 42.6,13.4044075 42.6,14.35 L42.6,27.15 C42.6,28.0955925 41.5980525,28.85 40.375,28.85 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-6' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M49.125,29.1 C47.9047486,29.1 46.9,28.3445269 46.9,27.4 L46.9,14.6 C46.9,13.6554731 47.9047486,12.9 49.125,12.9 C50.3452514,12.9 51.35,13.6554731 51.35,14.6 L51.35,27.4 C51.35,28.3445269 50.3452514,29.1 49.125,29.1 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-7' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M65.875,28.85 C64.6547486,28.85 63.65,28.0945269 63.65,27.15 L63.65,14.35 C63.65,13.4054731 64.6547486,12.65 65.875,12.65 C67.0952514,12.65 68.1,13.4054731 68.1,14.35 L68.1,27.15 C68.1,28.0945269 67.0952514,28.85 65.875,28.85 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-8' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M23.375,8.6 C22.1467715,8.6 21.15,7.60322847 21.15,6.375 L21.15,2.125 C21.15,0.896771525 22.1467715,-0.1 23.375,-0.1 C24.6032285,-0.1 25.6,0.896771525 25.6,2.125 L25.6,6.375 C25.6,7.60322847 24.6032285,8.6 23.375,8.6 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-9' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M40.375,8.6 C39.1467715,8.6 38.15,7.60322847 38.15,6.375 L38.15,2.125 C38.15,0.896771525 39.1467715,-0.1 40.375,-0.1 C41.6032285,-0.1 42.6,0.896771525 42.6,2.125 L42.6,6.375 C42.6,7.60322847 41.6032285,8.6 40.375,8.6 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-10' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M48.875,8.6 C47.6495849,8.6 46.65,7.60179388 46.65,6.375 L46.65,2.125 C46.65,0.89820612 47.6495849,-0.1 48.875,-0.1 C50.1004151,-0.1 51.1,0.89820612 51.1,2.125 L51.1,6.375 C51.1,7.60179388 50.1004151,8.6 48.875,8.6 Z' />
                </g>
                <g>
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-11' />
                  <path stroke='#FFFFFF' strokeWidth='0.2' d='M65.875,8.6 C64.6495849,8.6 63.65,7.60179388 63.65,6.375 L63.65,2.125 C63.65,0.89820612 64.6495849,-0.1 65.875,-0.1 C67.1004151,-0.1 68.1,0.89820612 68.1,2.125 L68.1,6.375 C68.1,7.60179388 67.1004151,8.6 65.875,8.6 Z' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)


class BirthdayIcon extends React.Component {
  render() {
    const customIconUrl = this.props.imageUrls.birthdayIcon

    if (customIconUrl) {
      return (
        <SVG src={customIconUrl} cacheGetRequests />
      )
    }

    return (
      <DefaultBirthdayIcon
        className={this.props.className}
        fill={this.props.fill}
        width={this.props.width}
        height={this.props.height}
      />
    )
  }
}

const mapStateToProps = state => ({
  imageUrls: state.imageUrls,
})

export default connect(mapStateToProps, dispatch => ({ dispatch }))(BirthdayIcon)
