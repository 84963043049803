import React, { useState } from 'react'

import Modal from 'components/common/modal'
import { I18NCommon } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import CsvAudienceUploader from 'components/common/csv/csvAudienceUploader'

const CSVAudienceModal = ({
  isOpen,
  toggle,
  onChange,
  header = undefined,
  description = '',
}) => {
  const [users, setUsers] = useState([])

  const handleUploadClick = () => {
    onChange(users)
    toggle()
  }

  return (
    <Modal className='CSVAudienceModal' visible={isOpen} toggle={toggle}>
      <CsvAudienceUploader
        users={users}
        setUsers={setUsers}
        header={header}
        description={description}
      />
      <div className='col d-flex justify-content-end align-items-center mt-3'>
        <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <Button onClick={handleUploadClick}>
          {I18NCommon('upload')}
        </Button>
      </div>
    </Modal>
  )
}


export default CSVAudienceModal
