import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import EditorNavbar from 'components/common/editorNavbar'
import NavigationBlocker from 'components/common/navigationBlocker'
import DeleteModal from 'components/common/modals/deleteModal'
import AutosaveTag from 'components/common/autosaveTag'
import { useDeleteCelebration } from 'pages/people/celebration_editor_page/celebrationEditorHooks'
import InvitedPeopleNavbar from 'pages/people/celebration_editor_page/invitedPeopleNavbar'
import InviteOthersButton from 'components/celebrations/greeting_card_actions/inviteOthersButton'
import ExitButton from 'components/common/exitButton'

const I18N = i18nPath('views.celebrations.greeting_card.editor')

const HeaderRight = ({
  areChangesPresent,
  workingCopy,
  celebration,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const canDelete = !!celebration.creatorId

  const { deleteCelebration, isDeleting } = useDeleteCelebration()

  return (
    <div className='d-flex gap-3 align-items-center'>
      <AutosaveTag areChangesPresent={areChangesPresent} lastUpdatedAt={workingCopy.updatedAt} />

      <InvitedPeopleNavbar workingCopy={workingCopy} />

      <InviteOthersButton
        celebration={workingCopy}
        short
      />

      {canDelete && (
        <DropdownMenuContainer className='default-dropdown' menuType='kebab' closeMenuOnItemClick>
          <CardDropdownMenuItem
            className='Card-dropdownMenuItem--danger'
            onClick={() => setShowDeleteModal(true)}
            primaryText={I18N('delete_card')}
            icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
          />
        </DropdownMenuContainer>
      )}

      {showDeleteModal && (
        <DeleteModal
          deleteText={I18N('delete_celebration_card')}
          deleteSecondaryText={I18N('delete_celebration_card_description')}
          deleteConfirm={() => deleteCelebration(celebration)}
          showDeleteModal
          closeDeleteModal={() => setShowDeleteModal(false)}
          isDeleting={isDeleting}
        />
      )}
    </div>
  )
}

const Header = ({
  celebration,
  areChangesPresent,
  workingCopy,
}) => {
  const isNew = !celebration.id

  const title = isNew ? I18N('create_celebration') : I18N('header_title', {
    user: celebration.user.preferredFullName,
    celebration_type_capitalized: celebration.celebrationTypeCapitalized,
  })

  return (
    <>
      <EditorNavbar
        left={(
          <ExitButton
            // if is a new celebration, always go back to the celebrations page
            url={isNew ? '/people/celebrations' : undefined}
            // but if is editing an existing celebration, go back to the previous page
            // or fallback to the celebration page if there's no previous page
            fallbackUrl={!isNew ? `/people/celebrations/${celebration.id}` : undefined}
            className='ExitButton'
          />
        )}
        title={title}
        right={!isNew && (
          <HeaderRight
            areChangesPresent={areChangesPresent}
            workingCopy={workingCopy}
            celebration={celebration}
          />
        )}
      />

      <NavigationBlocker isBlocked={areChangesPresent} />
    </>
  )
}

export default Header
