import React from 'react'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import LoginFormPage from 'pages/authentication/loginFormPage'
import PasswordLoginPage from 'pages/authentication/passwordLoginPage'
import AppRouter from 'components/appRouter'
import MobileAppLoginPage from 'pages/authentication/mobileAppLoginPage'
import CustomStylesApplier from 'components/customStylesApplier'

const App = () => (
  <div className='App'>
    <CustomStylesApplier />
    <BrowserRouter>
      <Switch>
        <Route path='/login' component={LoginFormPage} />
        <Route path='/mobile_app_login' component={MobileAppLoginPage} />
        <Route path='/password_login' component={PasswordLoginPage} />
        <Route path='/preboarding_login' component={LoginFormPage} />
        <Route path='/' component={AppRouter} />
      </Switch>
    </BrowserRouter>
  </div>
)

export default hot(App)
