import { createSlice } from '@reduxjs/toolkit'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import build from 'redux-object'
import { defaultActions, defaultMeta, buildDefaultMetaActions } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildGroupPayload = (group) => {
  const attributes = _.pick(group, [
    'id',
    'name',
    'slug',
    'description',
    'groupTypeId',
    'parentId',
    'active',
    'classification',
    'email',
    'image',
    'coverImage',
    'links',
    'leadIds',
  ])

  attributes.slackChannelId = group.slackChannel?.id

  return attributes
}

export const initialState = {
  groupIds: [],
  groupParentOptionIds: [],
  groupId: null,
  meta: {
    ...defaultMeta,
    // overriding isLoading true since if start as false it'll
    // show "No results" in groupListPage
    isLoading: true,
    queryParams: { ...defaultPaginationParams, perPage: 20 },
    isSaveComplete: false,
    groupParentOptions: {
      ...defaultMeta,
    },
  },
}

const adminGroupSlice = createSlice({
  name: 'adminGroups',
  initialState,
  reducers: {
    ...defaultActions,
    ...buildDefaultMetaActions('groupParentOptions'),

    setGroupIds: (state, action) => {
      state.groupIds = action.payload
    },

    setGroupId: (state, action) => {
      state.groupId = action.payload
    },

    setGroupParentOptionIds: (state, action) => {
      state.groupParentOptionIds = action.payload
    },

    setIsSaveComplete: (state, action) => {
      state.meta.isSaveComplete = action.payload
    },

    setQueryParams: (state, action) => {
      state.meta.queryParams = action.payload
    },

    clear() {
      return initialState
    },
  },
})

_.assign(adminGroupSlice, {
  asyncActions: {
    fetchGroupParentOptions: queryParams => async (dispatch) => {
      dispatch(adminGroupSlice.actions.setGroupParentOptionIds([]))
      dispatch(adminGroupSlice.actions.groupParentOptionsIsLoading(true))

      try {
        const response = await API.admin.groups.fetchAll(queryParams)
        const groupParentOptionIds = response.data.data.map(({ id }) => id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(adminGroupSlice.actions.setGroupParentOptionIds(groupParentOptionIds))
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupSlice.actions.groupParentOptionsIsNotFound(hasError))
        dispatch(adminGroupSlice.actions.groupParentOptionsSetError(error))
      } finally {
        dispatch(adminGroupSlice.actions.groupParentOptionsIsLoading(false))
      }
    },

    fetchGroups: queryParams => async (dispatch) => {
      dispatch(adminGroupSlice.actions.isLoading(true))
      dispatch(adminGroupSlice.actions.setError(null))
      dispatch(adminGroupSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.groups.fetchAll(queryParams)
        const newQueryParams = queryParamsFromHeaders(response)
        const groupIds = response.data.data.map(({ id }) => id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(adminGroupSlice.actions.setGroupIds(groupIds))
        dispatch(adminGroupSlice.actions.setQueryParams(newQueryParams))
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupSlice.actions.isNotFound(hasError))
        dispatch(adminGroupSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupSlice.actions.isLoading(false))
      }
    },

    fetchGroup: groupId => async (dispatch, getState) => {
      dispatch(adminGroupSlice.actions.isLoading(true))
      dispatch(adminGroupSlice.actions.setError(null))
      dispatch(adminGroupSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.groups.fetch(groupId)
        dispatch(entitySlice.actions.add({ data: response.data }))
        const group = build(getState().entities, 'group', response.data.data.id)

        dispatch(adminGroupSlice.actions.setGroupId(group.id))
        dispatch(adminGroupSlice.actions.isSaving(false))
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupSlice.actions.isNotFound(hasError))
        dispatch(adminGroupSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupSlice.actions.isLoading(false))
      }
    },

    updateGroup: (group, onSuccess = () => {}) => async (dispatch) => {
      dispatch(adminGroupSlice.actions.setIsSaveComplete(false))
      dispatch(adminGroupSlice.actions.isSaving(true))
      dispatch(adminGroupSlice.actions.isNotFound(false))
      dispatch(adminGroupSlice.actions.setError(null))

      try {
        const response = await API.admin.groups.update(buildGroupPayload(group))

        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(adminGroupSlice.actions.setIsSaveComplete(true))
        onSuccess()
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupSlice.actions.isNotFound(hasError))
        dispatch(adminGroupSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupSlice.actions.isSaving(false))
      }
    },

    createGroup: (group, onSuccess = () => {}) => async (dispatch) => {
      dispatch(adminGroupSlice.actions.setIsSaveComplete(false))
      dispatch(adminGroupSlice.actions.isSaving(true))
      dispatch(adminGroupSlice.actions.isNotFound(false))
      dispatch(adminGroupSlice.actions.setError(null))

      try {
        const response = await API.admin.groups.create(buildGroupPayload(group))
        const id = response.data.data.id
        const newGroup = denormalizedJsonApiResponse(response, 'group')

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(adminGroupSlice.actions.setGroupId(newGroup.id))
        dispatch(adminGroupSlice.actions.setIsSaveComplete(true))
        onSuccess(id)
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupSlice.actions.isNotFound(hasError))
        dispatch(adminGroupSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupSlice.actions.isSaving(false))
      }
    },
  },
})

_.assign(adminGroupSlice, {
  selectors: {
    getMetaData: () => state => state.adminGroups.meta,

    getGroups: () => state => state.adminGroups.groupIds.map(id => build(state.entities, 'group', id)),

    getGroup: () => state => (state.adminGroups.groupId && build(state.entities, 'group', state.adminGroups.groupId)) || {},

    getGroupParentOptions: () => state => state.adminGroups.groupParentOptionIds.map(id => build(state.entities, 'group', id)),

    getGroupParentOptionsMetaData: () => state => state.adminGroups.meta.groupParentOptions,
  },
})

export default adminGroupSlice
