import React, { useState } from 'react'
import Modal from 'components/common/modal'
import { LayoutCollectionType, PageTypeEnum } from 'types/layout/layoutCollection'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import buildLayoutCollectionPayload from 'utils/layout/buildLayoutCollectionPayload'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

type Props = {
  layoutCollection: LayoutCollectionType
  isOpen: boolean
  onClose: () => void
  onSave: (layoutCollection: LayoutCollectionType) => void
}

const I18N = i18nPath('views.admin.layout_collections_dropdown')

const LayoutCollectionAudienceSelectorModal = ({
  layoutCollection,
  isOpen,
  onClose,
  onSave,
}: Props) => {
  const [workingCopy, _setWorkingCopy, updateWorkingCopy] = useUpdatableState({
    id: layoutCollection.id,
    targetingRules: layoutCollection.targetingRules,
  })
  const [isSaving, setIsSaving] = useState(false)

  const handleSave = async () => {
    setIsSaving(true)
    await onSave(buildLayoutCollectionPayload(workingCopy))
    setIsSaving(false)
    onClose()
  }

  const isSaveButtonDisabled = isSaving
    || _.isEqual(
      normalizeTargetingRules(layoutCollection.targetingRules),
      normalizeTargetingRules(workingCopy.targetingRules)
    )

  return (
    <Modal
      visible={isOpen}
      toggle={onClose}
      size='lg'
    >
      <header className='mb-3'>
        <h4>{I18N('audience')}</h4>
      </header>

      <hr />

      <main className='mb-4'>
        <AudienceToggleSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          translationsPath='views.admin.layout_collections_audience_toggle_selector'
          showLabel={false}
          usePreboardingAudience={layoutCollection.pageType === PageTypeEnum.Preboarding}
        />

      </main>

      <footer className='d-flex gap-3 justify-content-end align-items-center'>
        <div role='button' tabIndex={0} onClick={onClose} className='text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <Button onClick={handleSave} disabled={isSaveButtonDisabled}>
          {I18NCommon('save_changes')}
        </Button>
      </footer>
    </Modal>
  )
}

export default LayoutCollectionAudienceSelectorModal
