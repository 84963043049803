import React from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import UserLink from 'components/common/userLink'
import { PageFAQType } from 'types/page/faq'
import classNames from 'classnames'
import RoundedAvatars from 'components/common/roundedAvatars'

const I18N = i18nPath('views.pages.faqs')

type Props = {
  faq: PageFAQType
  className?: string
}

const FAQLastUpdater = ({ faq, className = '' }: Props) => {
  const {
    author, updatedAt, automaticallyGenerated, faqSources,
  } = faq

  const users = _.uniq(faqSources?.map(fs => fs.user).filter(Boolean) || [])

  if (author || !automaticallyGenerated || users.length === 0) {
    return (
      <div className={classNames('d-flex flex-wrap align-items-center gap-2 text-small text-secondary', className)}>
        <span>{I18N(author ? 'last_updated_by' : 'last_updated')}</span>
        {author && <UserLink user={author} />}
        <span>{i18nMoment(updatedAt).fromNow()}</span>
      </div>
    )
  }

  return (
    <div className={classNames('d-flex flex-wrap align-items-center gap-2 text-small text-secondary', className)}>
      <span>{I18N('generated_by_bot', { count: users.length })}</span>
      {users.length === 1 ? (
        <UserLink user={users[0]} />
      ) : (
        <RoundedAvatars users={users} />
      )}
      <span>{i18nMoment(updatedAt).fromNow()}</span>
    </div>
  )
}

export default FAQLastUpdater
