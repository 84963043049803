import { Button } from 'components/common/buttons'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import managedGroupSlice from 'redux/slices/managedGroups'
import { useSelector, useDispatch } from 'react-redux'
import { audienceSummaryText } from 'utils/audience'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ChatChannel from 'components/common/chatChannel'
import ChatUserGroup from 'components/common/chatUserGroup'
import CdnSvg from 'components/common/cdnSvg'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const coworkersImagePath = '/images/coworkersImage.svg'
const I18N = i18nPath('views.admin.managed_groups')

const CreateNewGroupButton = () => (
  <Link className='CreateNewButton' to='/admin/managed_groups/new'>
    <Button>
      {I18N('manage_group_membership')}
    </Button>
  </Link>
)

const EmptyListState = () => {
  const { appName } = useCurrentCompany()

  return (
    <div className='EmptyListState background-color-white p-4 d-flex flex-row'>
      <CdnSvg src={coworkersImagePath} className='mr-5' />
      <div className='d-flex flex-column justify-content-center'>
        <h4>{I18N('empty_state_title')}</h4>
        <p className='text-secondary'>{I18N('empty_state_description', { appName })}</p>
        <CreateNewGroupButton />
      </div>
    </div>
  )
}

const AppliesToText = ({ targetingRules }) => {
  // We get targeting options so that we can populate correctly the membership definition column when it's a department for example
  const targetingOptions = useTargetingOptions()

  return audienceSummaryText(targetingRules, targetingOptions)
}

const ManagedGroupChannel = ({ managedGroupChannel: { channel }, managedGroupChannel }) => {
  switch (managedGroupChannel.channelType) {
  case 'Chat::SlackChannel':
    return <ChatChannel channel={channel} linkEnabled={false} />
  case 'Chat::SlackUserGroup':
    return <ChatUserGroup userGroup={channel} />
  case 'GoogleGroup':
    return channel.email
  default:
    return null
  }
}

const LinkedGroups = ({ managedGroupChannels = [] }) => (
  managedGroupChannels.map((managedGroupChannel, i) => (
    <div key={managedGroupChannel.id}>
      <ManagedGroupChannel managedGroupChannel={managedGroupChannel} />
    </div>
  ))
)

const columns = [
  {
    header: I18N('list.group'),
    accessor: d => <Link to={`/admin/managed_groups/${d.id}`}>{d.name}</Link>,
    style: { width: '30%' },
  },
  {
    header: I18N('list.membership_definition'),
    accessor: d => <AppliesToText targetingRules={d.targetingRules} />,
    style: { width: '30%' },
  },
  {
    header: I18N('list.members'),
    accessor: d => d.memberCount || 0,
    style: { width: '20%' },
  },
  {
    header: I18N('list.managed_groups_or_channels'),
    accessor: d => <LinkedGroups managedGroupChannels={d.managedGroupChannels} />,
    style: { width: '20%' },
  },
]

const ManagedGroupsTable = () => {
  const dispatch = useDispatch()
  const managedGroups = useSelector(managedGroupSlice.selectors.getManagedGroups()) || []

  const { isLoading, queryParams } = useSelector(managedGroupSlice.selectors.getMetaData())
  const {
    total, totalPages, page, perPage,
  } = queryParams

  const fetchManagedGroups = (params) => {
    dispatch(managedGroupSlice.asyncActions.admin.fetchAll(params))
  }

  useEffect(() => {
    fetchManagedGroups({ page: 1 })

    // We get targeting options so that we can populate correctly the membership definition column when it's a department for example
  }, [])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (_.isEmpty(managedGroups)) {
    return <EmptyListState />
  }

  return (
    <SmartTable
      className='white-bg-table ManagedGroupsTable'
      data={managedGroups}
      columns={columns}
      onPaginationClick={fetchManagedGroups}
      showPagination
      page={page}
      pages={totalPages}
      totalCount={total}
      perPage={perPage}
    />
  )
}

export default ManagedGroupsTable
