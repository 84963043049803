import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath, i18nMomentWithTimezone } from 'utils/i18nHelpers'
import communicationSlice, { COMMUNICATION_ATTRIBUTES } from 'redux/slices/journeys/communications'
import { COMMUNICATION_TEMPLATE_INTERPOLATED_FIELDS } from 'redux/slices/journeys/communicationTemplates'
import CommunicationForm from 'components/admin/journeys/communicationTemplates/communicationForm'
import SendPreviewEmailButtonAndModal from 'components/admin/sendPreviewEmailButtonAndModal'

import { LoadingContainer } from 'components/common/loadingContainer'
import useStepManagement from 'components/admin/journeys/hooks/useStepManagement'
import EditEntityHeader from 'components/admin/journeys/common/editEntityHeader'
import StepAdminBanner from 'components/admin/journeys/stepAdminBanner'
import currentTimezone from 'utils/currentTimezone'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const DROPPED_OR_BOUNCE = ['dropped', 'bounce']

const I18N = i18nPath('views.admin.journeys.communications.edit_communication_page')

const EditCommunicationPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const currentUser = useCurrentUser()
  const isClearyAdmin = currentUser.permissions.clearyAdmin

  const communication = useSelector(communicationSlice.selectors.getCommunication(id))
  const communicationTemplate = communication?.communicationTemplate

  const {
    isLoading, isNotFound, isSendingPreviewEmail, isSendingPreviewSms, isSaving,
  } = useSelector(communicationSlice.selectors.getMetaData())

  const {
    workingCopy,
    updateWorkingCopy,
    hasChanges,
    onSave,
  } = useStepManagement({
    id,
    entity: communication,
    entityTemplate: communicationTemplate,
    entityAttributes: COMMUNICATION_ATTRIBUTES,
    fetchEntity: communicationSlice.asyncActions.admin.fetchCommunication,
    updateEntity: communicationSlice.asyncActions.admin.updateCommunication,
    interpolatedFields: COMMUNICATION_TEMPLATE_INTERPOLATED_FIELDS,
  })

  const { step, deliveryErrorReason } = communication || {}
  const { missingVariables, triggerAt } = step || {}
  const journeyBlueprint = step?.journey?.journeyBlueprint

  const timezone = step?.triggerTimezone || currentTimezone()

  const onSendPreviewEmail = (userIds, onSuccess) => {
    dispatch(communicationSlice.asyncActions.admin.sendPreviewEmail(id, userIds, onSuccess))
  }

  const onSendPreviewSms = (userIds, onSuccess) => {
    dispatch(communicationSlice.asyncActions.admin.sendPreviewSms(id, userIds, onSuccess))
  }

  const stepAdminBannerErrorText = DROPPED_OR_BOUNCE.includes(deliveryErrorReason) ? I18N(`communication_error_banner_${deliveryErrorReason}_description`) : I18N('communication_error_banner_description')

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='EditCommunicationPage'>
        <EditEntityHeader
          type='communication'
          entity={communication}
          entityTemplate={communicationTemplate}
          entityI18n={I18N}
          actionButtons={(
            <SendPreviewEmailButtonAndModal
              className='mr-2'
              areChangesPresent={false}
              isSending={isSendingPreviewEmail}
              onSend={onSendPreviewEmail}
              buttonSecondary
            />
          )}
        />
        <StepAdminBanner
          step={step}
          triggeredText={I18N('communication_sent_banner_description',
            {
              sentHour: `${i18nMomentWithTimezone({ date: triggerAt, timezone, format: 'hh:mm A' })} (${timezone})`,
              sentDate: i18nMomentWithTimezone({ date: triggerAt, timezone, format: 'll' }),
            })}
          missingVariablesText={I18N('communication_not_sent_banner_description', { missingVariables })}
          errorText={stepAdminBannerErrorText}
        />
        <main className='AdminContent'>
          {
            communication && (
              <CommunicationForm
                onSave={onSave}
                updateWorkingCopy={updateWorkingCopy}
                workingCopy={workingCopy}
                currentState='active'
                isStepTemplate={false}
                areChangesPresent={hasChanges}
                forUser={step?.forUser}
                isDisabled={communication?.sent}
                journeyBlueprint={journeyBlueprint}
                isSaving={isSaving}
              />
            )
          }
        </main>
      </div>
    </LoadingContainer>
  )
}

export default EditCommunicationPage
