import React, { useEffect, useState } from 'react'
import { Button } from 'components/common/buttons'
import { showToastMessage } from 'redux/slices/toasts'
import { useDispatch, useSelector } from 'react-redux'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import Card from 'components/common/card'
import ColumnsSelector from 'components/admin/metrics/columnsSelector'
import companyConfigurationSlice, { METRICS_DEFAULT_COLUMNS } from 'redux/slices/companyConfigurations'
import FilterSelector from 'components/admin/metrics/filterSelector'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.metrics')

export const BASE_METRICS_OPTIONS = [
  { value: 'articles', label: I18N('articles'), defaultFilter: 'published_at' },
  { value: 'shoutouts', label: I18N('shoutouts'), defaultFilter: 'created_at' },
  { value: 'golinks', label: I18N('go_links'), defaultFilter: 'created_at' },
  { value: 'users', label: I18N('users'), defaultFilter: 'start_date' },
  { value: 'birthdays', label: I18N('birthdays'), defaultFilter: 'birthday' },
  { value: 'work_anniversaries', label: I18N('work_anniversaries'), defaultFilter: 'work_anniversary' },
  { value: 'new_employees', label: I18N('mew_employees'), defaultFilter: 'start_date' },
]

// company specific metrics
const DOMAIN_METRICS_OPTIONS = {
  doordash: [
    { value: 'doordash_daily_users_by_department', label: I18N('doordash_daily_users_by_department'), defaultFilter: 'doordash' },
    { value: 'doordash_all_users_at_date', label: I18N('doordash_all_users_at_date'), defaultFilter: 'doordash' },
  ],
  cleary: [
    { value: 'all_companies_articles_sla_summary', label: I18N('all_companies_articles_sla_summary'), defaultFilter: 'published_at' },
    { value: 'all_companies_articles_sla_detailed', label: I18N('all_companies_articles_sla_detailed'), defaultFilter: 'published_at' },
  ],
}

const FORMAT_OPTIONS = [
  { value: 'csv', label: 'CSV' },
  { value: 'json', label: 'JSON' },
  { value: 'xlsx', label: 'Excel' },
]

const DEFAULT_FORMAT_OPTION = FORMAT_OPTIONS[0]
const DEFAULT_METRIC_OPTION = BASE_METRICS_OPTIONS[0]

const MetricsPage = () => {
  const { email: currentUserEmail, permissions: { superAdmin, clearyAdmin } } = useCurrentUser()
  const { subdomain } = useCurrentCompany()
  const [metric, setMetric] = useState(DEFAULT_METRIC_OPTION)
  const [format, setFormat] = useState(DEFAULT_FORMAT_OPTION)
  const [filter, setFilter] = useState(DEFAULT_METRIC_OPTION.defaultFilter)
  const [targetingRules, setTargetingRules] = useState(TARGET_ENTIRE_COMPANY)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState(clearyAdmin ? '' : currentUserEmail)
  const [columns, setColumns] = useState([])
  const dispatch = useDispatch()
  const localStorageKey = `metrics_columns_${metric.value}`

  const defaultColumnsConfig = useSelector(
    companyConfigurationSlice.selectors.getCompanyConfiguration(METRICS_DEFAULT_COLUMNS)
  )

  let metricOptions = BASE_METRICS_OPTIONS

  if (clearyAdmin) {
    metricOptions = [
      ...metricOptions,
      ...(DOMAIN_METRICS_OPTIONS[subdomain] || []),
    ]
  }

  const onSubmit = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsSubmitting(true)

    const params = {
      startDate,
      endDate,
      emailTo: email,
      filter,
    }

    if (!_.isEmpty(columns)) {
      params.columns = columns
      localStorage.setItem(localStorageKey, JSON.stringify(columns))
    }

    if (metric.value === 'users') {
      params.targetingRules = normalizeTargetingRules(targetingRules)
    }


    try {
      const response = await API.admin.metrics.generateReport(metric.value, params, format.value)
      dispatch(showToastMessage({ message: response.data, type: 'success' }))
    } catch (e) {
      dispatch(showToastMessage({ message: e.response?.data?.error?.message, type: 'error' }))
    } finally {
      setIsSubmitting(false)
    }
  }

  const isSubmitDisabled = isSubmitting || !email

  useEffect(() => {
    dispatch(companyConfigurationSlice.asyncActions.admin.fetch(METRICS_DEFAULT_COLUMNS))
  }, [])

  useEffect(() => {
    if (!metric.value) return

    // we are going to prioritize columns saved in local storage, then the default config
    const localStorageItem = localStorage.getItem(localStorageKey)
    if (localStorageItem) {
      setColumns(JSON.parse(localStorageItem))
    } else if (defaultColumnsConfig?.id) {
      setColumns(defaultColumnsConfig.value[metric.value] || [])
    }
  }, [defaultColumnsConfig?.id, metric?.value])

  useEffect(() => {
    if (metric?.value) {
      setFilter(metric?.defaultFilter)
    }
  }, [metric?.value])

  return (
    <div className='MetricsPage'>
      <header className='AdminHeader'>
        <h3 className='mb-0'>{I18N('metrics')}</h3>
      </header>

      <main className='AdminContent'>
        {!superAdmin ? (
          <p>{I18N('unauthorized_message')}</p>
        ) : (
          <Card>
            <form onSubmit={onSubmit}>
              <div className='mt-4 w-100 w-lg-75'>
                <label className='required-form-label'>{I18N('metric')}</label>
                <ReactSelect
                  name='metric'
                  className='MetricSelect'
                  value={metric}
                  options={metricOptions}
                  onChange={setMetric}
                />
              </div>

              {metric?.value === 'users' && (
                <AudienceToggleSelector
                  className='mt-4 w-100 w-lg-75'
                  workingCopy={{ targetingRules }}
                  updateWorkingCopy={val => setTargetingRules(val.targetingRules)}
                  translationsPath='views.admin.metrics.audience_toggle_selector'
                  canTargetSpecificUsers
                  hasEstimatedAudienceAvatars={false}
                />
              )}

              <FilterSelector
                metric={metric?.value}
                filter={filter}
                setFilter={setFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />

              {metric?.value === 'users' && (
                <ColumnsSelector selectedColumns={columns} setSelectedColumns={setColumns} />
              )}

              <div className='mt-4 w-100 w-lg-75'>
                <label className='required-form-label'>{I18N('format')}</label>
                <ReactSelect
                  name='format'
                  value={format}
                  options={FORMAT_OPTIONS}
                  onChange={setFormat}
                  className='FormatSelect'
                />
              </div>

              <div className='mt-4'>
                <label className='required-form-label' htmlFor='email'>{I18N('email')}</label>
                <p className='text-secondary text-small font-weight-500'>{I18N('email_helper')}</p>
                <div className='w-100 w-lg-75'>
                  <input type='email' id='email' disabled={!clearyAdmin} value={email} className='w-100' onChange={e => setEmail(e.target.value)} />
                </div>
              </div>

              <div className='mt-4 w-50'>
                <Button className='ml-auto mr-0' type='submit' disabled={isSubmitDisabled}>
                  {isSubmitting ? I18N('submitting') : I18N('submit')}
                </Button>
              </div>
            </form>
          </Card>
        )}
      </main>
    </div>
  )
}

export default MetricsPage
