import { createSlice } from '@reduxjs/toolkit'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import build from 'redux-object'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import arrayMove from 'array-move'

export const initialState = {
  groupTypeIds: [],
  groupTypeId: null,
  meta: {
    ...defaultMeta,
    queryParams: defaultPaginationParams,
  },
}

const GROUP_TYPE_ATTRIBUTES = [
  'id',
  'name',
  'profileSequence',
  'targetable',
  'collapsed',
  'viewable',
  'viewableOnOrgChart',
  'joinable',
  'followable',
  'followersEditableBy',
  'fieldsEditableBy',
  'orderPosition',
  'membersEditableBy',
  'autoJoinGroupAncestors',
  'useLabelsToCategorizeMembers',
]

const buildGroupTypeLabelPayload = groupTypeLabel => _.pick(groupTypeLabel, ['id', 'name', '_destroy'])

const buildGroupTypePayload = (groupType) => {
  const newGroupType = _.pick(groupType, GROUP_TYPE_ATTRIBUTES)

  if (!_.isEmpty(groupType?.groupTypeLabelsAttributes)) {
    // eslint-disable-next-line max-len
    newGroupType.groupTypeLabelsAttributes = groupType.groupTypeLabelsAttributes.map(gtl => buildGroupTypeLabelPayload(gtl))
  }

  return newGroupType
}

const adminGroupTypeSlice = createSlice({
  name: 'adminGroupTypes',
  initialState,
  reducers: {
    ...defaultActions,

    setGroupTypeIds: (state, action) => {
      state.groupTypeIds = action.payload
    },

    setGroupTypeId: (state, action) => {
      state.groupTypeId = action.payload
    },

    setQueryParams: (state, action) => {
      state.meta.queryParams = action.payload
    },
  },
})

_.assign(adminGroupTypeSlice, {
  asyncActions: {
    fetchGroupTypes: (queryParams = {}) => async (dispatch) => {
      dispatch(adminGroupTypeSlice.actions.isLoading(true))

      try {
        const response = await API.admin.groupTypes.fetchAll(queryParams)
        const groupTypeIds = response.data.data.map(({ id }) => id)
        const newQueryParams = queryParamsFromHeaders(response)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(adminGroupTypeSlice.actions.setGroupTypeIds(groupTypeIds))
        dispatch(adminGroupTypeSlice.actions.setQueryParams(newQueryParams))
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupTypeSlice.actions.isNotFound(hasError))
        dispatch(adminGroupTypeSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupTypeSlice.actions.isLoading(false))
      }
    },

    fetchGroupType: groupTypeId => async (dispatch, getState) => {
      dispatch(adminGroupTypeSlice.actions.isLoading(true))
      dispatch(adminGroupTypeSlice.actions.setError(null))
      dispatch(adminGroupTypeSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.groupTypes.fetch(groupTypeId)
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(adminGroupTypeSlice.actions.setGroupTypeId(response.data.data.id))
        dispatch(adminGroupTypeSlice.actions.isSaving(false))
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupTypeSlice.actions.isNotFound(hasError))
        dispatch(adminGroupTypeSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupTypeSlice.actions.isLoading(false))
      }
    },

    updateGroupType: (groupType, onSuccess = () => {}) => async (dispatch) => {
      dispatch(adminGroupTypeSlice.actions.isSaving(true))
      dispatch(adminGroupTypeSlice.actions.isNotFound(false))
      dispatch(adminGroupTypeSlice.actions.setError(null))

      try {
        const response = await API.admin.groupTypes.update(buildGroupTypePayload(groupType))

        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess()
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupTypeSlice.actions.isNotFound(hasError))
        dispatch(adminGroupTypeSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupTypeSlice.actions.isSaving(false))
      }
    },

    createGroupType: (groupType, onSuccess = () => {}) => async (dispatch) => {
      dispatch(adminGroupTypeSlice.actions.isSaving(true))
      dispatch(adminGroupTypeSlice.actions.isNotFound(false))
      dispatch(adminGroupTypeSlice.actions.setError(null))

      try {
        const response = await API.admin.groupTypes.create(buildGroupTypePayload(groupType))
        const id = response.data.data.id

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(adminGroupTypeSlice.actions.setGroupTypeId(id))
        onSuccess(id)
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupTypeSlice.actions.isNotFound(hasError))
        dispatch(adminGroupTypeSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupTypeSlice.actions.isSaving(false))
      }
    },

    setGroupTypeOrder: (groupTypes, oldIndex, newIndex) => async (dispatch) => {
      dispatch(adminGroupTypeSlice.actions.isSaving(true))

      try {
        // Moving in memory so the ui doesn't changes after drag end
        const groupIds = groupTypes.map(({ id }) => id)
        const moveGroupTypeIds = arrayMove(groupIds, oldIndex, newIndex)
        dispatch(adminGroupTypeSlice.actions.setGroupTypeIds(moveGroupTypeIds))

        const newGroupType = { ...groupTypes[oldIndex], orderPosition: newIndex }
        await API.admin.groupTypes.update(buildGroupTypePayload(newGroupType))
        const response = await API.admin.groupTypes.fetchAll({ active: 'all' })
        const groupTypeIds = response.data.data.map(({ id }) => id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(adminGroupTypeSlice.actions.setGroupTypeIds(groupTypeIds))
      } catch (e) {
        const response = getResponseOrThrow(e)
        const { hasError, error } = checkForError(response)

        dispatch(adminGroupTypeSlice.actions.isNotFound(hasError))
        dispatch(adminGroupTypeSlice.actions.setError(error))
      } finally {
        dispatch(adminGroupTypeSlice.actions.isSaving(false))
      }
    },
  },
})

_.assign(adminGroupTypeSlice, {
  selectors: {
    getMetaData: () => state => state.adminGroupTypes.meta,

    getGroupTypes: () => state => state.adminGroupTypes.groupTypeIds.map(id => build(state.entities, 'groupType', id)),

    getGroupType: () => state => state.adminGroupTypes.groupTypeId && build(state.entities, 'groupType', state.adminGroupTypes.groupTypeId),
  },
})

export default adminGroupTypeSlice
