import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import AudienceModal from 'components/common/audience/audienceModal'
import { ButtonSmallNarrow } from 'components/common/buttons'
import TargetingRulesType from 'types/audience/targetingRules'
import useAudienceUserIds from 'components/common/hooks/useAudienceUserIds'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.includes.audience_selector')

const personsIconPath = '/images/personsIcon.svg'
const peopleCount = (isEveryoneTargeted: boolean, count: number) => {
  if (isEveryoneTargeted) {
    return I18N('everyone')
  }

  return I18N('people', { count })
}

interface Props {
  targetingRules: TargetingRulesType
  includeRestricted?: boolean
}

const AudienceSummary = ({ targetingRules, includeRestricted }: Props) => {
  const { isLoading, audienceUserIds } = useAudienceUserIds({ targetingRules, includeRestricted })
  const isEveryoneTargeted = targetingRules?.targetEntireCompany

  const [showAudienceModal, setShowAudienceModal] = useState(false)

  const handleAudienceClick = () => {
    if (isEveryoneTargeted) return

    setShowAudienceModal(true)
  }

  return (
    <>
      <ButtonSmallNarrow className='AudienceSummary text-left' variant='tag' onClick={handleAudienceClick}>
        <CdnSvg src={personsIconPath} className='mr-2' />
        {isLoading ? (
          '...'
        ) : (
          peopleCount(isEveryoneTargeted, audienceUserIds.length)
        )}
      </ButtonSmallNarrow>
      {showAudienceModal && (
        <AudienceModal
          userIds={audienceUserIds}
          showAudienceModal={showAudienceModal}
          closeModal={() => setShowAudienceModal(false)}
        />
      )}
    </>
  )
}

export default AudienceSummary
