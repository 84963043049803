import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Popover } from 'reactstrap'

import { i18nPath } from 'utils/i18nHelpers'

import classNames from 'classnames'

const I18N = i18nPath('views.navbar.custom_logo')

class CompanyLogo extends Component {
  constructor(props) {
    super(props)
    this.mouseLeftTextTimer = null
  }

  state = {
    showDropdown: false,
    isMouseOverToolTip: false,
  }

  handleMouseLeftText = () => {
    this.setState(prevState => ({ showDropdown: prevState.isMouseOverToolTip }))
  }

  handleTextMouseEnter = () => {
    clearTimeout(this.mouseLeftTextTimer)
    this.setState({ showDropdown: true })
  }

  handleTextMouseLeave = () => {
    this.mouseLeftTextTimer = setTimeout(this.handleMouseLeftText, 75)
  }

  handleToolTipMouseEnter = () => {
    clearTimeout(this.mouseLeftTextTimer)
    // not sure why eslint is complaining about this
    // since we're using in handleMouseLeftText
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isMouseOverToolTip: true, showDropdown: true })
  }

  handleToolTipMouseLeave = () => {
    clearTimeout(this.mouseLeftTextTimer)
    // not sure why eslint is complaining about this
    // since we're using in handleMouseLeftText
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isMouseOverToolTip: false, showDropdown: false })
  }

  render() {
    const {
      logo,
      currentCompany,
      imageUrls,
      className,
      dimensions: { logoHeight, logoWidth } = {}, // custom logo defaults to company logo dimensions
    } = this.props

    const {
      imageUrl, link, text, width = logoWidth, height = logoHeight,
    } = logo

    const { settings } = currentCompany
    const defaultLogoUrl = imageUrls.companyLogo
    const customLogo = settings.logos.enabled && !!logo.imageUrl
    const homeUrl = _.get(settings, 'home.baseUrl')

    const customLogoDimensions = {
      width: `${width}em`,
      height: `${height}em`,
    }

    return (
      <div className={classNames('d-flex', className, { CustomLogo: customLogo })}>
        <NavLink exact to={homeUrl}>
          {customLogo ? (
            <img
              style={customLogoDimensions}
              src={imageUrl}
              alt='Navbar'
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseLeave}
              id='logo-image'
            />
          ) : (
            <img src={defaultLogoUrl} alt='Navbar' id='logo-image' />
          )}
        </NavLink>
        {customLogo ? (
          <Popover
            placement='bottom-start'
            isOpen={this.state.showDropdown}
            target='logo-image'
            // className={"EmployeePopover"}
          >
            <div
              className='m-1 p-2'
              style={{ width: '550px', maxHeight: '20em' }}
              onMouseEnter={this.handleToolTipMouseEnter}
              onMouseLeave={this.handleToolTipMouseLeave}
            >
              <a className='companyLogo-link' href={link}>
                {text}
              </a>
              <span className='ml-1'>
                {link ? (
                  <a href={link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {I18N('read_more_link')}
                  </a>
                ) : (
                  ''
                )}
              </span>
            </div>
          </Popover>
        ) : (
          <div />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  logo: state.logo,
  currentCompany: state.currentCompany,
  imageUrls: state.imageUrls,
  dimensions: state.styles?.variables?.dimensions,
})

export default connect(
  mapStateToProps,
  dispatch => ({ dispatch })
)(CompanyLogo)
