import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import { showToastMessage } from 'redux/slices/toasts'
import appSignal from 'services/appSignal'

const I18N = i18nPath('views.admin.user_skill_types')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildUserSkillTypePayload = userSkillType => _.pick(userSkillType, [
  'id',
  'name',
  'slug',
])

export const initialState = {
  meta: {
    isSaving: false,
    error: null,
    isLoading: false,
    isLoadingAdmin: false,
  },
}

const userSkillTypeSlice = createSlice({
  name: 'userSkillTypes',
  initialState,
  reducers: {
    setIsSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    setError(state, action) {
      state.meta.error = action.payload
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    setIsLoadingAdmin(state, action) {
      state.meta.isLoadingAdmin = action.payload
    },
  },
})

_.assign(userSkillTypeSlice, {
  asyncActions: {
    admin: {
      createUserSkillType: (userSkillType, history) => async (dispatch) => {
        dispatch(userSkillTypeSlice.actions.setError(null))
        dispatch(userSkillTypeSlice.actions.setIsSaving(true))

        try {
          const response = await API.admin.userSkillTypes.create(buildUserSkillTypePayload(userSkillType))
          dispatch(entitySlice.actions.add({ data: response.data }))

          history.push('/admin/user_skill_types/')
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(userSkillTypeSlice.actions.setError(error))
        } finally {
          dispatch(userSkillTypeSlice.actions.setIsSaving(false))
        }
      },

      updateUserSkillType: userSkillType => async (dispatch) => {
        dispatch(userSkillTypeSlice.actions.setError(null))
        dispatch(userSkillTypeSlice.actions.setIsSaving(true))

        try {
          const response = await API.admin.userSkillTypes.update(buildUserSkillTypePayload(userSkillType))
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(userSkillTypeSlice.actions.setError(error))
        } finally {
          dispatch(userSkillTypeSlice.actions.setIsSaving(false))
        }
      },

      deleteUserSkillType: userSkillType => async (dispatch) => {
        try {
          await API.admin.userSkillTypes.destroy(userSkillType)
          dispatch(entitySlice.actions.remove({ type: 'userSkillType', id: userSkillType.id }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        }
      },

      fetchUserSkillType: userSkillTypeId => async (dispatch) => {
        dispatch(userSkillTypeSlice.actions.setIsLoadingAdmin(true))

        try {
          const response = await API.admin.userSkillTypes.fetch(userSkillTypeId)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(userSkillTypeSlice.actions.setIsLoadingAdmin(false))
        }
      },
      fetchUserSkillTypes: () => async (dispatch) => {
        dispatch(userSkillTypeSlice.actions.setIsLoading(true))

        try {
          const response = await API.admin.userSkillTypes.fetchAll()

          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(userSkillTypeSlice.actions.setIsLoading(false))
        }
      },
    },
    fetchUserSkillTypes: () => async (dispatch) => {
      dispatch(userSkillTypeSlice.actions.setIsLoading(true))

      try {
        const response = await API.userSkillTypes.fetchAll()

        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userSkillTypeSlice.actions.setIsLoading(false))
      }
    },
  },
})

_.assign(userSkillTypeSlice, {
  selectors: {
    getUserSkillTypes: () => state => build(state.entities, 'userSkillType') || [],

    getUserSkillType: userSkillTypeId => state => build(state.entities, 'userSkillType', userSkillTypeId) || {},

    getMetaData: () => state => state.userSkillTypes.meta,
  },
})

export default userSkillTypeSlice
