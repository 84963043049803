import { useMemo } from 'react'
import TargetingRulesType from 'types/audience/targetingRules'

// This will remove any targetingRules that doesnt have value set
// plus returning undefined if there is no rule set
// to avoid api calls with blank or missing values in the targeting rules
const useCompletedTargetingRules = (targetingRules: TargetingRulesType) => {
  const validRules = targetingRules.rules.filter(r => (r.values?.length || 0) > 0)

  const validRulesJson = JSON.stringify(validRules)

  return useMemo(() => (validRules.length === 0 ? undefined : targetingRules), [validRulesJson])
}

export default useCompletedTargetingRules
