import React from 'react'
import GiphyGif from 'components/common/gifs/gif'
import Gif from 'components/common/tenor/gif'

const ShoutoutGif = ({
  shoutout,
}) => {
  if (shoutout.gifUrl) { return <Gif gifUrl={shoutout.gifUrl} className='mt-3' /> }
  if (shoutout.giphyId) { return <GiphyGif giphyId={shoutout.giphyId} className='mt-3' /> }

  return null
}

export default ShoutoutGif
