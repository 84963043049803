import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import LockIcon from 'components/icons/lockIcon'
import Tooltip from 'components/common/clearyTooltip'

const I18N = i18nPath('views.qna.events')

const Icon = ({ fillColor = 'var(--text-color-secondary)' }) => <LockIcon fillColor={fillColor} className='mr-1' />

const AccessLevel = ({
  event,
  className = '',
  showLabel = false,
  fillColor = 'var(--text-color-secondary)',
}) => {
  const isPublic = event.accessLevel === 'public_access'

  if (isPublic || !event.accessLevel) return null

  return (
    <span className={classNames('AccessLevel position-relative', className)}>
      <Tooltip
        placement='bottom'
        tooltipClassName='mt-2'
        content={(
          <>
            <Icon fillColor={fillColor} />
            {showLabel && <span className='ml-1 text-primary'>{I18N('private_event')}</span>}
          </>
        )}
      >
        <span className='text-small'>{I18N('invite_only_event')}</span>
      </Tooltip>
    </span>
  )
}

export default AccessLevel
