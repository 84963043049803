import { LayoutCollectionType } from 'types/layout/layoutCollection'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const buildLayoutCollectionPayload = (layoutCollection: LayoutCollectionType) => {
  const attributes = _.pick(layoutCollection, ['id', 'name', 'orderPosition', 'targetingRules', 'templateName', 'pageType'])

  if (layoutCollection.targetingRules) {
    attributes.targetingRules = normalizeTargetingRules(layoutCollection.targetingRules)
  }

  return attributes as LayoutCollectionType
}

export default buildLayoutCollectionPayload
