import { useState } from 'react'

const useUpdatableState = (initialState: any) => {
  const [state, setState] = useState<any>(initialState)
  const updateState = (newState: any) => setState(oldState => ({ ...oldState, ...newState }))

  return [state, setState, updateState]
}

export default useUpdatableState
