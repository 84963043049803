import useFetch from 'components/common/hooks/useFetch'
import React, { useState } from 'react'
import API from 'services/api'
import { useParams, useHistory } from 'react-router-dom'
import FAQItem from 'components/pages/faqs/faqItem'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { PageFAQType } from 'types/page/faq'
import PageBreadcrumbs from 'components/pages/pageBreadcrumbs'
import BackButton from 'components/common/backButton'

const FAQPage = () => {
  const history = useHistory()
  const { pageSlug, faqId } = useParams()
  const { data: page, isLoading: isLoadingPage } = useFetch(
    () => API.pages.fetch(pageSlug, { includePageWorkspace: true })
  )

  const [faq, setFAQ] = useState<PageFAQType | null>(null)

  const { isLoading: isLoadingFAQ } = useFetch(() => API.pagesFAQs.fetch(pageSlug, faqId), [], {
    onSuccess: setFAQ,
  })

  return (
    <div className='FAQPage container p-4'>
      {isLoadingPage || isLoadingFAQ || !faq ? (
        <CirclesLoadingIndicator />
      ) : (
        <div className='d-flex flex-column gap-3'>
          <BackButton />
          <PageBreadcrumbs page={page} workspace={page.workspace} />
          <FAQItem
            pageId={pageSlug as string}
            faq={faq as PageFAQType}
            onDelete={() => history.push(page.displayPath)}
            onUpdate={setFAQ}
            canManageFAQs={page.permittedActions.edit}
          />
        </div>
      )}
    </div>
  )
}

export default FAQPage
