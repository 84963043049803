import { i18nPath } from 'utils/i18nHelpers'
import { audienceSummaryText } from 'utils/audience'
import { WHEN_TARGETED, WHEN_TARGETING_CHANGES } from 'utils/journeys/journeyBlueprintHowUserAreAdded'

const I18N = i18nPath('views.admin.journeys.audience')

const getJourneyBlueprintAudienceText = (journeyBlueprint, targetingOptions) => {
  if (!journeyBlueprint) {
    return ''
  }

  if (journeyBlueprint.howUsersAreAdded === 'manually') {
    return I18N(`applies_to.manually_added.${journeyBlueprint.type}`)
  }

  // eslint-disable-next-line max-len
  if (journeyBlueprint.howUsersAreAdded === WHEN_TARGETED || journeyBlueprint.howUsersAreAdded === WHEN_TARGETING_CHANGES) {
    return audienceSummaryText(
      journeyBlueprint.targetingRules,
      targetingOptions,
      journeyBlueprint.type
    )
  }

  return ''
}

export default getJourneyBlueprintAudienceText
