import classNames from 'classnames'
import ReactSelect from 'components/common/react_select'
import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.companies')

interface Props {
  value: string | null
  onChange: (value: string) => void
  isDisabled?: boolean
  isClearable?: boolean
  placeholder?: string
  className?: string
}


// make sure to change Company::type enum if you're changing this
const options = [{
  value: 'full_platform',
  label: I18N('type_selector.full_platform'),
}, {
  value: 'onboarding_only',
  label: I18N('type_selector.onboarding_only'),
}]

const CompanyTypeSelector = ({
  onChange, value, className, ...props
}: Props) => (
  <ReactSelect
    className={classNames('CompanyTypeSelector', className)}
    options={options}
    value={options.find(option => option.value === value)}
    placeholder={I18NCommon('select_a_type')}
    onChange={option => onChange(option?.value)}
    openMenuOnFocus
    {...props}
  />
)

export default CompanyTypeSelector
