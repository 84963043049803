import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import ReactSelect from 'components/common/react_select'
import DatePicker from 'components/form_fields/datePicker'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import currentTimezone from 'utils/currentTimezone'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.admin.journeys.step_templates')

const JOURNEY_CREATION = 'journey_creation'
const NEW_HIRE_START_DATE = 'new_hire_start_date'
const ONBOARDING_BUDDY_ASSIGNMENT = 'onboarding_buddy_assignment'
const EMPLOYEE_END_DATE = 'employee_end_date'
const STEP_COMPLETION = 'step_completion'

export const FUTURE_ONLY_MILESTONES = [
  JOURNEY_CREATION,
  ONBOARDING_BUDDY_ASSIGNMENT,
  STEP_COMPLETION,
]

const MilestoneSelect = ({
  milestone,
  setMilestone,
  type,
  allowedMilestones = [],
  excludedMilestones = [],
}) => {
  const { settings } = useCurrentCompany()
  const journeySettings = settings.journeys

  const isOnboardingJourney = type === 'onboarding'
  const isOffboardingJourney = type === 'offboarding'
  const isCustomJourney = type === 'custom'

  const showOnboardingMilestones = isOnboardingJourney || isCustomJourney
  const showOffboardingMilestones = isOffboardingJourney || isCustomJourney

  let milestoneOptions = [
    { value: JOURNEY_CREATION, label: I18N(JOURNEY_CREATION) },
    showOnboardingMilestones && { value: NEW_HIRE_START_DATE, label: I18N(NEW_HIRE_START_DATE) },
    showOnboardingMilestones && journeySettings.onboardingBuddies?.enabled && {
      value: ONBOARDING_BUDDY_ASSIGNMENT, label: I18N(ONBOARDING_BUDDY_ASSIGNMENT),
    },
    showOffboardingMilestones && journeySettings.offboarding?.enabled && {
      value: EMPLOYEE_END_DATE, label: I18N(EMPLOYEE_END_DATE),
    },
    { value: STEP_COMPLETION, label: I18N(STEP_COMPLETION) },
  ].filter(Boolean)

  if (allowedMilestones.length > 0) {
    milestoneOptions = milestoneOptions.filter(mo => allowedMilestones.includes(mo.value))
  }

  if (excludedMilestones.length > 0) {
    milestoneOptions = milestoneOptions.filter(mo => !excludedMilestones.includes(mo.value))
  }

  if (!setMilestone || milestoneOptions.length === 1) {
    return <span className='mr-md-2 mb-md-0 mb-sm-1'>{milestoneOptions[0]?.label}</span>
  }

  return (
    <ReactSelect
      id='milestoneSelect'
      className='mr-md-2 mb-md-0 mb-sm-1'
      options={milestoneOptions}
      onChange={mo => setMilestone(mo?.value)}
      value={milestoneOptions.find(mo => mo?.value === milestone)}
    />
  )
}

const TriggerSelect = ({
  time,
  updateTime,
  numDays,
  updateNumDays,
  milestone,
  setMilestone,
  additionalText,
  onlyDays = false,
  className,
  type,
  allowedMilestones = [],
  excludedMilestones = [],
  triggerTimezone,
}) => {
  const timezone = triggerTimezone || currentTimezone()

  // Since we get the time from an utc time like 2000-01-01T{time}Z, we need to convert it to the user's timezone
  // And the offset difference with utc might be different depending on the time of the year
  const timeWithCorrectOffset = moment.utc(time).tz(timezone)
  const currentOffset = moment.tz(moment().format(), timezone).utcOffset()
  const initialOffset = moment.tz(time, timezone).utcOffset()
  const offsetDifference = (initialOffset - currentOffset) / 60
  timeWithCorrectOffset.add(-offsetDifference, 'hours')

  const timeframeOptions = [
    !FUTURE_ONLY_MILESTONES.includes(milestone) && { value: 'before', label: I18N('before') },
    { value: 'on', label: I18N('on') },
    { value: 'after', label: I18N('after') },
  ].filter(Boolean)

  const startingTimeframe = () => {
    if (numDays === 0 || (numDays < 0 && FUTURE_ONLY_MILESTONES.includes(milestone))) {
      return timeframeOptions.find(to => to.value === 'on')
    } else if (numDays < 0) {
      return timeframeOptions.find(to => to.value === 'before')
    }

    return timeframeOptions.find(to => to.value === 'after')
  }

  const [selectedTimeframe, setSelectedTimeframe] = useState(startingTimeframe())
  const days = Math.abs(numDays)
  const triggerOn = selectedTimeframe.value === 'on'
  const isFutureOnlyMilestone = FUTURE_ONLY_MILESTONES.includes(milestone)
  // For future only milestones, we'll trigger immediately if after_num_days if negative
  // If it's zero, the user can select the time they want
  const triggerImmediately = triggerOn && isFutureOnlyMilestone && numDays < 0

  useEffect(() => {
    setSelectedTimeframe(startingTimeframe())
  }, [numDays, milestone])

  const onUpdateMilestone = (newMilestone) => {
    if (FUTURE_ONLY_MILESTONES.includes(newMilestone) && selectedTimeframe.value !== 'after') {
      updateNumDays(-1)
    }

    setMilestone(newMilestone)
  }

  const onUpdateTimeFrame = (newTimeframe) => {
    if (newTimeframe.value === 'on') {
      updateNumDays(isFutureOnlyMilestone ? -1 : 0)
    }

    if (newTimeframe.value === 'before') {
      updateNumDays(-1 * parseInt(Math.max(days, 1), 10))
    }

    if (newTimeframe.value === 'after') {
      updateNumDays(parseInt(Math.max(days, 1), 10))
    }

    setSelectedTimeframe(newTimeframe)
  }

  const onDaysChange = ({ target }) => {
    if (selectedTimeframe.value === 'before') {
      updateNumDays(-1 * parseInt(target.value, 10))
    } else {
      updateNumDays(parseInt(target.value, 10))
    }
  }

  return (
    <div className={classNames('TriggerSelect d-md-flex align-items-center', className)}>
      {!triggerOn && (
        <>
          <input
            className='DaysInput mr-md-2 mb-md-0 mb-sm-1'
            name='days'
            type='number'
            value={days}
            min={0}
            onChange={onDaysChange}
          />
          <span className='mr-md-2 mb-md-0 mb-sm-1'>{I18N('days')}</span>
        </>
      )}

      {triggerImmediately && (
        <span className='mr-md-2 mb-md-0 mb-sm-1'>{I18N('immediately')}</span>
      )}

      <ReactSelect
        className='mr-md-2 mb-md-0 mb-sm-1'
        options={timeframeOptions}
        onChange={onUpdateTimeFrame}
        value={selectedTimeframe}
      />

      <MilestoneSelect
        milestone={milestone}
        setMilestone={setMilestone ? onUpdateMilestone : null}
        type={type}
        allowedMilestones={allowedMilestones}
        excludedMilestones={excludedMilestones}
      />

      {!onlyDays && !triggerImmediately && (
        <>
          <span className='mr-md-2 mb-md-0 mb-sm-1'>{I18N('at')}</span>
          <DatePicker
            id='selectTriggerTime'
            selected={time && timeWithCorrectOffset}
            onChange={newTime => updateTime(newTime.format())}
            showTimeSelect
            showTimeSelectOnly
            dateFormat='h:mm a'
            isClearable={false}
          />
        </>
      )}

      {triggerImmediately && (
        <Button
          className='pointer link-color'
          variant='tag'
          onClick={() => updateNumDays(0)}
        >
          {I18N('specify_a_time')}
        </Button>
      )}

      {additionalText && <span className='ml-md-2 mt-md-0 mt-sm-1'>{additionalText}</span>}
    </div>
  )
}

export default TriggerSelect
