import React, { useEffect, useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import RuleSelect from 'components/common/audience/ruleSelect'
import classNames from 'classnames'
import { SimpleUserType } from 'types/user'
import SpecificUsersSelect from 'components/common/audience/specificUsersSelect'
import { Button } from 'components/common/buttons'
import TargetingOptionsType from 'types/audience/targetingOptions'
import TargetingRulesType, { AudienceRuleType } from 'types/audience/targetingRules'
import { randomReactKey } from 'components/common/utils'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.admin.includes.audience_selector')

interface AudienceRulesSelectorProps {
  targetingRules: TargetingRulesType,
  onChange: (targetingRules: TargetingRulesType) => void
  targetingOptions: TargetingOptionsType
  canTargetSpecificUsers?: boolean
  className?: string
  isManagersSelectDisabled?: boolean
  hasChangesOperators?: boolean
  hasInlineAddRule?: boolean
}

// This selector "groups" OR rules into arrays of AND rules, so we tranform the targeting rules
// To an array of arrays to make it easier to work with while editing
export const transformRulesIntoOrRuleGroups = (rules) => {
  if (!rules || rules.length === 0) return []

  if (rules.length === 1 && rules[0].type === 'or') {
    return rules[0].rules.map(rule => (rule.type === 'and' ? rule.rules : [rule]))
  } else {
    return [rules]
  }
}

// We then transform from the array of arrays back to the targeting rules format that's used everywhere else
export const transformOrRuleGroupsToRules = (orRuleGroups) => {
  if (orRuleGroups.length === 0) return []

  if (orRuleGroups.length === 1 && !(orRuleGroups[0].length === 1 && orRuleGroups[0][0].type === 'or')) {
    return orRuleGroups[0]
  }

  return [{
    type: 'or',
    rules: orRuleGroups.map((orRuleGroup) => {
      if (orRuleGroup.length > 1) {
        return { type: 'and', rules: orRuleGroup }
      } else {
        return orRuleGroup[0]
      }
    }),
  }]
}

const AndButton = ({
  className = '',
  addRule,
  hasRules = true,
  dataTestId = 'cy_add_rule',
}) => (
  <Button className={className} onClick={() => addRule()} variant='tag' dataTestId={dataTestId}>
    + {I18N(hasRules ? 'and' : 'add_rule')}
  </Button>
)


const AudienceRulesSelector = ({
  targetingRules,
  onChange,
  targetingOptions,
  canTargetSpecificUsers = true,
  className = '',
  isManagersSelectDisabled = false,
  hasChangesOperators = false,
  hasInlineAddRule,
}: AudienceRulesSelectorProps) => {
  const orRuleGroups = transformRulesIntoOrRuleGroups(targetingRules?.rules || [])
  const audienceRules = targetingRules?.rules || []
  const selectedUsers = targetingRules?.users || []
  const [showSpecificUsersSelect, setShowSpecificUsersSelect] = useState(!_.isEmpty(selectedUsers))
  const { permissions: { superAdmin } } = useCurrentUser()

  const showOrButton = orRuleGroups.length > 0 && !hasInlineAddRule

  const updateRules = (newOrRuleGroups: any) => {
    onChange({
      ...targetingRules,
      rules: transformOrRuleGroupsToRules(newOrRuleGroups),
    })
  }

  const onOrClick = () => {
    updateRules([...orRuleGroups, [{ reactKey: randomReactKey() }]])
  }

  const addRule = (orRuleGroupIndex: number = orRuleGroups.length - 1) => {
    if (orRuleGroups.length === 0) {
      updateRules([[{ reactKey: randomReactKey() }]])
    } else {
      const newOrRuleGroups = [...orRuleGroups]
      newOrRuleGroups[orRuleGroupIndex] = [...newOrRuleGroups[orRuleGroupIndex], { reactKey: randomReactKey() }]

      updateRules(newOrRuleGroups)
    }
  }

  const updateRule = (orRuleGroupIndex: number, ruleIndex: number, newRule: AudienceRuleType) => {
    const newOrRuleGroups = [...orRuleGroups]
    newOrRuleGroups[orRuleGroupIndex] = [...newOrRuleGroups[orRuleGroupIndex]]
    newOrRuleGroups[orRuleGroupIndex][ruleIndex] = { ...newOrRuleGroups[orRuleGroupIndex][ruleIndex], ...newRule }
    updateRules(newOrRuleGroups)
  }

  const onRemoveRule = (orRuleGroupIndex: number, ruleIndex: number) => {
    const newOrRuleGroups = [...orRuleGroups]
    const newOrRuleGroup = [...newOrRuleGroups[orRuleGroupIndex]]

    if (newOrRuleGroup.length === 1) {
      newOrRuleGroups.splice(orRuleGroupIndex, 1)
    } else {
      newOrRuleGroup.splice(ruleIndex, 1)
      newOrRuleGroups[orRuleGroupIndex] = newOrRuleGroup
    }

    updateRules(newOrRuleGroups)
  }

  const handleUsersChange = (users: SimpleUserType[]) => {
    onChange({
      ...targetingRules,
      users,
    })
  }

  const handleRemoveSpecificUsers = () => {
    setShowSpecificUsersSelect(false)
    handleUsersChange([])
  }

  useEffect(() => {
    if (!showSpecificUsersSelect && selectedUsers?.length > 0) {
      setShowSpecificUsersSelect(true)
    }
  }, [selectedUsers?.length])

  if (!targetingOptions || !audienceRules) {
    return null
  }

  const noRuleSelected = audienceRules.length === 1 && !audienceRules[0].key


  const isRuleDisabled = (rule) => {
    if (isManagersSelectDisabled && rule?.key === 'manager') { return true }
    if (!superAdmin && rule?.key === 'restricted') { return true }

    return false
  }

  return (
    <div className={classNames('AudienceRulesSelector', className, { hasInlineAddRule, noRuleSelected })}>
      <div>
        {orRuleGroups.map((rules, orRuleGroupIndex) => (
          <div key={orRuleGroupIndex} className='or-rule-group'>
            {orRuleGroupIndex > 0 && <div className='or-title pt-2 font-weight-600'>{I18N('or_title')}</div>}
            {rules.map((rule, index) => (
              <RuleSelect
                key={rule.reactKey || `rule-${index}`}
                rule={rule}
                targetingOptions={targetingOptions}
                updateRule={newRule => updateRule(orRuleGroupIndex, index, newRule)}
                onRemoveRule={() => onRemoveRule(orRuleGroupIndex, index)}
                isDisabled={isRuleDisabled(rule)}
                hasChangesOperators={hasChangesOperators}
                onAddRule={hasInlineAddRule ? () => addRule() : undefined}
              />
            ))}
            {orRuleGroupIndex < orRuleGroups.length - 1 && (
              <AndButton
                addRule={() => addRule(orRuleGroupIndex)}
                className='mb-3'
                dataTestId='cy_add_rule_or_group'
              />
            )}
          </div>
        )
        )}
      </div>

      {showSpecificUsersSelect && (
        <SpecificUsersSelect
          selectedUsers={selectedUsers}
          onUsersChange={handleUsersChange}
          onRemoveSpecificUsers={handleRemoveSpecificUsers}
        />
      )}
      <div className='d-flex'>
        {!hasInlineAddRule && (
          <AndButton
            addRule={addRule}
            hasRules={audienceRules.length > 0}
          />
        )}

        {showOrButton && (
          <Button onClick={onOrClick} className='ml-2' variant='tag' dataTestId='cy_or_rule'>
          + {I18NCommon('or')}
          </Button>
        )}

        {canTargetSpecificUsers && !showSpecificUsersSelect && (
          <Button onClick={() => setShowSpecificUsersSelect(true)} variant='tag' className='ml-2'>
            + {I18N('add_specific_people')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default AudienceRulesSelector
