import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import Sources from 'components/search/ai/sources'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import ChatUsername from 'components/common/chatUsername'
import UserLink from 'components/common/userLink'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'

const I18N = i18nPath('views.platform_analytics.ai_answers')
const optionsI18N = i18nPath('views.additional_information_modal.ai_assistant_answers')

const AiAnswerSidebar = ({ answer }) => (
  <>
    <div className='d-flex align-items-center gap-2 mb-1'>
      <CdnSvg className='svg-highlight' src='/images/aiIcon.svg' />
      <div className='font-weight-600 text-highlight'>{I18N('sidebar.answer')}</div>
    </div>

    <AiAnswerContent content={answer?.answer} />

    <Sources sources={answer?.sources} />

    <div className='d-flex'>
      <div className='d-flex align-items-center bordered py-3 px-4 gap-3 my-4'>
        <div>
          <EmployeeThumbnailPhoto
            employee={answer?.user}
            linkToProfile
          />
        </div>

        <div>
          <UserLink
            user={answer?.user}
            hideImage
            customClassName='text-primary'
          />
          <div className='text-secondary text-small'>
            {answer?.user?.title}
          </div>
        </div>

        <div>
          <a
            className='d-flex gap-1 align-items-center'
            href={`mailto:${answer?.user?.primaryEmail}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <CdnSvg src='/images/envelopeOutlineIcon.svg' className='MessagingIcon' />
            <span className='font-weight-400 text-small text-primary'>{answer?.user?.primaryEmail}</span>
          </a>

          {answer?.user?.primaryChatUsers?.map(chatUser => (
            <div key={chatUser.id}>
              <ChatUsername
                chatUser={chatUser}
                iconClass='MessagingIcon'
                className='text-small font-weight-400'
              />
            </div>
          ))}
        </div>
      </div>
    </div>


    {answer?.answerFeedback && (
      <>
        <hr />
        <div className='text-primary text-large font-weight-600'>{I18N('sidebar.feedback')}</div>
        {answer.answerFeedback.options.map(option => (
          <li key={option}>{optionsI18N(option)}</li>
        ))}

        {answer.answerFeedback.comment && (
          <div className='mt-3 text-normal'>{answer.answerFeedback.comment}</div>
        )}
      </>
    )}
  </>
)

export default AiAnswerSidebar
