const removeQueryParam = (history, key) => {
  const { location } = history
  const { search } = location
  const params = new URLSearchParams(search)

  params.delete(key)
  history.replace({
    ...location,
    search: params.toString(),
  })
}

export default removeQueryParam
