import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useQueryParams from 'components/common/hooks/useQueryParams'
import { transformCcCorrespondentsForBackend } from 'utils/journeys/journeyVariablesHelpers'
import { STEP_ATTRIBUTES } from 'redux/slices/journeys/steps'
import useApi from 'components/common/hooks/useApi'
import { I18NCommon } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import useTransformInterpolatedFields from 'components/admin/journeys/hooks/useTransformInterpolatedFields'
import journeySlice from 'redux/slices/journeys'

const useStepCreation = ({
  defaultWorkingCopy,
  entityAttributes,
  createApiPath,
  interpolatedFields = [],
}) => {
  const history = useHistory()
  const { name } = useQueryParams()
  const { journeyBlueprintId, journeyId } = useParams()
  const journey = useSelector(journeySlice.selectors.getJourney(journeyId))
  const interpolateParams = useTransformInterpolatedFields()

  const [createEntity, { isLoading: isSaving }] = useApi(createApiPath, {
    onSuccess: entity => history.replace(entity.step.adminDisplayPath),
    toastSuccessMessage: I18NCommon('created_successfully'),
  })

  const { data: journeyBlueprint } = useFetch(
    () => API.admin.journeyBlueprints.fetch(journeyBlueprintId),
    [journeyBlueprintId]
  )

  const journeyBlueprintType = journeyBlueprint?.type
  const questionVariables = journeyBlueprint?.questionVariables || []
  // We need the journetBlueprintType in order to set the default working copy correctly
  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState({})

  const handleSave = () => {
    const params = {
      ..._.pick(workingCopy, entityAttributes),
      stepAttributes: {
        name: workingCopy.stepName || name,
        journeyId,
        ..._.pick(workingCopy, STEP_ATTRIBUTES),
        fromCorrespondentAttributes: {
          role: workingCopy.fromCorrespondentAttributes.role, userId: workingCopy.fromCorrespondentAttributes.user?.id,
        },
        toCorrespondentAttributes: {
          role: workingCopy.toCorrespondentAttributes.role, userId: workingCopy.toCorrespondentAttributes.user?.id,
        },
        ccCorrespondentsAttributes: transformCcCorrespondentsForBackend(workingCopy?.ccCorrespondentsAttributes),
      },
    }

    const interpolatedParams = interpolateParams(interpolatedFields, params, questionVariables)

    createEntity(interpolatedParams)
  }

  useEffect(() => {
    if (journeyBlueprintType) {
      const initialWorkingCopy = {
        ...defaultWorkingCopy(journeyBlueprintType),
        isCustom: true, // Only custom steps can be created with this hook
        forUser: journey?.user,
      }
      updateWorkingCopy(initialWorkingCopy)
    }
  }, [journeyBlueprintType, journey?.id])

  return {
    name: workingCopy.stepName || name,
    workingCopy,
    updateWorkingCopy,
    handleSave,
    journeyBlueprint,
    isSaving,
  }
}

export default useStepCreation
