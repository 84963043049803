import React, { useEffect, useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useAudienceUserIds, { useGroupArticleAudienceUserIds } from 'components/common/hooks/useAudienceUserIds'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import AudienceModal from 'components/common/audience/audienceModal'
import GroupPostAudienceModal from 'components/posts/groupPostAudienceModal'
import { useDispatch, useSelector } from 'react-redux'
import userSlice from 'redux/slices/users'
import API from 'services/api'

const EstimatedAudienceAvatars = ({
  targetingRules,
  targetEntireCompany = true,
  translationsPath,
  includeRestricted = false,
  usePreboardingAudience = false,
}) => {
  const I18N = i18nPath(translationsPath)
  const { isLoading, audienceUserIds } = useAudienceUserIds({
    targetingRules,
    includeRestricted,
    endpoint: usePreboardingAudience
      ? API.audiences.fetchEstimatedPreboardingAudience
      : API.audiences.fetchEstimatedAudience,
  })
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  if (targetEntireCompany) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18N('everyone_will_be_included')}</div>
    )
  }

  if (isLoading) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18NCommon('loading')}</div>
    )
  }

  if (_.isEmpty(audienceUserIds)) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18N('no_users_targeted')}</div>
    )
  }

  return (
    <>
      <RoundedAvatarsWithAsyncUserFetching
        avatarSize='30px'
        userIds={audienceUserIds}
        popOverPlacement='auto'
        onMoreUsersClick={() => setShowAudienceModal(true)}
        includeHiddenUsers={usePreboardingAudience}
      />
      <div
        onClick={() => setShowAudienceModal(true)}
        className='text-left text-normal text-secondary ml-3 mb-0 flex-grow-1 pointer'
      >
        {I18N('will_be_included', { count: audienceUserIds.length })}
      </div>
      {showAudienceModal && (
        <AudienceModal
          userIds={audienceUserIds}
          showAudienceModal={showAudienceModal}
          closeModal={() => setShowAudienceModal(false)}
          summary={I18N('audience_modal_description')}
        />
      )}
    </>
  )
}

export const EstimatedGroupArticleAudienceAvatars = ({
  targetingRules,
  translationsPath,
  groupId,
  specificallyTargetedGroups = [],
  specificallyTargetedUsers = [],
}) => {
  const dispatch = useDispatch()
  const I18N = i18nPath(translationsPath)
  const { isLoading, audienceUserIds } = useGroupArticleAudienceUserIds({ targetingRules, groupId })
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  const allUsers = useSelector(userSlice.selectors.getSimpleUsersByIds(audienceUserIds))

  useEffect(() => {
    const missingUserIds = _.difference(audienceUserIds, allUsers.map(u => u.id))

    if (!_.isEmpty(missingUserIds)) {
      dispatch(userSlice.asyncActions.simpleFetchAll(missingUserIds))
    }
  }, [JSON.stringify(audienceUserIds)])

  if (isLoading) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18NCommon('loading')}</div>
    )
  }

  if (_.isEmpty(audienceUserIds)) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18N('no_users_targeted')}</div>
    )
  }

  return (
    <>
      <RoundedAvatarsWithAsyncUserFetching
        avatarSize='30px'
        userIds={audienceUserIds}
        popOverPlacement='auto'
        onMoreUsersClick={() => setShowAudienceModal(true)}
      />
      <div
        onClick={() => setShowAudienceModal(true)}
        className='text-left text-normal text-secondary ml-3 mb-0 flex-grow-1 pointer'
      >
        {I18N('will_be_included', { count: audienceUserIds.length })}
      </div>
      {showAudienceModal && (
        <GroupPostAudienceModal
          groupId={groupId}
          allTargetedUsers={allUsers}
          specificallyTargetedGroups={specificallyTargetedGroups}
          specificallyTargetedUsers={specificallyTargetedUsers}
          onClose={() => setShowAudienceModal(false)}
        />
      )}
    </>
  )
}

export default EstimatedAudienceAvatars
