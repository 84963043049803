import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import CommonSidebar from 'components/common/sidebar'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import FormSelectField from 'components/form_fields/formSelectField'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import generateWorkingCopyFromTemplate from 'pages/people/celebration_editor_page/generateWorkingCopyFromTemplate'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import celebrationEmojis from 'components/celebrations/utils/emojis'

const I18N = i18nPath('views.home.celebrations')

const TemplateItem = ({ applyTemplate, template }) => (
  <img
    src={template.coverImageThumbUrl}
    onClick={() => applyTemplate(template)}
    className='w-100 h-auto mb-4 pointer template-image'
  />
)

interface Props {
  show: boolean
  onClose: () => void
  setWorkingCopy: (workingCopy: any) => void
  workingCopy: any
  templates: any[]
}

const Sidebar = ({
  show, onClose, setWorkingCopy, workingCopy, templates = [],
}: Props) => {
  const [currentType, setCurrentType] = useState('')
  const currentCompany = useCurrentCompany()
  const currentUser = useCurrentUser()

  useEffect(() => {
    const templateTypes = templates.map(template => template.templateType)
    const type = templateTypes.includes(workingCopy.type) ? workingCopy.type : templateTypes[0]

    setCurrentType(type)
  }, [templates])

  const templatesByType = useMemo(() => _.groupBy(templates, 'templateType'), [templates])
  const options = Object.keys(templatesByType).map(key => ({ value: key, label: I18N(key) }))

  const applyTemplate = (template) => {
    const newWorkingCopy = generateWorkingCopyFromTemplate(template, currentCompany, currentUser, workingCopy)

    setWorkingCopy(newWorkingCopy)
  }

  return (
    <div className={classNames('CelebrationEditorPageSidebar', { 'slide-in': show })}>
      <CommonSidebar
        title={I18NCommon('templates')}
        onClose={onClose}
        floating
        visible={show}
      >
        <span className='text-normal'>{I18N('select_occasion')}</span>
        <FormSelectField
          currentValue={options.find(option => option.value === currentType) as any}
          onChange={object => setCurrentType(object?.value)}
          getOptionLabel={option => `${celebrationEmojis[option.value]} ${option.label}`}
          options={options}
        />

        <div className='pb-4'>
          {templatesByType[currentType]?.map(template => (
            <TemplateItem
              key={template.id}
              applyTemplate={applyTemplate}
              template={template}
            />
          ))}
        </div>

      </CommonSidebar>
    </div>
  )
}

export default Sidebar
