import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import defaultPermissionSlice from 'redux/slices/defaultPermissions'
import { ButtonSmall } from 'components/common/buttons'
import BackButton from 'components/common/backButton'
import AudienceSelector from 'components/common/audience/audienceSelector'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.permissions')

const DefaultPermissionPage = () => {
  const { permission } = useParams()
  const dispatch = useDispatch()

  const permissionName = I18N(permission)

  useEffect(() => {
    dispatch(defaultPermissionSlice.asyncActions.fetch(permission))
  }, [])

  const { defaultPermission } = useSelector(defaultPermissionSlice.selectors.getDefaultPermissionData(permission))
  const {
    isLoading, isSaving, isUnauthorized,
  } = useSelector(defaultPermissionSlice.selectors.getMetaData())
  const [defaultPermissionWorkingCopy, setDefaultPermissionWorkingCopy] = useState(defaultPermission)

  const targetingOptions = useTargetingOptions()

  const [isNewDefaultPermission, setIsNewDefaultPermission] = useState(true)

  const handleAudienceChange = (values = {}) => {
    setDefaultPermissionWorkingCopy({ ...defaultPermissionWorkingCopy, ...values })
  }

  useEffect(() => {
    if (defaultPermission?.id) {
      setIsNewDefaultPermission(false)
      setDefaultPermissionWorkingCopy(defaultPermission)
    }
  }, [defaultPermission.id])

  const updateDefaultPermission = () => {
    const defaultPermissionParams = {
      id: defaultPermission.id,
      permission: defaultPermissionWorkingCopy.permission,
      targetingRules: defaultPermissionWorkingCopy.targetingRules,
    }

    dispatch(defaultPermissionSlice.asyncActions.update(defaultPermissionParams))
  }

  const createDefaultPermission = () => {
    const defaultPermissionParams = {
      permission: defaultPermissionWorkingCopy.permission,
      targetingRules: defaultPermissionWorkingCopy.targetingRules,
    }

    dispatch(defaultPermissionSlice.asyncActions.create(defaultPermissionParams))
  }

  if (isUnauthorized) {
    return null
  }
  return (
    <div className='container py-2'>
      <BackButton className='mb-4' />
      <h2>{I18N('target_audience_for', { permission: permissionName })}</h2>
      <div className='mt-2'>
        {defaultPermission && !isLoading && (
          <div className='row'>
            <div className='col-md-6'>
              <AudienceSelector
                workingCopy={defaultPermissionWorkingCopy}
                updateWorkingCopy={handleAudienceChange}
                targetingOptions={targetingOptions}
              />
              <div className='row mt-2 ml-1'>
                {isNewDefaultPermission ? (
                  <ButtonSmall className='text-small font-weight-normal px-1' onClick={createDefaultPermission} disabled={isSaving}>
                    {I18N('save_audience')}
                  </ButtonSmall>
                ) : (
                  <ButtonSmall className='text-small font-weight-normal px-1' onClick={updateDefaultPermission} disabled={isSaving}>
                    {I18N('update_audience')}
                  </ButtonSmall>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DefaultPermissionPage
