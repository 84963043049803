import React from 'react'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import Card from 'components/common/card'
import { VideoAnalyticsType } from 'services/api/admin/videos'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Header from './videoAnalytics/Header'
import Stats from './videoAnalytics/Stats'
import Graph from './videoAnalytics/Graph'

const reduceAbsoluteSeriesToPercentage = (series, videoAudienceCount) => Object
  .entries(series)
  .reduce((acc, [key, value]: [string, number]) => {
    acc[Number(key)] = Math.round((value * 100) / videoAudienceCount)

    return acc
  }, {})

interface Props {
  parentId: string
  parentType: string
  videoId: string
}

const VideoAnalytics = ({
  parentId, parentType, videoId,
}: Props) => {
  const { data, isLoading }: { data: VideoAnalyticsType, isLoading: boolean } = useFetch(
    API.admin.videos.analytics,
    [parentId, parentType, videoId]
  )

  if (isLoading) return <CirclesLoadingIndicator />
  if (!data) return null

  const series = reduceAbsoluteSeriesToPercentage(data.dropoffDataSeries, data.videoAudienceCount)

  return (
    <Card className='VideoAnalytics'>
      <Header
        name={data.name}
        duration={data.duration}
        uploadedAt={data.uploadedAt}
        thumbnailUrl={data.thumbnailUrl}
      />

      <hr />

      <div className='d-flex'>
        <div className='stats-part mr-4'>
          <Stats
            totalAudience={data.displayedVideoAudienceCount}
            videoAudience={data.videoAudienceCount}
            videoFinishedAudience={data.videoFinishedAudienceCount}
          />
        </div>

        <div className='chart-part'>
          <Graph
            series={series}
            duration={data.duration}
            averageWatched={data.averageWatchTime}
          />
        </div>
      </div>
    </Card>
  )
}

export default VideoAnalytics
