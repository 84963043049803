import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import Card from 'components/common/card'
import FormEditableField from 'components/form_fields/formEditableField'
import ContentVariablesDropdown from 'components/common/tiptap/contentVariablesDropdown'
import FormCheck from 'components/form_fields/formCheck'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import { ChatChannelTypeEnum } from 'types/chat/channel'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import FormTiptapEditorField from 'components/form_fields/formTiptapEditorField'
import { MEDIUM_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

const I18N = i18nPath('views.admin.surveys.recipients_section')

const notifyAllText = (slackEnabled, msTeamEnabled) => {
  let baseText = I18N('notify_via_email')

  if (slackEnabled) {
    baseText += I18N('and_notify_via_slack')
  }

  if (msTeamEnabled) {
    baseText += I18N('and_notify_via_ms_teams')
  }

  return baseText
}

const NotificationSettings = ({
  workingCopy, updateWorkingCopy,
}) => {
  const notificationChannels = workingCopy?.notificationChannels || []
  const { slackEnabled, msTeamEnabled } = useCurrentCompany()
  const chatEnabled = slackEnabled || msTeamEnabled
  const [
    shouldPublishToExternalChannels,
    setShouldPublishToExternalChannels,
  ] = useState(notificationChannels.length > 0)

  const updateShouldPublishToExternalChannels = (value: boolean) => {
    setShouldPublishToExternalChannels(value)

    if (!value) {
      updateWorkingCopy({ notificationChannels: [] })
    }
  }

  return (
    <>
      <h6 className='text-secondary mt-4'>{I18N('how_should_they_be_notified')}</h6>
      <Card cardBodyClassName='p-4'>
        <FormCheck
          name='notify_all'
          label={notifyAllText(slackEnabled, msTeamEnabled)}
          checked={workingCopy.notifyAll}
          onChange={({ target }) => updateWorkingCopy({ notifyAll: target.checked })}
          id='notify_all'
        />
        <FormCheck
          name='show_on_home_page'
          label={I18N('show_on_home_page')}
          checked={workingCopy.showOnHomePage}
          onChange={({ target }) => updateWorkingCopy({ showOnHomePage: target.checked })}
          id='show_on_home_page'
        />
        {chatEnabled && (
          <FormCheck
            name='notify_via_chat'
            label={I18N('publish_to_external_channels')}
            checked={shouldPublishToExternalChannels}
            onChange={({ target }) => updateShouldPublishToExternalChannels(target.checked)}
            id='notify_via_chat'
          />
        )}
        {chatEnabled && shouldPublishToExternalChannels && (
          <ChatChannelsAsyncSelect
            types={[ChatChannelTypeEnum.MsTeamChannel, ChatChannelTypeEnum.SlackChannel]}
            className='my-3'
            value={workingCopy?.notificationChannels || []}
            onChange={value => updateWorkingCopy({ notificationChannels: value })}
          />
        )}
        <FormCheck
          name='custom_notification'
          label={I18N('want_custom_notification')}
          checked={workingCopy?.customNotification}
          onChange={({ target }) => updateWorkingCopy({ customNotification: target.checked })}
          id='custom_notification'
        />
      </Card>
    </>
  )
}

const RecipientsAndInstructionsSection = ({
  workingCopy, updateWorkingCopy,
}) => (
  <>
    <h6 className='text-secondary'>{I18N('who_should_receive_this_survey')}</h6>
    <Card
      className='RecipientsAndInstructionsSection'
      cardBodyClassName='p-4'
    >
      <AudienceToggleSelector
        translationsPath='views.admin.surveys.recipients_section.audience_toggle_selector'
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        canTargetSpecificUsers
      />
    </Card>
    <NotificationSettings
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
    />
    {workingCopy?.customNotification && (
      <>
        <h6 className='text-secondary mt-4'>{I18N('custom_notification')}</h6>
        <Card>
          <>
            <div className='form-group'>
              <FormEditableField
                label={I18N('subject')}
                fieldName='subject'
                placeholder={I18N('subject_placeholder')}
                currentValue={workingCopy.subject}
                onChange={({ target }) => updateWorkingCopy({ subject: target.value })}
                isRequired
              />
            </div>
            <div>
              <FormTiptapEditorField
                label={I18N('instructions')}
                fieldName='instructions'
                currentValue={workingCopy.instructions}
                onChange={value => updateWorkingCopy({ instructions: value })}
                editorClassName='straight-borders'
                editorConfiguration={{
                  placeholder: I18N('instructions_placeholder'),
                  slashCommandEnabled: false,
                  userMentionsEnabled: false,
                }}
                toolbarItems={MEDIUM_TOOLBAR}
                isRequired
              />
              <ContentVariablesDropdown
                roles={['survey_recipient']}
                type='survey'
              />
            </div>
          </>
        </Card>
      </>
    )}
  </>
)

export default RecipientsAndInstructionsSection

