import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import EditableRichTextContent from 'components/common/editable_content/editableRichTextContent'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import CdnSvg from 'components/common/cdnSvg'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'

const I18N = i18nPath('views.groups.group_page')
const plusIconPath = '/images/plusIcon.svg'

const Description = ({ group, updateGroup }) => (
  <section className='GroupDescription mb-3 w-100 text-center text-md-left'>
    <EditableRichTextContent
      canEdit={group.permittedActions.editFields}
      showCharCount
      displayElement={
        isHtmlStringEmpty(group.description) ? (
          <span className='font-weight-500 d-flex'>
            <CdnSvg className='PlusIcon mr-1' src={plusIconPath} />
            {I18N('add_description')}
          </span>
        ) : (
          <TipTapRichTextView html={group.description} />
        )
      }
      inputElement={(
        <TiptapEditor
          className='RoundedTiptapEditor w-100'
          configuration={{
            ...SIMPLE_EDITOR_CONFIGURATION,
            placeholder: I18N('describe_what_this_group_is_about'),
          }}
          toolbarItems={SMALL_TOOLBAR}
        />
      )}
      valueToEdit={group.description ?? ''}
      saveContent={description => updateGroup({ description })}
    />
  </section>
)

export default Description
