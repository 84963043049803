import React, { useState } from 'react'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DeleteModal from 'components/common/modals/deleteModal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { PageFAQType } from 'types/page/faq'

type Props = {
  pageId: string
  faq: PageFAQType
  onDelete: () => void
  setIsEditing: (isEditing: boolean) => void
  canManageFAQs: boolean
}

const DELETE_MODAL = 'delete_modal'

const I18N = i18nPath('views.pages.faqs.dropdown')

const FAQDropdown: React.FC<Props> = ({
  pageId, faq, onDelete, setIsEditing, canManageFAQs,
}) => {
  const [currentOpenModal, setCurrentOpenModal] = useState<string | null>(null)
  const closeModal = () => setCurrentOpenModal(null)

  const [deleteFAQ, { isLoading: isDeleting }] = useApi(
    () => API.pagesFAQs.destroy(pageId, faq.id),
    {
      toastSuccessMessage: I18N('delete_modal.faq_deleted'),
      toastErrorMessage: I18N('delete_modal.error_deleting_faq'),
      onSuccess: () => {
        closeModal()
        onDelete()
      },
    }
  )

  if (!canManageFAQs) {
    return null
  }

  return (
    <>
      <DropdownMenuContainer
        menuType='kebab'
        className='FAQDropdown default-dropdown position-absolute top-0 right-0 p-2'
        closeMenuOnItemClick
      >
        <section>
          <CardDropdownMenuItem
            onClick={() => setIsEditing(true)}
            primaryText={I18NCommon('edit')}
            icon={<CdnSvg src='/images/pencilIcon.svg' className='mr-2' />}
          />
        </section>
        <section>
          <CardDropdownMenuItem
            className='Card-dropdownMenuItem--danger'
            onClick={() => setCurrentOpenModal(DELETE_MODAL)}
            primaryText={I18NCommon('delete')}
            icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
          />
        </section>
      </DropdownMenuContainer>

      {currentOpenModal === DELETE_MODAL && (
        <DeleteModal
          deleteText={I18N('delete_modal.delete_text')}
          deleteSecondaryText={I18N('delete_modal.delete_secondary_text')}
          deleteConfirm={deleteFAQ}
          showDeleteModal
          closeDeleteModal={closeModal}
          isDeleting={isDeleting}
        />
      )}
    </>
  )
}

export default FAQDropdown


