import React from 'react'
import { useSelector } from 'react-redux'

import SuggestedIcon from 'components/common/suggestedIcon'
import cleanUrl from 'utils/cleanUrl'
import searchSlice from 'redux/slices/search'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import isClearyUrl from 'utils/isClearyUrl'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

export const GoLinkResult = ({ result, inMobileApp }) => {
  const { goLink, goLinkAlias, highlights } = result

  const record = goLinkAlias || goLink

  const {
    name,
    description,
    url,
    displayName,
    iconUrl,
  } = { ...record, ...highlights }

  // display path must use the url to remove the <em> tags
  const shouldOpenInNewTab = !inMobileApp || !isClearyUrl(url)
  const displayPath = shouldOpenInNewTab ? url : url.replace(location.origin, '')

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={(
        <SuggestedIcon
          name={displayName}
          url={url}
          iconUrl={iconUrl}
          className=''
        />
      )}
      title={`go/${name}`}
      subtitle={cleanUrl(url)}
      description={description}
      result={result}
      descriptionExtraMargin
      openNewTab={shouldOpenInNewTab}
    />
  )
}

const GolinkResults = ({ showLabel = true }) => {
  const { golinkNotFound } = useQueryParams()
  const clearySearchResults = useSelector(searchSlice.selectors.getClearySearchResults('pageAll'))
  const golinkResults = clearySearchResults
    .filter(result => result.goLink)
  const label = !_.isEmpty(golinkResults) && golinkNotFound ? 'similar_links' : 'links'
  const { inMobileApp } = useCurrentUser()

  return (
    <ClearyInternalSearchResultsTemplate
      showLabel={showLabel}
      label={label}
      type='goLink'
      extraTypes={['goLinkAlias']}
      queryType='GoLink'
      searchResultToTemplateMap={result => (
        <GoLinkResult result={result} inMobileApp={inMobileApp} />
      )}
    />
  )
}

export default GolinkResults
