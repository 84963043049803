import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import moment from 'moment'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError, getValidationErrors } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import {
  defaultStepTemplateAttributes,
  DEFAULT_MILESTONE,
  DEFAULT_TO_CORRESPONDENT_ROLE,
  getMeetingCalendarEventTitle,
  getMeetingCalendarEventDescriptionText
} from 'utils/journeys/templatesDefaultHelpers'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'

const I18N = i18nPath('views.admin.journeys.meeting_templates')

export const MEETING_TEMPLATE_ATTRIBUTES = [
  'rangeStartTime',
  'rangeStartAfterNumDays',
  'rangeStartMilestone',
  'rangeEndTime',
  'rangeEndAfterNumDays',
  'rangeEndMilestone',
  'scheduleSpecificallyTime',
  'scheduleSpecificallyAfterNumDays',
  'scheduleSpecificallyMilestone',
  'meetingDuration',
  'calendarEventTitle',
  'calendarEventDescription',
  'hourWindowStart',
  'hourWindowEnd',
  'fallbackTimezone',
  'scheduleWhenAttendeesAvailable',
  'createVideoConference',
]

export const MEETING_TEMPLATE_INTERPOLATED_FIELDS = ['calendarEventTitle', 'calendarEventDescription']

const buildMeetingTemplatePayload = (meetingTemplate) => {
  const meetingTemplateAttrs = _.pick(meetingTemplate, [...MEETING_TEMPLATE_ATTRIBUTES, 'id'])

  if (meetingTemplate.stepTemplateAttributes) {
    meetingTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      meetingTemplate.stepTemplateAttributes
    )
  }

  return meetingTemplateAttrs
}

const TRIGGER_AFTER_NUM_DAYS = {
  onboarding: -1,
  offboarding: -14,
  custom: -1,
}

const SCHEDULE_SPECIFICALLY_AFTER_NUM_DAYS = {
  onboarding: 0,
  offboarding: 0,
  custom: 14,
}

const RANGE_START_AFTER_NUM_DAYS = {
  onboarding: 0,
  offboarding: -13,
  custom: 5,
}

const RANGE_END_AFTER_NUM_DAYS = {
  onboarding: 5,
  offboarding: 0,
  custom: 14,
}

export const defaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepTemplateAttributes(journeyBlueprintType),
  rangeStartTime: moment(new Date(2020, 1, 1, 12)).format(),
  rangeStartAfterNumDays: RANGE_START_AFTER_NUM_DAYS[journeyBlueprintType],
  rangeStartMilestone: DEFAULT_MILESTONE[journeyBlueprintType],
  rangeEndTime: moment(new Date(2020, 1, 1, 12)).format(),
  rangeEndAfterNumDays: RANGE_END_AFTER_NUM_DAYS[journeyBlueprintType],
  rangeEndMilestone: DEFAULT_MILESTONE[journeyBlueprintType],
  scheduleSpecificallyTime: moment(new Date(2020, 1, 1, 16)).format(), // 9 am
  scheduleSpecificallyAfterNumDays: SCHEDULE_SPECIFICALLY_AFTER_NUM_DAYS[journeyBlueprintType],
  scheduleSpecificallyMilestone: DEFAULT_MILESTONE[journeyBlueprintType],
  fromCorrespondentAttributes: {},
  toCorrespondentAttributes: { role: DEFAULT_TO_CORRESPONDENT_ROLE[journeyBlueprintType] },
  meetingDuration: 30,
  calendarEventTitle: getMeetingCalendarEventTitle(journeyBlueprintType),
  calendarEventDescription: getMeetingCalendarEventDescriptionText(journeyBlueprintType),
  hourWindowStart: moment.utc('01-01-2020 9:00', 'MM-DD-YYYY h:mm').format(),
  hourWindowEnd: moment.utc('01-01-2020 17:00', 'MM-DD-YYYY h:mm').format(),
  fallbackTimezone: 'America/Los_Angeles',
  scheduleWhenAttendeesAvailable: true,
  triggerAfterNumDays: TRIGGER_AFTER_NUM_DAYS[journeyBlueprintType],
  triggerTime: moment(new Date(2020, 1, 1, 12)).format(),
  createVideoConference: true,
})

export const initialState = {
  meta: {
    ...defaultMeta,
  },
}

const meetingTemplateSlice = createSlice({
  name: 'meetingTemplates',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

_.assign(meetingTemplateSlice, {
  asyncActions: {
    admin: {
      createMeetingTemplate: (meetingTemplate, onSuccess = () => null) => async (dispatch) => {
        dispatch(meetingTemplateSlice.actions.isSaving(true))

        try {
          const response = await API.admin.meetingTemplates.create(
            buildMeetingTemplatePayload(meetingTemplate)
          )
          dispatch(entitySlice.actions.add({ data: response.data }))
          const meetingTemplateId = response.data.data.id

          if (meetingTemplate?.stepTemplateAttributes?.state === 'draft') {
            dispatch(showToastMessage({ message: I18N('draft_saved'), type: 'success' }))
          } else {
            dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
          }

          onSuccess(meetingTemplateId)
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(meetingTemplateSlice.actions.setError(error))
          dispatch(showToastMessage({ message: getValidationErrors(error), type: 'error' }))
        } finally {
          dispatch(meetingTemplateSlice.actions.isSaving(false))
        }
      },

      fetchMeetingTemplate: id => async (dispatch) => {
        dispatch(meetingTemplateSlice.actions.isLoading(true))
        dispatch(meetingTemplateSlice.actions.isNotFound(false))

        try {
          const response = await API.admin.meetingTemplates.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(meetingTemplateSlice.actions.isNotFound(true))
        } finally {
          dispatch(meetingTemplateSlice.actions.isLoading(false))
        }
      },

      updateMeetingTemplate: (workingCopy, isPublishing = false) => async (dispatch) => {
        dispatch(meetingTemplateSlice.actions.isSaving(true))

        try {
          const response = await API.admin.meetingTemplates.update(
            buildMeetingTemplatePayload(workingCopy)
          )
          dispatch(entitySlice.actions.update({ data: response.data }))

          if (isPublishing) {
            dispatch(showToastMessage({ message: I18N('successfully_published'), type: 'success' }))
          }
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(meetingTemplateSlice.actions.setError(error))
          dispatch(showToastMessage({ message: getValidationErrors(error), type: 'error' }))
        } finally {
          dispatch(meetingTemplateSlice.actions.isSaving(false))
        }
      },
    },
  },
})

_.assign(meetingTemplateSlice, {
  selectors: {
    getMetaData: () => state => state.meetingTemplates.meta,

    getMeetingTemplate: id => state => build(state.entities, 'meetingTemplate', id),
  },
})

export default meetingTemplateSlice
