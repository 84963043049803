import { HocuspocusProvider, onAwarenessUpdateParameters } from '@hocuspocus/provider'
import { useEffect, useState } from 'react'

interface UseCollaborativeIsLeaderParams {
  provider?: HocuspocusProvider
}

const useCollaborativeIsLeader = ({ provider }: UseCollaborativeIsLeaderParams) => {
  const [isLeader, setIsLeader] = useState(false)

  useEffect(() => {
    if (!provider) { return () => {} }

    const awarenessUpdate = ({ states }: onAwarenessUpdateParameters) => {
      const firstClientId = states.map(a => a.clientId).sort((a, b) => a - b)[0]

      setIsLeader(firstClientId === provider.awareness.clientID)
    }

    provider.on('awarenessUpdate', awarenessUpdate)

    return () => {
      provider.off('awarenessUpdate', awarenessUpdate)
    }
  }, [provider])


  return isLeader
}

export default useCollaborativeIsLeader
