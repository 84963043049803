import React, { useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import ReactSelect from 'components/common/react_select'

import { i18nLabelWithTimeZone, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import DatePicker from 'components/form_fields/datePicker'
import currentTimezone from 'utils/currentTimezone'
import { URL_PATTERN_REGEX } from 'utils/constants'
import AudienceSelector from 'components/common/audience/audienceSelector'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import ColorSelect from 'components/common/colorSelect'
import ToggleSwitch from 'components/common/toggleSwitch'
import ArticleSelect from 'components/admin/carousel_cards/articleSelect'
import useSetItemFromQueryParams from 'components/admin/carousel_cards/hooks/useSetItemFromQueryParams'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import EditCoverImageButtons from 'components/admin/carousel_cards/editCoverImageButtons'

const I18N = i18nPath('views.admin.carousel_cards')

const BUTTON_STYLE_OPTIONS = [{ value: 'fill' }, { value: 'outline' }]

const UrlInputField = ({
  fieldName, onChange, fieldNameValue = '', disabled = false,
}) => {
  const fieldLabel = I18N(`form.${_.snakeCase(fieldName)}`)

  return (
    <div className='row'>
      <div className='form-group col-md-12'>
        <label>{fieldLabel}</label>
        <input
          value={fieldNameValue}
          onChange={onChange}
          disabled={disabled}
          pattern={URL_PATTERN_REGEX}
        />
      </div>
    </div>
  )
}

const TextInputField = ({
  fieldName, isRequired, onChange, textarea = false, footNote = null, fieldNameValue = '',
}) => {
  const fieldLabel = I18N(`form.${_.snakeCase(fieldName)}`)

  return (
    <div className='row'>
      <div className='form-group col-md-12'>
        <label className={classNames(isRequired && 'required-form-label')}>{fieldLabel}</label>
        {textarea ? (
          <textarea value={fieldNameValue} onChange={onChange} />
        ) : (
          <input value={fieldNameValue} onChange={onChange} />
        )}
        {footNote && <small className='form-text text-secondary'>{footNote}</small>}
      </div>
    </div>
  )
}

const ColorField = ({ fieldName, color, onChange }) => (
  <div className='row'>
    <div className='form-group col-md-12'>
      <label>{I18N(`form.${_.snakeCase(fieldName)}`)}</label>
      <ColorSelect
        color={color}
        onChange={val => onChange(fieldName, val)}
        onRemove={() => onChange(fieldName, '#000000')}
      />
    </div>
  </div>
)

const CarouselCardForm = ({
  card, setCard,
}) => {
  const { permissions: { articleManager } } = useCurrentUser()

  const [showArticleSelect, setShowArticleSelect] = useState(!_.isEmpty(card.item))
  const toggleArticleSelect = () => {
    if (showArticleSelect) {
      setCard({ ...card, item: { id: null, type: null } })
    }

    setShowArticleSelect(!showArticleSelect)
  }
  const updateItem = (item) => {
    const newCard = {
      ...card,
      item: { ...item, type: 'Article' },
      name: item.title,
      title: item.title,
      backgroundUrl: window.location.origin + item.displayPath,
      targetingRules: item.targetingRules,
    }

    if (item.publishedAt && moment(item.publishedAt).isAfter(moment())) {
      newCard.startTime = item.publishedAt
      newCard.endTime = moment(item.publishedAt).add(7, 'days').format()
    }

    setCard(newCard)
    setShowArticleSelect(true)
  }
  useSetItemFromQueryParams(updateItem)

  const [showButtonFields, setShowButtonFields] = useState(!_.isEmpty(card.buttonText))
  const toggleShowButtonFields = () => {
    if (showButtonFields) {
      setCard({
        ...card, buttonText: '', buttonUrl: '', buttonColor: '',
      })
    }

    setShowButtonFields(!showButtonFields)
  }
  const targetingOptions = useTargetingOptions()

  const handleChange = (fieldName, value) => {
    setCard({
      ...card,
      [fieldName]: value,
    })
  }

  const generateChangeHandler = fieldName => (e) => {
    handleChange(fieldName, e.target.value)
  }

  const buttonStyleSelectRenderer = buttonStyle => I18N(`form.button_style_option_${buttonStyle.value}`)

  const setStartTimeToNow = (e) => {
    e.preventDefault()
    setCard({ ...card, startTime: moment().format() })
  }

  const handleAudienceChange = (values = {}) => {
    setCard({ ...card, ...values })
  }

  return (
    <div className='CarouselCardForm w-100 mt-3'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='form-group col-12'>
              <EditCoverImageButtons workingCopy={card} setWorkingCopy={setCard} />
            </div>
          </div>

          {articleManager && (
            <div className='mb-4'>
              <div className='row'>
                <div className='form-group col-lg-7 col-md-12'>
                  <ToggleSwitch
                    checked={showArticleSelect}
                    text={I18N('form.create_for_article')}
                    onChange={toggleArticleSelect}
                    textPosition='after'
                  />
                </div>
              </div>
              {showArticleSelect && (
                <ArticleSelect
                  article={card.item}
                  onChange={updateItem}
                />
              )}
            </div>
          )}

          <TextInputField fieldName='name' fieldNameValue={card.name} isRequired={true} onChange={generateChangeHandler('name')} />
          <TextInputField fieldName='title' fieldNameValue={card.title} onChange={generateChangeHandler('title')} />
          <TextInputField fieldName='subtitle' fieldNameValue={card.subtitle} onChange={generateChangeHandler('subtitle')} />

          <UrlInputField
            fieldName='backgroundUrl'
            fieldNameValue={card.backgroundUrl}
            onChange={generateChangeHandler('backgroundUrl')}
            disabled={!_.isEmpty(card.item)}
          />
          <ColorField fieldName='backgroundColor' color={card.backgroundColor} onChange={handleChange} />

          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <ToggleSwitch
                checked={showButtonFields}
                text={I18N('form.add_button')}
                onChange={toggleShowButtonFields}
                textPosition='after'
              />
            </div>
          </div>

          {showButtonFields && (
            <>
              <div className='row'>
                <div className='form-group col-lg-7 col-md-12'>
                  <label className='form-label'>{I18N('form.button_style')}</label>
                  <ReactSelect
                    name='button_style'
                    value={BUTTON_STYLE_OPTIONS.find(option => option.value === card.buttonStyle)}
                    options={BUTTON_STYLE_OPTIONS}
                    getOptionLabel={buttonStyleSelectRenderer}
                    getOptionValue={buttonStyleSelectRenderer}
                    onChange={selectedValue => handleChange('buttonStyle', selectedValue.value)}
                  />
                </div>
              </div>
              <TextInputField fieldName='buttonText' fieldNameValue={card.buttonText} onChange={generateChangeHandler('buttonText')} />
              <UrlInputField fieldName='buttonUrl' fieldNameValue={card.buttonUrl} onChange={generateChangeHandler('buttonUrl')} />
              <ColorField fieldName='buttonColor' color={card.buttonColor} onChange={handleChange} />
            </>
          )}

          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('form.start_time'), currentTimezone())}</label>
              <DatePicker
                id='cy_carousel_card_form_start_time'
                selected={card.startTime && i18nMoment(card.startTime)}
                onChange={timeValue => handleChange('startTime', timeValue && timeValue.format())}
                showTimeSelect
                isClearable
                dateFormat='LLL'
                minDate={card.item?.publishedAt && moment(card.item?.publishedAt)}
              >
                <div className='react-datepicker-children text-center p-2'>
                  <a href='#' onMouseDown={setStartTimeToNow}>
                    {I18n.t('views.common.start_immediately')}
                  </a>
                </div>
              </DatePicker>
            </div>
          </div>
          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('form.end_time'), currentTimezone())}</label>
              <DatePicker
                selected={card.endTime && i18nMoment(card.endTime)}
                onChange={timeValue => handleChange('endTime', timeValue && timeValue.format())}
                showTimeSelect
                isClearable
                dateFormat='LLL'
                minDate={card.startTime && moment(card.startTime)}
              />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <AudienceSelector
            workingCopy={card}
            updateWorkingCopy={handleAudienceChange}
            targetingOptions={targetingOptions}
          />
        </div>
      </div>
    </div>
  )
}

export default CarouselCardForm
