import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.platform_analytics.ai_answers')

const AiAnswersOrigin = ({ answer, showChannel = false }) => {
  const i18nKey = answer.origin
  const icon = answer.origin === 'slack' ? 'slackLogoIcon.svg' : 'searchIcon.svg'

  return (
    <div className='flex items-center gap-2'>
      <CdnSvg src={`/images/${icon}`} className='w-4 h-4' />
      <span className='text-small text-secondary'>{I18N(`origin.${i18nKey}`)}</span>
      {showChannel && answer.originMetadata?.channelName && (
        <span className='text-small text-muted ml-2 font-normal'>
          #{answer.originMetadata.channelName}
        </span>
      )}
    </div>
  )
}

export default AiAnswersOrigin
