import React from 'react'

import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import GlobeIcon from 'components/icons/globeIcon'
import ToggleGroup from 'components/common/toggleGroup'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import EstimatedAudienceAvatars from 'components/common/audience/estimatedAudienceAvatars'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const personsIconPath = '/images/personsIcon.svg'

const AudienceToggleSelector = ({
  className = '',
  showFootnote = false,
  showAllTargetedDescription = false,
  translationsPath,
  workingCopy,
  updateWorkingCopy,
  canTargetSpecificUsers = false,
  canTargetEntireCompany = true,
  hasEstimatedAudienceAvatars = true,
  showLabel = true,
  includeRestricted = false,
  isManagersSelectDisabled = false,
  usePreboardingAudience = false,
}) => {
  const I18N = i18nPath(translationsPath)

  const targetingOptions = useTargetingOptions()
  const { targetingRules } = workingCopy

  const handleTargetingRulesChange = (newTargetingRules) => {
    updateWorkingCopy({ targetingRules: newTargetingRules })
  }

  const handleEntireCompanyChange = (value) => {
    const newTargetingRules = {
      ...targetingRules,
      targetEntireCompany: value,
    }

    updateWorkingCopy({ targetingRules: newTargetingRules })
  }

  const showEntireCompanyToggle = canTargetEntireCompany && !isManagersSelectDisabled

  return (
    <div className={classNames('AudienceToggleSelector', className)}>
      {showLabel && (
        <LabeledFormFieldContainer
          label={I18N('label')}
          footNote={showFootnote ? I18N('footnote') : ''}
          isRequired
        />
      )}
      <ToggleGroup
        onChange={handleEntireCompanyChange}
        selectedValue={targetingRules?.targetEntireCompany}
        toggles={[
          showEntireCompanyToggle && {
            value: true,
            icon: <GlobeIcon />,
            title: I18N('all_targeted_title'),
            description: showAllTargetedDescription ? I18N('all_targeted_description') : '',
          },
          {
            value: false,
            icon: <CdnSvg src={personsIconPath} />,
            title: I18N('specific_audience_title'),
            description: I18N('specific_audience_description'),
          },
        ].filter(Boolean)}
      />
      {!targetingRules?.targetEntireCompany && (
        <AudienceRulesSelector
          targetingRules={targetingRules}
          className='mb-4 mt-4'
          targetingOptions={targetingOptions}
          onChange={handleTargetingRulesChange}
          canTargetSpecificUsers={canTargetSpecificUsers}
          isManagersSelectDisabled={isManagersSelectDisabled}
        />
      )}
      {hasEstimatedAudienceAvatars && (
        <div className='EstimatedAudienceAvatars p-3 d-flex justify-content-start align-items-center mt-3'>
          <EstimatedAudienceAvatars
            targetingRules={targetingRules}
            targetEntireCompany={targetingRules?.targetEntireCompany}
            translationsPath={translationsPath}
            includeRestricted={includeRestricted}
            usePreboardingAudience={usePreboardingAudience}
          />
        </div>
      )}
    </div>
  )
}

export default AudienceToggleSelector
