import { useCallback } from 'react'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { transformInterpolatedFieldsForBackend } from 'utils/journeys/journeyVariablesHelpers'

const useTransformInterpolatedFields = () => {
  const { atsIntegrationName, hrisIntegrationName } = useCurrentCompany()

  const interpolateParams = useCallback((
    interpolatedFields, params, questionVariables
  ) => transformInterpolatedFieldsForBackend(
    interpolatedFields,
    params,
    questionVariables,
    atsIntegrationName,
    hrisIntegrationName
  ), [atsIntegrationName, hrisIntegrationName])

  return interpolateParams
}

export default useTransformInterpolatedFields
