const transformVariablesForUser = (
  text,
  {
    questionVariables,
    atsIntegrationName,
    hrisIntegrationName,
  }) => {
  if (!text) return ''

  let content = text

  questionVariables.forEach(({ id, text }) => {
    const questionIdPattern = new RegExp(`{survey_question_${id}}`, 'g')
    content = content.replaceAll(questionIdPattern, `{${text}}`)
  })

  if (hrisIntegrationName) {
    content = content.replaceAll('_external_hris_id}', `_${_.snakeCase(hrisIntegrationName)}_id}`)
  }

  if (atsIntegrationName) {
    content = content.replaceAll('_external_ats_id}', `_${_.snakeCase(atsIntegrationName)}_id}`)
  }

  return content
}


const transformVariablesForBackend = (
  content,
  {
    questionVariables,
    atsIntegrationName,
    hrisIntegrationName,
  }
) => {
  questionVariables.forEach(({ id, text }) => {
    const questionNamePattern = new RegExp(`\\{${text}\\}`, 'g')
    content = content.replaceAll(questionNamePattern, `{survey_question_${id}}`)
  })

  if (hrisIntegrationName) {
    content = content.replaceAll(`_${_.snakeCase(hrisIntegrationName)}_id}`, '_external_hris_id}')
  }

  if (atsIntegrationName) {
    content = content.replaceAll(`_${_.snakeCase(atsIntegrationName)}_id}`, '_external_ats_id}')
  }

  return content
}

export const transformInterpolatedFieldsForBackend = (
  interpolatedFields,
  params,
  questionVariables,
  atsIntegrationName,
  hrisIntegrationName
) => {
  interpolatedFields.forEach((attr) => {
    if (params[attr]) {
      params[attr] = transformVariablesForBackend(
        params[attr],
        { questionVariables, atsIntegrationName, hrisIntegrationName }
      )
    }
  })

  return params
}

const transformCcCorrespondentsForUser = (correspondents) => {
  const grouped = correspondents?.reduce((acc, correspondent) => {
    let group = acc.find(item => item.role === correspondent.role)
    if (!group) {
      group = { role: correspondent.role, users: [], emails: [] }
      acc.push(group)
    }

    if (correspondent.role === 'specific_employee' && correspondent.user) {
      group.users.push(correspondent.user)
    } else if (correspondent.role === 'email_address' && correspondent.email) {
      group.emails.push(correspondent.email)
    }

    return acc
  }, [])

  return grouped
}

const transformCcCorrespondentsForBackend = (correspondents) => {
  const transformedCorrespondents = correspondents?.flatMap((correspondent) => {
    const { role, users, emails } = correspondent

    if (!role) return []

    if (role === 'specific_employee') {
      const userEntries = users.map(user => ({ role, userId: user?.id }))
      return userEntries
    }

    if (role === 'email_address') {
      const emailEntries = emails.map(email => ({ role, email }))
      return emailEntries
    }

    return [{ role }]
  })

  return transformedCorrespondents
}

const transformStepCcsForUser = (stepCcs) => {
  const grouped = stepCcs?.reduce((acc, stepCc) => {
    let group = acc.find(item => item.role === stepCc.role)
    if (!group) {
      group = { role: stepCc.role, users: [], emails: [] }
      acc.push(group)
    }

    if (stepCc.role === 'email_address' && stepCc.email) {
      group.emails.push(stepCc.email)
    } else {
      group.users.push(stepCc.user)
    }

    return acc
  }, [])

  return grouped
}

export {
  transformVariablesForUser,
  transformVariablesForBackend,
  transformCcCorrespondentsForUser,
  transformCcCorrespondentsForBackend,
  transformStepCcsForUser
}
