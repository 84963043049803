import React, { useState } from 'react'
import Card from 'components/common/card'
import RichTextView from 'components/common/richTextView'
import FormCheck from 'components/form_fields/formCheck'
import AdvancedSettings from 'components/admin/journeys/advancedSettings'
import StepVariablesDropdown from 'components/admin/journeys/stepVariablesDropdown'
import {
  i18nPath
} from 'utils/i18nHelpers'


import ReplyToSetting from 'components/admin/journeys/replyToSetting'
import FromAndToCorrespondentSelector from 'components/admin/journeys/common/fromAndToCorrespondentSelector'
import StepTemplateFormButtonsContainer from 'components/admin/journeys/common/stepTemplateFormButtonsContainer'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import CcCorrespondentSelector from 'components/admin/journeys/common/ccCorrespondentSelector'
import TriggerScheduleSection from 'components/admin/journeys/common/triggerScheduleSection'
import CommunicationContent from 'components/admin/journeys/communicationTemplates/communicationContent'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.admin.journeys.communication_templates')
const I18NStepTemplates = i18nPath('views.admin.journeys.step_templates')

export const nonRequiredFields = [
  'sendToEmail',
  'createFeedCard',
  'sendToPersonalEmail',
  'sendSms',
  'feedTitle',
  'feedContent',
  'smsContent',
  'subject',
  'content',
]

const CommunicationForm = ({
  onSave,
  workingCopy,
  updateWorkingCopy,
  currentState = 'draft',
  isStepTemplate = true,
  areChangesPresent = true,
  forUser = null,
  isDisabled = false,
  journeyBlueprint,
  isSaving = false,
}) => {
  const { useNewForm } = useQueryParams()
  const [showCCSelector, setShowCCSelector] = useState(!_.isEmpty(workingCopy?.ccCorrespondentsAttributes))

  const journeyBlueprintType = journeyBlueprint?.type
  const triggerAfterNumDays = workingCopy?.triggerAfterNumDays || 0
  const preboardingEnabled = useCurrentCompany().settings?.journeys.preboarding.enabled
  const isOnboardingJourney = journeyBlueprintType === 'onboarding'
  const triggerOnStepCompletion = workingCopy?.triggerMilestone === 'step_completion'
  const isBeforeStartDate = triggerAfterNumDays < 0
  // We won't show the personal email checkbox when it's a step before the start date
  const showSendToPersonalEmail = preboardingEnabled && !(isOnboardingJourney && isBeforeStartDate)

  return (
    <div className='CommunicationForm'>
      <div className='font-weight-600 text-secondary mb-2'>{I18N(useNewForm ? 'communication_correspondents' : 'communication_content')}</div>
      <Card className='mb-4'>
        <FromAndToCorrespondentSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isDisabled={isDisabled}
          journeyBlueprint={journeyBlueprint}
        />

        {showCCSelector ? (
          <CcCorrespondentSelector
            journeyBlueprintType={journeyBlueprintType}
            setShowCCSelector={setShowCCSelector}
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
          />
        ) : (
          <div className='hover-highlight text-small pointer text-secondary mb-3' onClick={() => setShowCCSelector(true)}>
            {I18NStepTemplates('add_cc')}
          </div>
        )}

        {!useNewForm && (
          <>
            <div className='form-group'>
              <label className='required-form-label'>{I18N('subject')}</label>
              <input
                name='subject'
                placeholder={I18N('subject')}
                value={workingCopy.subject}
                disabled={isDisabled}
                // Temporary update both email and feed until new form is done
                onChange={({ target }) => updateWorkingCopy({ subject: target.value, feedTitle: target.value })}
              />
            </div>

            <div>
              <label className='required-form-label'>{I18N('body')}</label>
              {isDisabled ? (
                <RichTextView className='content-rich-view' record={workingCopy} recordType='communication' richTextKey='content' />
              ) : (
                <>
                  <TiptapEditor
                    className='TiptapEditor straight-borders'
                    // Temporary update both email and feed until new form is done
                    onChange={value => updateWorkingCopy({ content: value, feedContent: value })}
                    html={workingCopy.content}
                    configuration={{
                      placeholder: I18N('body_placeholder'),
                      userMentionsEnabled: false,
                      videosEnabled: true,
                    }}
                    editorContentClassName='p-3'
                  />
                  <StepVariablesDropdown
                    journeyBlueprint={journeyBlueprint}
                  />
                </>
              )}

            </div>
          </>
        )}
      </Card>

      {useNewForm && (
        <CommunicationContent
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isDisabled={isDisabled}
          journeyBlueprint={journeyBlueprint}
          isStepTemplate={isStepTemplate}
        />
      )}

      <TriggerScheduleSection
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isDisabled={isDisabled}
        isStepTemplate={isStepTemplate}
        journeyBlueprint={journeyBlueprint}
        sectionLabel={I18N('communication_trigger')}
      />

      <AdvancedSettings
        className='mt-4'
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isStepTemplate={isStepTemplate}
        journeyBlueprintType={journeyBlueprint?.type}
      >
        <label className='text-secondary font-weight-600 mb-3'>{I18NStepTemplates('notification_channel')}</label>
        <FormCheck
          name='create_feed_card'
          label={I18N('advanced_settings.create_feed_card')}
          checked={workingCopy.createFeedCard}
          onChange={({ target }) => updateWorkingCopy({ createFeedCard: target.checked })}
          id='create_feed_card'
          className='mb-3'
          disabled={isDisabled}
        />
        <FormCheck
          name='send_to_email'
          label={I18N('advanced_settings.send_to_email')}
          checked={workingCopy.sendToEmail}
          onChange={({ target }) => updateWorkingCopy({ sendToEmail: target.checked })}
          id='send_to_email'
          disabled={isDisabled}
        />
        {showSendToPersonalEmail && (
          <FormCheck
            name='send_to_personal_email'
            label={I18N('advanced_settings.send_to_personal_email')}
            checked={workingCopy.sendToPersonalEmail}
            onChange={({ target }) => updateWorkingCopy({ sendToPersonalEmail: target.checked })}
            id='send_to_personal_email'
            disabled={isDisabled}
            className='mt-3'
          />
        )}
        {(workingCopy.sendToEmail || workingCopy.sendToPersonalEmail) && triggerAfterNumDays <= 0 && (
          <ReplyToSetting
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
            className='mt-3'
          />
        )}
        <label className='text-secondary font-weight-600 mt-4 mb-3'>{I18NStepTemplates('error_handling')}</label>
        {
          isStepTemplate && journeyBlueprintType && !triggerOnStepCompletion && (
            <FormCheck
              name='create_after_trigger_period'
              label={I18N(`advanced_settings.${journeyBlueprintType}.create_after_trigger_period`)}
              checked={workingCopy.triggerImmediatelyIfPastTrigger}
              onChange={({ target }) => updateWorkingCopy({ triggerImmediatelyIfPastTrigger: target.checked })}
              id='create_after_trigger_period'
              className='mb-3'
            />
          )
        }
        {/* the variable is positive and the label is negative, so the value is negated for this checkbox */}
        <FormCheck
          name='variables_missing'
          label={I18N('advanced_settings.variables_missing')}
          checked={!workingCopy.triggerIfVariablesMissing}
          onChange={({ target }) => updateWorkingCopy({ triggerIfVariablesMissing: !target.checked })}
          id='variables_missing'
          className='mb-3'
          disabled={isDisabled}
        />
      </AdvancedSettings>
      <StepTemplateFormButtonsContainer
        onSave={onSave}
        workingCopy={workingCopy}
        currentState={currentState}
        isStepTemplate={isStepTemplate}
        areChangesPresent={areChangesPresent}
        isDisabled={isDisabled}
        forUser={forUser}
        nonRequiredFields={nonRequiredFields}
        stepType='communication'
        isSaving={isSaving}
      />
    </div>
  )
}

export default CommunicationForm
