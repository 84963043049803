import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import FormErrorList from 'components/errors/formErrorList'
import ImageSelector from 'components/common/imageSelector'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AudienceSelector from 'components/common/audience/audienceSelector'
import { isManagersOnly } from 'utils/audience'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.article_type_editor')
const I18NCommon = i18nPath('views.common')

export const defaultFormState = {
  id: null,
  name: '',
  description: '',
  imageUrl: '',
  orderPosition: '',
  active: false,
  groupArticleType: false,
  targetingRules: TARGET_ENTIRE_COMPANY,
}

const ArticleTypeForm = ({
  articleType, setArticleType, error, targetingOptions, isLoading,
}) => {
  const { settings: companySettings } = useCurrentCompany()
  const areTeamPostsEnabled = companySettings?.groups?.groupPosts?.enabled

  const generateChangeHandler = (event) => {
    const { name, value } = event.target
    setArticleType({ ...articleType, [name]: value })
  }

  const handleImageInputChange = (image) => {
    const changedArticleType = { ...articleType, image }
    setArticleType(changedArticleType)
  }

  const handleBooleanCheck = (event) => {
    const { checked, name } = event.target
    const changedActive = { ...articleType, [name]: checked }
    setArticleType(changedActive)
  }

  const handleAudienceChange = (values = {}) => {
    setArticleType({ ...articleType, ...values })
  }

  return (
    <>
      <div className='col-md-7'>
        <div className='row'>
          <div className='col'>{error && <FormErrorList error={error} />}</div>
        </div>
        <div className='row'>
          <div className='form-group col'>
            <label className='required-form-label' htmlFor='article_type_name'>{I18N('name')}</label>
            <input id='article_type_name' name='name' value={articleType?.name} onChange={generateChangeHandler} />
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label className='required-form-label' htmlFor='article_type_description'>{I18N('description')}</label>
            <input id='article_type_description' name='description' value={articleType?.description} onChange={generateChangeHandler} />
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label>{I18N('image')}</label>
            <ImageSelector
              placeholderUrl={articleType?.imageUrl}
              onImageAdded={image => handleImageInputChange(image)}
            />
            <div
              className='text-small text-secondary mt-1'
              dangerouslySetInnerHTML={{ __html: I18N('image_guidance') }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label className='form-label'>{I18N('position')}</label>
            <input
              name='orderPosition'
              value={articleType?.orderPosition}
              onChange={generateChangeHandler}
              type='number'
            />
            <div className='text-small text-secondary'>{I18N('position_helper_text')}</div>
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <div className='form-check'>
              <input
                name='active'
                type='checkbox'
                id='article-type-active-checkbox'
                checked={articleType?.active}
                onChange={handleBooleanCheck}
              />
              <label htmlFor='article-type-active-checkbox' className='checkbox-label form-inline-check'>
                {I18NCommon('active')}
              </label>
            </div>
          </div>
        </div>

        {areTeamPostsEnabled && (
          <div className='row'>
            <div className='form-group col'>
              <div className='form-check'>
                <input
                  name='groupArticleType'
                  type='checkbox'
                  id='group-article-type-checkbox'
                  checked={articleType?.groupArticleType}
                  onChange={handleBooleanCheck}
                />
                <label htmlFor='group-article-type-checkbox' className='checkbox-label form-inline-check'>
                  {I18N('group_article_type')}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='col-md-5'>
        <AudienceSelector
          workingCopy={articleType}
          updateWorkingCopy={handleAudienceChange}
          targetingOptions={targetingOptions}
          isLoading={isLoading}
        />
        {isManagersOnly(articleType?.targetingRules) && (
          <div className='alert alert-info pb-1 mt-3'>
            <label>{I18N('manager_override', { articleTypeName: articleType?.name })}</label>
          </div>
        )}
      </div>
    </>
  )
}

export default ArticleTypeForm
