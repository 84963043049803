import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import AudienceSelector from 'components/common/audience/audienceSelector'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.journeys.step_templates.targeting_settings')

interface StepTemplateTargetingSettingsProps {
  className?: string,
  workingCopy: any,
  updateWorkingCopy: () => void,
  journeyBlueprintType: string
}

const StepTemplateTargetingSettings = ({
  className,
  workingCopy,
  updateWorkingCopy,
  journeyBlueprintType,
}: StepTemplateTargetingSettingsProps) => {
  const targetingOptions = useTargetingOptions()

  return (
    <div className={classNames('StepTemplateTargetingSettings', className)}>
      <label className='text-secondary font-weight-600 mb-3'>{I18N('label')}</label>
      <AudienceSelector
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        targetingOptions={targetingOptions}
        canTargetEntireCompany={true}
        isLoading={_.isEmpty(targetingOptions)}
        showEstimatedAudience={false}
        canTargetSpecificUsers={false}
        everyoneIsTargetedText={I18N(`${journeyBlueprintType}.everyone_is_targeted`)}
        targetEntireCompanyText={I18N(`${journeyBlueprintType}.target_entire_company`)}
      />
    </div>
  )
}

export default StepTemplateTargetingSettings
