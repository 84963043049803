import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import AuthenticationSection from 'components/admin/integrations/authenticationSection'
import CalendarsSection from 'components/admin/integrations/calendarsSection'
import MessagingSection from 'components/admin/integrations/messagingSection'
import PeopleSection from 'components/admin/integrations/peopleSection'
import SearchSection from 'components/admin/integrations/searchSection'
import itAdminSlice from 'redux/slices/itAdmin'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { showToastMessage } from 'redux/slices/toasts'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.integrations_page')

const IntegrationsPage = () => {
  const dispatch = useDispatch()
  const { messageType, message } = useQueryParams()
  const history = useHistory()

  const { isLoading } = useSelector(itAdminSlice.selectors.getMetaData())

  useEffect(() => {
    if (message) {
      dispatch(showToastMessage({ message, type: messageType || 'success' }))
      history.replace({ search: null })
    }

    dispatch(itAdminSlice.asyncActions.admin.fetchData())
  }, [])

  return (
    <>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('app_integrations')}</h3>
      </header>
      <main className='AdminContent IntegrationsPage'>
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <AuthenticationSection />
            <CalendarsSection />
            <MessagingSection />
            <PeopleSection />
            <SearchSection />
          </>
        )}
      </main>
    </>
  )
}

export default IntegrationsPage
