import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import moment from 'moment'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'

import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import { sortByDate } from 'utils/sortByDate'
import getIdsFromResponse from 'redux/slices/utils/getIdsFromResponse'
import audienceSlice from 'redux/slices/audiences'
import buildByIdOrSlugFromEntitiesStore from 'redux/slices/utils/buildByIdOrSlugFromEntitiesStore'

import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.qna.events')

export const buildEventPayload = (event) => {
  const payload = _.pick(event, [
    'title', 'eventTime', 'startedAt', 'lockedAt', 'archivedAt',
    'description', 'allowAnonymousQuestions', 'questionsAnswerableBy',
    'videoConferenceLink', 'draft', 'accessLevel',
    'templateName', 'coverImage', 'settings',
    'agendaItems', 'id', 'generateRecapArticle',
    'template_name', // createEventModal pass this as camelcase
  ])

  if (event?.targetingRules) {
    payload.targetingRules = normalizeTargetingRules(event.targetingRules)
  }
  if (event?.notificationChannels) {
    payload.notificationChannelIds = event.notificationChannels.map(({ id }) => id)
  }

  if (event?.moderators) {
    payload.moderatorIds = event.moderators.map(({ id }) => id)
  }

  return payload
}

const initialState = {
  highlightedEventId: null,
  topQuestionId: null,
  eventsIds: [],
  meta: {
    isLoading: false,
    isLoadingEvents: false,
    isLoadingSettings: false,
    isHighlightedEventLoading: false,
    eventsLoaded: false,
    queryParams: { ...defaultPaginationParams, perPage: 18, filter: 'all' },
    error: null,
    isNotFound: false,
    isUnauthorized: false,
    isSaving: false,
    isDeleting: false,
    isArchiving: false,
    isUpdatingField: {
      description: false,
      title: false,
      notificationChannels: false,
      videoConferenceLink: false,
    },
  },
}

const qnaEventSlice = createSlice({
  name: 'qnaEvents',
  initialState,
  reducers: {
    clearEventsListPageData(state, action) {
      state.meta.eventsLoaded = false
      state.meta.queryParams = { ...defaultPaginationParams, perPage: 18, filter: 'all' }
      state.eventsIds = []
      state.highlightedEvent = null
    },

    setError(state, action) {
      state.meta.error = action.payload
    },

    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    isLoadingSettings(state, action) {
      state.meta.isLoadingSettings = action.payload
    },

    isLoadingEvents(state, action) {
      state.meta.isLoadingEvents = action.payload
    },

    isSaving(state, action) {
      state.meta.isSaving = action.payload
    },

    isUpdatingField(state, action) {
      state.meta.isUpdatingField[action.payload.attribute] = action.payload.updating
    },

    isHighlightedEventLoading(state, action) {
      state.meta.isHighlightedEventLoading = action.payload
    },

    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },

    isArchiving(state, action) {
      state.meta.isArchiving = action.payload
    },

    isUnauthorized(state, action) {
      state.meta.isUnauthorized = action.payload
    },

    isNotFound(state, action) {
      state.meta.isNotFound = action.payload
    },

    eventsLoaded(state, action) {
      state.meta.eventsLoaded = action.payload
    },

    setHighlightedEventId(state, action) {
      state.highlightedEventId = action.payload
    },

    setTopQuestionId(state, action) {
      state.topQuestionId = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    setEventsIds(state, action) {
      state.eventsIds = action.payload
    },

    resetTopQuestionId(state, action) {
      state.topQuestionId = null
    },

    resetQueryParams(state) {
      state.meta.queryParams = { ...defaultPaginationParams, perPage: 18, filter: 'all' }
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetchAll: queryParams => async (dispatch, getState) => {
    dispatch(qnaEventSlice.actions.isLoadingEvents(true))

    try {
      const response = await API.qna.events.fetchAll(queryParams)

      // Need previous events to accumulate results as user asks for more events
      const previousEventsIds = getState().qnaEvents.eventsIds
      const previousFilter = getState().qnaEvents.meta.queryParams?.filter
      const queryParamsFromResponse = queryParamsFromHeaders(response)
      const eventsIds = getIdsFromResponse(response, 'qnaEvent')

      if (previousFilter === queryParams.filter && queryParams.page !== 1) {
        dispatch(qnaEventSlice.actions.setEventsIds([...previousEventsIds, ...eventsIds]))
      } else {
        dispatch(qnaEventSlice.actions.setEventsIds(eventsIds))
      }

      dispatch(qnaEventSlice.actions.setQueryParams({ ...queryParamsFromResponse, filter: queryParams.filter }))
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      dispatch(qnaEventSlice.actions.setError('Failed to fetch events'))
    } finally {
      dispatch(qnaEventSlice.actions.isLoadingEvents(false))
      dispatch(qnaEventSlice.actions.eventsLoaded(true))
    }
  },

  fetchHighlightedEvent: () => async (dispatch) => {
    dispatch(qnaEventSlice.actions.isHighlightedEventLoading(true))

    try {
      const response = await API.qna.events.fetchHighlightedEvent()

      dispatch(entitySlice.actions.add({ data: response.data }))

      const highlightedEventId = response.data.data?.id
      const topQuestionId = response.data.data?.relationships?.highlightedQuestions?.data[0]?.id

      dispatch(qnaEventSlice.actions.setTopQuestionId(topQuestionId))
      dispatch(qnaEventSlice.actions.setHighlightedEventId(highlightedEventId))
    } catch (e) {
      dispatch(qnaEventSlice.actions.setError('Failed to fetch events'))
    } finally {
      dispatch(qnaEventSlice.actions.isHighlightedEventLoading(false))
    }
  },

  fetch: eventId => async (dispatch) => {
    dispatch(qnaEventSlice.actions.isLoading(true))
    dispatch(qnaEventSlice.actions.isNotFound(false))

    try {
      const response = await API.qna.events.fetch(eventId)

      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      if (error.type === 'unauthorized') {
        dispatch(qnaEventSlice.actions.isUnauthorized(true))
      } else if (error.type === 'not_found') {
        dispatch(qnaEventSlice.actions.isNotFound(true))
      }

      dispatch(qnaEventSlice.actions.setError('Failed to fetch event'))
    } finally {
      dispatch(qnaEventSlice.actions.isLoading(false))
    }
  },

  requestInvite: eventId => async (dispatch) => {
    dispatch(qnaEventSlice.actions.isSaving(true))

    try {
      const response = await API.qna.events.requestInvite(eventId)

      dispatch(showToastMessage({ message: I18N('request_invitation_successful'), type: 'success' }))
    } catch (e) {
      dispatch(qnaEventSlice.actions.setError('Failed to request invite'))
    } finally {
      dispatch(qnaEventSlice.actions.isSaving(false))
    }
  },

  admin: {
    speakers: {
      create: (eventId, userId) => async (dispatch) => {
        try {
          const response = await API.admin.qna.speakers.create(eventId, userId)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          dispatch(qnaEventSlice.actions.setError('Failed to add speaker'))
        }
      },
      destroy: (eventId, userId) => async (dispatch) => {
        try {
          const response = await API.admin.qna.speakers.destroy(eventId, userId)
          dispatch(entitySlice.actions.update({ data: response.data }))
        } catch (e) {
          dispatch(qnaEventSlice.actions.setError('Failed to remove speaker'))
        }
      },
    },
    // we need to handle the response back for agenda items a bit differently
    // since its an array, we cant simply just update the state as deleted items
    // will still be in the state due to how _.merge work
    // for example: _.merge(['a', 'b'], ['c']) => ['c', 'b']
    // this does not actually replace the array but only replaces at the index
    // therefore, we are using the update method
    updateAgendaItems: event => async (dispatch) => {
      try {
        const response = await API.admin.qna.events.update(buildEventPayload(event))

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        dispatch(qnaEventSlice.actions.setError('Failed to update agenda item'))
      }
    },
    fetch: eventId => async (dispatch, getState) => {
      dispatch(qnaEventSlice.actions.isLoadingSettings(true))

      try {
        const response = await API.admin.qna.events.fetch(eventId)

        // Use actions.update so it can also update relationship data already fetched before
        dispatch(entitySlice.actions.update({ data: response.data }))

        const event = buildByIdOrSlugFromEntitiesStore(eventId, 'qnaEvent', getState())
        const { targetingRules } = event
        dispatch(audienceSlice.asyncActions.fetchEstimatedAudience({ targetingRules }))
      } catch (e) {
        dispatch(qnaEventSlice.actions.setError('Failed to fetch event'))
      } finally {
        dispatch(qnaEventSlice.actions.isLoadingSettings(false))
      }
    },

    create: (event, history) => async (dispatch) => {
      dispatch(qnaEventSlice.actions.isSaving(true))

      try {
        const response = await API.admin.qna.events.create(buildEventPayload(event))
        const eventSlug = response.data.data.attributes.slug

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('event_created'), type: 'success' }))

        // Redirect user to the Event page
        history.push(`/events/${eventSlug}`)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(qnaEventSlice.actions.setError('Failed to create event'))
      } finally {
        dispatch(qnaEventSlice.actions.isSaving(false))
      }
    },
    update: (event, attribute = null, onSuccess = () => null) => async (dispatch) => {
      try {
        dispatch(qnaEventSlice.actions.isSaving(true))

        if (attribute) {
          dispatch(qnaEventSlice.actions.isUpdatingField({ attribute, updating: true }))
        }

        const response = await API.admin.qna.events.update(buildEventPayload(event))

        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('event_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(qnaEventSlice.actions.setError('Failed to update event'))
      } finally {
        dispatch(qnaEventSlice.actions.isSaving(false))
        if (attribute) {
          dispatch(qnaEventSlice.actions.isUpdatingField({ attribute, updating: false }))
        }
      }
    },
    publish: (eventId, onSuccess) => async (dispatch) => {
      try {
        const response = await API.admin.qna.events.publish(eventId)

        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess()
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(showToastMessage({ message: error.message, type: 'error' }))
        dispatch(qnaEventSlice.actions.setError(I18N('event_failed_to_publish')))
      }
    },
    destroy: (event, history) => async (dispatch) => {
      dispatch(qnaEventSlice.actions.isDeleting(true))

      try {
        await API.admin.qna.events.destroy(event)

        dispatch(entitySlice.actions.remove({ id: event.id, type: 'qnaEvent' }))
        history.push('/events')
      } catch (e) {
        dispatch(qnaEventSlice.actions.setError('event_failed_to_destroy'))
        dispatch(qnaEventSlice.actions.setError(I18N('event_failed_to_destroy')))
      } finally {
        dispatch(qnaEventSlice.actions.isDeleting(false))
      }
    },
    archive: (event, history) => async (dispatch) => {
      try {
        dispatch(qnaEventSlice.actions.isArchiving(true))

        let eventData = { ...event, archivedAt: moment().clone().format() }

        if (moment(eventData.archivedAt).isBefore(moment(event.lockedAt))) {
          const now = moment().format()
          eventData = { ...event, lockedAt: now, archivedAt: now }
        }

        const response = await API.admin.qna.events.update(buildEventPayload(eventData))

        dispatch(entitySlice.actions.update({ data: response.data }))

        history.push('/events')
        dispatch(showToastMessage({ message: I18N('event_archived'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(qnaEventSlice.actions.setError(I18N('event_failed_to_archive')))
      } finally {
        dispatch(qnaEventSlice.actions.isArchiving(false))
      }
    },
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getEventListData: () => (state) => {
    const {
      highlightedEventId, topQuestionId, eventsIds, meta,
    } = state.qnaEvents

    const topQuestion = topQuestionId ? build(state.entities, 'qnaQuestion', topQuestionId) : null
    const highlightedEvent = highlightedEventId ? build(state.entities, 'qnaEvent', highlightedEventId) : null

    let events = eventsIds.map(id => build(state.entities, 'qnaEvent', id)).filter(i => i) // Array can have n empty elements
    events = events.filter(e => e.id !== highlightedEvent?.id)
    // Sort oldest to recent
    events = sortByDate(events, 'eventTime')

    return {
      highlightedEvent,
      events,
      meta,
      topQuestion,
    }
  },

  getEventPageData: eventId => (state) => {
    const { isQuestionFormVisible, meta, pendingQuestionContent } = state.qnaEvents

    const event = buildByIdOrSlugFromEntitiesStore(eventId, 'qnaEvent', state)

    return {
      event,
      isQuestionFormVisible,
      meta,
      pendingQuestionContent,
    }
  },

  getEventMetaData: () => state => state.qnaEvents.meta,

  getEditEventPageData: eventId => (state) => {
    const { meta } = state.qnaEvents

    const event = buildByIdOrSlugFromEntitiesStore(eventId, 'qnaEvent', state) || {}

    return { event, meta }
  },
}

export { initialState }
export default {
  ...qnaEventSlice,
  asyncActions,
  selectors,
}
