import React from 'react'

const PhoneIcon = () => (

  <svg width='21px'
    height='20px'
    viewBox='0 0 21 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <defs />
    <g id='PhoneIcon-Main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='PhoneIcon-Profile-Detail' transform='translate(-658.000000, -403.000000)' fill='#C2C7CC'>
        <path
          d='M661.933378,403.000016 C659.259944,403.000016 658.122902,406.164912 658.011216,408.292205 C657.876066,410.853422 658.962896,413.543932 661.241204,416.289322 C664.120647,419.757452 667.156357,421.917301 670.265274,422.709339 C671.020799,422.902349 671.787118,423.000016 672.543581,423.000016 C673.831259,423.000016 675.032122,422.722826 676.111444,422.176818 C676.816758,421.821029 677.799879,421.224326 678.191251,420.151843 C678.986664,417.97432 676.928913,416.315831 676.013367,415.590766 C675.420209,415.020108 674.756661,414.730826 674.038677,414.730826 C672.843914,414.730826 671.801665,415.521933 670.881895,416.220024 C670.6665,416.383734 670.456736,416.542792 670.253073,416.683713 C670.095398,416.793938 669.950862,416.845097 669.799288,416.845097 C669.285906,416.845097 668.635498,416.252115 668.11273,415.775869 C668.010899,415.683317 667.912352,415.593556 667.815213,415.507981 C667.288691,415.043362 666.762639,414.542002 666.251604,414.017852 C666.129594,413.89321 665.989282,413.75694 665.838646,413.610904 C665.206069,412.996528 664.249696,412.068221 664.186345,411.396175 C664.162412,411.145029 664.703012,410.559954 664.962987,410.279043 C665.109869,410.11952 665.245019,409.972553 665.34732,409.84326 C665.93907,409.09866 666.525658,408.184771 666.392854,407.053687 C666.178398,405.235209 663.719421,403.000016 661.933378,403.000016 L661.933378,403.000016 Z'
          id='PhoneIcon-Fill-1'
        />
      </g>
    </g>
  </svg>
)

export default PhoneIcon
