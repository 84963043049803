import React from 'react'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import GlobeIcon from 'components/icons/globeIcon'
import LockIcon from 'components/icons/lockIcon'
import ToggleGroup from 'components/common/toggleGroup'

const I18N = i18nPath('views.common.access_level_toggle')

const PUBLIC_ACCESS = 'public_access'
const PRIVATE_ACCESS = 'private_access'

const AccessLevelToggleGroup = ({
  onChange,
  selectedValue = PUBLIC_ACCESS,
  className = '',
  guidanceText = I18N('who_can_access'),
  publicTitle = I18N('public_title'),
  publicDescription = I18N('public_description'),
  privateTitle = I18N('private_title'),
  privateDescription = I18N('private_description'),
}) => (
  <ToggleGroup
    onChange={onChange}
    selectedValue={selectedValue}
    label={guidanceText}
    className={classNames('AccessLevelToggleGroup', className)}
    toggles={[
      {
        value: PUBLIC_ACCESS,
        icon: <GlobeIcon />,
        title: publicTitle,
        description: publicDescription,
      },
      {
        value: PRIVATE_ACCESS,
        icon: <LockIcon />,
        title: privateTitle,
        description: privateDescription,
      },
    ]}
  />
)

export default AccessLevelToggleGroup
