import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import PageFeedback from 'components/analytics/pages/pageFeedback'
import PageUsage from 'components/analytics/pages/pageUsage'
import ReadTime from 'components/analytics/pages/readTime'
import BackButton from 'components/common/backButton'
import { Button } from 'components/common/buttons'
import ExternalLinkIcon from 'components/icons/externalLinkIcon'
import pageSlice from 'redux/slices/pages'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import VideoAnalytics from 'pages/admin/articles/stats/videoAnalytics'

const I18N = i18nPath('views.platform_analytics')

const PageAnalyticsPage = () => {
  const dispatch = useDispatch()
  const { pageId } = useParams()

  const {
    targetingRules,
    completedTargetingRules,
    setTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const { page } = useSelector(pageSlice.selectors.getPageData(pageId))
  const { isLoading: isLoadingPage } = useSelector(pageSlice.selectors.getPagesMetadata())

  useEffect(() => {
    // We need to scroll to the top because when we navigate from the pages platform analytics tab
    // it is sometimes scrolled down and this ensures this page loads at the top.
    window.scrollTo(0, 0)
    dispatch(pageSlice.asyncActions.fetch(pageId, { analytics: true }))
  }, [])

  return (
    <main className='PageAnalyticsPage mb-5'>
      <header className='d-flex flex-column mt-3'>
        <BackButton />
        <div className='d-flex flex-row mt-3'>
          <h3>{I18N('pages.page_analytics_for', { title: page.title || '' })}</h3>
          <Link to={page.displayPath || ''} target='_blank'>
            <Button variant='link'>
              <ExternalLinkIcon fillColor='var(--link-color)' />
            </Button>
          </Link>
        </div>
        <p className='text-secondary'>
          {I18N('pages.last_updated', { last_updated: i18nMoment(page.lastPublishedAt).format('MMMM D, YYYY') })}
        </p>
      </header>

      <TabHeader
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        setGroupBy={setGroupBy}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <PageUsage
        pageId={pageId}
        groupBy={groupBy}
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      {page.videoIds?.map(videoId => (
        <div key={videoId} className='mt-4 VideoAnalyticsWrapper'>
          <VideoAnalytics
            parentId={page.id}
            parentType='page'
            videoId={videoId}
          />
        </div>
      ))}

      <ReadTime
        pageId={pageId}
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <PageFeedback page={page} isLoading={isLoadingPage} />
    </main>

  )
}

export default PageAnalyticsPage
