import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  i18nPath, i18nLabelWithTimeZone, i18nMoment, I18NCommon
} from 'utils/i18nHelpers'
import currentTimezone from 'utils/currentTimezone'
import ImageSelector from 'components/common/imageSelector'
import { LoadingContainer } from 'components/common/loadingContainer'
import DatePicker from 'components/form_fields/datePicker'
import FormErrorList from 'components/errors/formErrorList'
import { Button } from 'components/common/buttons'
import { useHistory, useParams } from 'react-router-dom'
import API from 'services/api'
import { buildLogoPayload } from 'services/api/admin/logos'
import useApi from 'components/common/hooks/useApi'

const I18N = i18nPath('views.admin.logo_editor')
const MAX_LOGO_TEXT_LENGTH = 500

const defaultWorkingCopy = {
  text: '',
  imageUrl: '',
  link: '',
  height: '',
  width: '',
}

const LogoEditorPage = () => {
  const history = useHistory()
  const [fetch, { data: logo, isLoading }] = useApi(API.admin.logos.fetch)

  const [create, { error: createError }] = useApi(API.admin.logos.create, {
    onSuccess: () => history.push('/admin/logos'),
    toastSuccessMessage: I18NCommon('created_successfully'),
  })

  const [update, { error: updateError }] = useApi(API.admin.logos.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
  })

  const [destroy] = useApi(API.admin.logos.destroy, {
    onSuccess: () => history.push('/admin/logos'),
    toastSuccessMessage: I18NCommon('deleted_successfully'),
  })

  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)
  const { logoHeight, logoWidth } = useSelector(state => state.styles.variables.dimensions)
  const { logoId } = useParams()
  const isNewLogo = logoId === 'new'
  const logoUpdatedAt = logo?.updatedAt || null
  const error = updateError || createError

  useEffect(() => {
    if (!isNewLogo) {
      fetch(logoId)
    }
  }, [isNewLogo])

  useEffect(() => {
    if (logo) {
      setWorkingCopy(_.cloneDeep(logo))
    }
  }, [logoUpdatedAt])

  const handleImageInputChange = (image) => {
    const changedLogo = { ...workingCopy, image }
    setWorkingCopy(changedLogo)
  }

  const generateChangeHandler = fieldName => (e) => {
    const value = e.target.value
    const changedLogo = { ...workingCopy, [fieldName]: value }
    setWorkingCopy(changedLogo)
  }

  const generateDimensionsChangeHandler = fieldName => (e) => {
    const value = e.target.value

    const changedLogo = { ...workingCopy, [fieldName]: value }
    setWorkingCopy(changedLogo)
  }

  const generateTimeChangeHandler = fieldName => (e) => {
    const changedLogo = { ...workingCopy }
    changedLogo[fieldName] = e && e.format()

    setWorkingCopy(changedLogo)
  }

  const saveLogo = () => {
    // If the color only contains 3 characters we need to return early.
    // The reason for this is that the navbar requires a 6 char length hex color to build colors for custom logos
    if (!workingCopy.color.match(/^#([A-Fa-f0-9]{6})$/)) return

    if (workingCopy.id) {
      update(buildLogoPayload(workingCopy))
    } else {
      create(buildLogoPayload(workingCopy))
    }
  }

  const deleteLogo = () => {
    const confirmDelete = confirm(I18N('confirm_delete'))

    if (confirmDelete) {
      destroy(workingCopy)
    }
  }

  const textLength = workingCopy.text.length
  const shouldDisableCreateButton = !workingCopy.title || !workingCopy.startTime || !workingCopy.endTime
  const changesPresent = !_.isEqual(logo, workingCopy)
  const { width, height } = workingCopy

  return (
    <div className='LogoEditorPage'>
      <header className='AdminHeader'>
        <h3>{I18N('logo_editor')}</h3>
      </header>

      <main className=' AdminContent container my-4'>
        {error && <FormErrorList error={error} />}
        <LoadingContainer isLoading={isLoading} useCirclesLoadingIndicator>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='row'>
                <div className='form-group col-md-8'>
                  <label className='required-form-label'>{I18N('title')}</label>
                  <input value={workingCopy.title} onChange={generateChangeHandler('title')} />
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-md-8'>
                  <label className='required-form-label'>{I18N('nav_color')}</label>
                  <input
                    minLength={7}
                    maxLength={7}
                    pattern='^#([A-Fa-f0-9]{6})$'
                    value={workingCopy.color}
                    placeholder='#FFFFFF'
                    onChange={generateChangeHandler('color')}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-md-8'>
                  <label className='required-form-label'>{I18N('subnav_color')}</label>
                  <input
                    minLength={7}
                    maxLength={7}
                    pattern='^#([A-Fa-f0-9]{6})$'
                    value={workingCopy.subnavColor}
                    placeholder='#FFFFFF'
                    onChange={generateChangeHandler('subnavColor')}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-md-10'>
                  <label className='required-form-label'>{I18N('text')}</label>
                  <textarea
                    maxLength={MAX_LOGO_TEXT_LENGTH}
                    value={workingCopy.text}
                    onChange={generateChangeHandler('text')}
                  />
                  <div className='ArticlesAdminEditor-characterCount text-muted'>
                    {I18N('characters_remaining', { numCharsRemaining: MAX_LOGO_TEXT_LENGTH - textLength })}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-md-10'>
                  <label>{I18N('link')}</label>
                  <input
                    placeholder='ex: https://www.nytimes.com'
                    value={workingCopy.link}
                    onChange={generateChangeHandler('link')}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-md-10'>
                  <label className='required-form-label'>{I18N('image')}</label>
                  <ImageSelector
                    className='CustomLogo'
                    width={width && width.length ? width + 'em' : null}
                    height={height && height.length ? height + 'em' : null}
                    placeholderUrl={workingCopy.imageUrl}
                    onImageAdded={image => handleImageInputChange(image)}
                  />
                  <div className='instructions text-muted'>{I18N('image_requirements')}</div>
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-md-5'>
                  <label>{I18N('height')}</label>
                  <input
                    type='number'
                    min={0}
                    max={10}
                    step={0.01}
                    placeholder={logoHeight || '3'}
                    value={height}
                    onChange={generateDimensionsChangeHandler('height')}
                  />
                </div>
                <div className='form-group col-md-5'>
                  <label>{I18N('width')}</label>
                  <input
                    type='number'
                    min={0}
                    max={10}
                    step={0.01}
                    placeholder={logoWidth || 'auto'}
                    value={width}
                    onChange={generateDimensionsChangeHandler('width')}
                  />
                </div>
              </div>
            </div>

            <div className='col-lg-5'>
              <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('start_time'), currentTimezone())}</label>
              <div className='row'>
                <div className='col-md-10 mb-3'>
                  <DatePicker
                    id='cy_logo_editor_start_time'
                    className='ArticleEdidor-publishedAtPicker'
                    selected={workingCopy.startTime && i18nMoment(workingCopy.startTime)}
                    onChange={generateTimeChangeHandler('startTime')}
                    showTimeSelect
                    isClearable
                    dateFormat='LLL'
                  />
                </div>
              </div>

              <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('end_time'), currentTimezone())}</label>
              <div className='row'>
                <div className='col-md-10 mb-3'>
                  <DatePicker
                    id='cy_logo_editor_end_time'
                    className='ArticleEdidor-publishedAtPicker'
                    selected={workingCopy.endTime && i18nMoment(workingCopy.endTime)}
                    onChange={generateTimeChangeHandler('endTime')}
                    showTimeSelect
                    isClearable
                    dateFormat='LLL'
                  />
                </div>
              </div>

              {isNewLogo ? (
                <div>
                  <Button disabled={shouldDisableCreateButton} onClick={saveLogo}>
                    {I18N('submit')}
                  </Button>
                </div>
              ) : (
                <div>
                  <Button disabled={!changesPresent} onClick={saveLogo}>
                    {I18N('update')}
                  </Button>
                  <Button variant='danger' className='ml-1' onClick={deleteLogo}>
                    {I18N('delete')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default LogoEditorPage
