import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import moment from 'moment'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultStepTemplateAttributes } from 'utils/journeys/templatesDefaultHelpers'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'

const I18N = i18nPath('views.admin.journeys.communication_templates')
export const COMMUNICATION_TEMPLATE_ATTRIBUTES = [
  'subject',
  'content',
  'contentId',
  'sendToEmail',
  'createFeedCard',
  'replyToSetting',
  'sendToPersonalEmail',
  'sendSms',
  'feedTitle',
  'feedContent',
  'smsContent',
]
export const COMMUNICATION_TEMPLATE_INTERPOLATED_FIELDS = ['subject', 'content', 'feedTitle', 'feedContent', 'smsContent']

const buildCommunicationTemplatePayload = (communicationTemplate) => {
  const communicationTemplateAttrs = _.pick(communicationTemplate, [...COMMUNICATION_TEMPLATE_ATTRIBUTES, 'id'])

  if (communicationTemplate.stepTemplateAttributes) {
    communicationTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      communicationTemplate.stepTemplateAttributes
    )
  }

  return communicationTemplateAttrs
}

export const defaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepTemplateAttributes(journeyBlueprintType),
  subject: '',
  content: '',
  feedTitle: '',
  feedContent: '',
  sendToEmail: true,
  createFeedCard: true,
  sendToPersonalEmail: false,
  sendSms: false,
  smsContent: '',
})

export const initialState = {
  meta: {
    ...defaultMeta,
    isSendingPreviewEmail: false,
    isSendingPreviewSms: false,
  },
}

const slice = createSlice({
  name: 'communicationTemplates',
  initialState,
  reducers: {
    ...defaultActions,

    isSendingPreviewEmail(state, action) {
      state.meta.isSendingPreviewEmail = action.payload
    },

    isSendingPreviewSms(state, action) {
      state.meta.isSendingPreviewSms = action.payload
    },
  },
})

const asyncActions = {
  admin: {
    fetchCommunicationTemplate: id => async (dispatch) => {
      dispatch(communicationTemplateSlice.actions.isLoading(true))
      dispatch(communicationTemplateSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.communicationTemplates.fetch(id)
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(communicationTemplateSlice.actions.isNotFound(true))
      } finally {
        dispatch(communicationTemplateSlice.actions.isLoading(false))
      }
    },

    createCommunicationTemplate: (communicationTemplate, onSuccess = () => {}) => async (dispatch) => {
      dispatch(communicationTemplateSlice.actions.isSaving(true))

      try {
        const response = await API.admin.communicationTemplates.create(
          buildCommunicationTemplatePayload(communicationTemplate)
        )
        dispatch(entitySlice.actions.add({ data: response.data }))
        const communicationTemplateId = response.data.data.id

        if (communicationTemplate?.stepTemplateAttributes?.state === 'draft') {
          dispatch(showToastMessage({ message: I18N('draft_saved'), type: 'success' }))
        } else {
          dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
        }

        onSuccess(communicationTemplateId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationTemplateSlice.actions.setError(error))
      } finally {
        dispatch(communicationTemplateSlice.actions.isSaving(false))
      }
    },

    updateCommunicationTemplate: (workingCopy, isPublishing = false) => async (dispatch) => {
      dispatch(communicationTemplateSlice.actions.isSaving(true))

      try {
        const response = await API.admin.communicationTemplates.update(buildCommunicationTemplatePayload(workingCopy))
        dispatch(entitySlice.actions.update({ data: response.data }))

        if (isPublishing) {
          dispatch(showToastMessage({ message: I18N('successfully_published'), type: 'success' }))
        }
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationTemplateSlice.actions.setError(error))
      } finally {
        dispatch(communicationTemplateSlice.actions.isSaving(false))
      }
    },

    sendPreviewEmail: (id, userIds, onSuccess = () => {}) => async (dispatch) => {
      dispatch(communicationTemplateSlice.actions.isSendingPreviewEmail(true))

      try {
        await API.admin.communicationTemplates.sendPreviewEmail(id, userIds)
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationTemplateSlice.actions.setError(error))
      } finally {
        dispatch(communicationTemplateSlice.actions.isSendingPreviewEmail(false))
      }
    },

    sendPreviewSms: (id, userIds, onSuccess = () => {}) => async (dispatch) => {
      dispatch(communicationTemplateSlice.actions.isSendingPreviewSms(true))

      try {
        await API.admin.communicationTemplates.sendPreviewSms(id, userIds)
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationTemplateSlice.actions.setError(error))
      } finally {
        dispatch(communicationTemplateSlice.actions.isSendingPreviewSms(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => state => state.communicationTemplates.meta,

  getCommunicationTemplate: id => state => build(state.entities, 'communicationTemplate', id),
}

const communicationTemplateSlice = {
  ...slice,
  asyncActions,
  selectors,
}

export default communicationTemplateSlice
