import React, { useEffect, useState } from 'react'
import Card from 'components/common/card'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import classNames from 'classnames'
import { marked } from 'marked'
import CdnSvg from 'components/common/cdnSvg'
import useSSE from 'components/common/hooks/useSSE'
import API from 'services/api'
import useQueryParams from 'components/common/hooks/useQueryParams'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import BetaTag from 'components/common/tags/betaTag'
import { AssistantAnswerStatuses, AssistantAnswerType } from 'types/ai/assistantAnswer'
import Sources from 'components/search/ai/sources'
import Feedback from 'components/search/ai/feedback'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'

const NOT_ANSWERED_RESPONSE = 'We were unable to generate any answers about this topic.'

const I18N = i18nPath('views.search.search_results.ai_answer')

const AiOverview = ({
  title = I18N('title'),
  className = '',
}) => {
  const [assistantAnswer, setAssistantAnswer] = useState<AssistantAnswerType | null>(null)
  const sources = assistantAnswer?.sources || []
  const [content, setContent] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { query } = useQueryParams()

  useEffect(() => {
    setContent('')
    setAssistantAnswer(null)
    setIsLoading(true)
  }, [query])

  useSSE('/api/ai/assistant/answers', {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
      payload: JSON.stringify({ query }),
    },
    dependencies: [query],
    listeners: {
      'delta-received': (event) => {
        setContent(prev => prev + event.data)
      },
      'workflow-finished': (event) => {
        const data = JSON.parse(event.data)
        if (!data?.data) {
          setContent(prev => prev || NOT_ANSWERED_RESPONSE)
        } else {
          const aiAssistantAnswer = denormalizedJsonApiResponse({ data }, 'aiAssistantAnswer')
          setContent(aiAssistantAnswer.answer)
          setAssistantAnswer(aiAssistantAnswer)
        }
        setIsLoading(false)
      },
    },
    listen: !!query,
  })

  if (!query) {
    return null
  }

  return (
    <Card className={classNames('AiOverview mb-4', className)}>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <div className='d-flex align-items-center gap-2'>
          <CdnSvg className='svg-highlight' src='/images/aiIcon.svg' />
          <div className='font-weight-600 text-highlight'>{title}</div>
        </div>
        <BetaTag />
      </div>
      {!content && isLoading && <CirclesLoadingIndicator />}
      {content && (
        <div className='px-4'>
          <AiAnswerContent content={content} />
          {assistantAnswer && assistantAnswer?.status !== AssistantAnswerStatuses.NotAnswered && (
            <>
              <Sources sources={sources} />
              <hr className='my-3 w-100' />
              <div className='d-flex justify-content-between align-items-center'>
                <div className='text-secondary text-small'>
                  {I18N('inaccurate_content_warning')}
                </div>
                <Feedback
                  assistantAnswer={assistantAnswer}
                  setAssistantAnswer={setAssistantAnswer}
                />
              </div>
            </>
          )}
        </div>
      )}
    </Card>
  )
}


export default AiOverview
