import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { i18nPath } from 'utils/i18nHelpers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import buildByIdOrSlugFromEntitiesStore from 'redux/slices/utils/buildByIdOrSlugFromEntitiesStore'

import { showToastMessage } from 'redux/slices/toasts'
import entitySlice from 'redux/slices/entities'
import groupMembershipSlice from 'redux/slices/groupMemberships'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'

const I18N = i18nPath('views.profile')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildTeamPayload = (team) => {
  const payload = _.pick(team, [
    'id',
    'name',
    'groupTypeId',
    'active',
    'classification',
    'parentId',
    'description',
    'email',
    'coverImage',
    'coverImageCroppedArea',
    'links',
  ])

  payload.slackChannelId = team.slackChannel?.id
  payload.msTeamChannelId = team.msTeamChannel?.id

  return payload
}

export const initialState = {
  newsFeedIds: [],
  currentUserTeamIds: [],
  currentUserFollowedTeamIds: [],
  teamIds: [],
  meta: {
    error: null,
    profilePhotoError: null,
    currentUserTeamsLoadingError: false,
    isPhotoUploading: false,
    isPhotoSavingComplete: false,
    isFetchingNewsFeed: false,
    newsFeedQueryParams: defaultPaginationParams,
    isLoading: true,
    isLoadingAllTeams: false,
    isLoadingCurrentUserTeams: false,
  },
}

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    // This is where we want to expand our generic handling of errors from the Rails backend
    //   * Generic string error like 500's
    //   * Form based, return all fields that are populating a model.error and the standardized message per field, using this to create inline error messages
    setError(state, action) {
      state.meta.error = action.payload
    },

    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    setIsLoadingAllTeams(state, action) {
      state.meta.isLoadingAllTeams = action.payload
    },

    setProfilePhotoError(state, action) {
      state.meta.profilePhotoError = action.payload.message
    },

    setCurrentUserTeamsLoadingError(state, action) {
      state.meta.currentUserTeamsLoadingError = action.payload
    },

    isPhotoUploading(state, action) {
      state.meta.isPhotoUploading = action.payload
    },

    isPhotoSavingComplete(state, action) {
      state.meta.isPhotoSavingComplete = action.payload
    },

    setIsFetchingNewsFeed(state, action) {
      state.meta.isFetchingNewsFeed = action.payload
    },

    setNewsFeedQueryParams(state, action) {
      state.meta.newsFeedQueryParams = action.payload
    },

    setNewsFeedIds(state, action) {
      state.newsFeedIds = Array.from(new Set([...state.newsFeedIds, ...action.payload]))
    },

    resetNewsFeedIds(state) {
      state.newsFeedIds = []
    },

    setIsLoadingCurrentUserTeams(state, action) {
      state.meta.isLoadingCurrentUserTeams = action.payload
    },

    setCurrentUserTeamIds(state, action) {
      state.currentUserTeamIds = action.payload
    },

    setCurrentUserFollowedTeamIds(state, action) {
      state.currentUserFollowedTeamIds = action.payload
    },

    setTeamIds(state, action) {
      state.teamIds = action.payload
    },

    clearTeamIds(state) {
      state.teamIds = []
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetch: (teamIdOrSlug, options = { useSavedTeam: false }) => async (dispatch, getState) => {
    const savedTeam = buildByIdOrSlugFromEntitiesStore(teamIdOrSlug, 'group', getState())

    if (options.useSavedTeam && savedTeam?.leadIds) {
      dispatch(groupMembershipSlice.actions.setLeadUserIds(
        { teamId: savedTeam.id, userIds: savedTeam.leadIds }
      ))
    } else {
      dispatch(groupSlice.actions.setIsLoading(true))
    }

    try {
      const response = await API.groups.fetch(teamIdOrSlug)

      dispatch(groupMembershipSlice.actions.setLeadUserIds(
        { teamId: response.data.data.id, userIds: response.data.data.attributes.leadIds }
      ))
      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(groupSlice.actions.setIsLoading(false))
    }
  },

  fetchAll: params => async (dispatch) => {
    dispatch(groupSlice.actions.setIsLoadingAllTeams(true))
    try {
      const response = await API.groups.fetchAll(params)

      const teamIds = response.data.data.map(team => team.id)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(groupSlice.actions.setTeamIds(teamIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)

      dispatch(groupSlice.actions.setError(e))
    } finally {
      dispatch(groupSlice.actions.setIsLoadingAllTeams(false))
    }
  },

  fetchCurrentUserTeams: () => async (dispatch) => {
    dispatch(groupSlice.actions.setIsLoadingCurrentUserTeams(true))

    try {
      const response = await API.groups.fetchCurrentUserGroups()

      const userGroupIds = response.data.data.relationships.activeGroups.data.map(group => group.id)
      const userFollowedGroupIds = response.data.data.relationships.activeFollowedGroups.data.map(group => group.id)

      dispatch(groupSlice.actions.setCurrentUserTeamIds(userGroupIds))
      dispatch(groupSlice.actions.setCurrentUserFollowedTeamIds(userFollowedGroupIds))

      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(groupSlice.actions.setCurrentUserTeamsLoadingError(true))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(groupSlice.actions.setIsLoadingCurrentUserTeams(false))
    }
  },

  fetchNewsFeed: (teamId, tagId, queryParams = {}) => async (dispatch) => {
    dispatch(groupSlice.actions.setIsFetchingNewsFeed(true))

    try {
      const response = await API.groups.fetchNewsFeed({
        ...queryParams,
        groupId: teamId,
        tagId,
      })
      const newQueryParams = queryParamsFromHeaders(response)
      const newsFeedIds = response.data.data.map(feed => feed.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(groupSlice.actions.setNewsFeedIds(newsFeedIds))
      dispatch(groupSlice.actions.setNewsFeedQueryParams(newQueryParams))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(groupSlice.actions.setIsFetchingNewsFeed(false))
    }
  },

  createPhoto: (photo, team, croppedAreaPixels) => async (dispatch) => {
    dispatch(groupSlice.actions.isPhotoUploading(true))
    dispatch(groupSlice.actions.isPhotoSavingComplete(false))

    try {
      await API.groups.photos.create(photo, team, croppedAreaPixels).then(() => {
        dispatch(asyncActions.fetch(team.id))
      })
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(groupSlice.actions.setProfilePhotoError({ message: I18N('photos.filetype_error') }))
      dispatch(showToastMessage({ message: error.message, type: 'error' }))
    } finally {
      dispatch(groupSlice.actions.isPhotoUploading(false))
      dispatch(groupSlice.actions.isPhotoSavingComplete(true))
    }
  },

  destroyPhoto: (photo, team) => async (dispatch) => {
    dispatch(groupSlice.actions.isPhotoUploading(true))
    dispatch(groupSlice.actions.isPhotoSavingComplete(false))

    try {
      const response = await API.groups.photos.destroy(photo)

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(entitySlice.actions.remove({ id: photo.id, type: 'photo' }))

      dispatch(asyncActions.fetch(team.id))
    } catch (e) {
      dispatch(groupSlice.actions.setProfilePhotoError({ message: I18N('failed_destroy_photo') }))
      dispatch(showToastMessage({ message: I18N('failed_destroy_photo'), type: 'error' }))
    } finally {
      dispatch(groupSlice.actions.isPhotoUploading(false))
      dispatch(groupSlice.actions.isPhotoSavingComplete(true))
    }
  },

  promotePhoto: (photo, team) => async (dispatch) => {
    dispatch(groupSlice.actions.isPhotoUploading(true))
    dispatch(groupSlice.actions.isPhotoSavingComplete(false))

    try {
      await API.groups.photos.promote(photo).then(() => {
        dispatch(asyncActions.fetch(team.id))
      })
    } catch (e) {
      dispatch(groupSlice.actions.setProfilePhotoError({ message: I18N('failed_promote_photo') }))
      dispatch(showToastMessage({ message: I18N('failed_promote_photo'), type: 'error' }))
    } finally {
      dispatch(groupSlice.actions.isPhotoUploading(false))
      dispatch(groupSlice.actions.isPhotoSavingComplete(true))
    }
  },

  updatePhoto: (photo, team) => async (dispatch) => {
    dispatch(groupSlice.actions.isPhotoUploading(true))
    dispatch(groupSlice.actions.isPhotoSavingComplete(false))

    try {
      const response = await API.groups.photos.update(photo)
      dispatch(entitySlice.actions.update(response))
    } catch (e) {
      dispatch(groupSlice.actions.setProfilePhotoError({ message: I18N('failed_update_photo') }))
      dispatch(showToastMessage({ message: I18N('failed_update_photo'), type: 'error' }))
    } finally {
      dispatch(groupSlice.actions.isPhotoUploading(false))
      dispatch(groupSlice.actions.isPhotoSavingComplete(true))
    }
  },

  update: team => async (dispatch) => {
    try {
      const response = await API.groups.update(buildTeamPayload(team))
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18n.t('views.groups.group_page.team_updated'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    }
  },

  removeCoverImage: team => async (dispatch) => {
    try {
      const response = await API.groups.removeCoverImage(team.id)
      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      const { error } = checkForError(e.response)
      dispatch(showToastMessage({ message: error, type: 'error' }))
    }
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.groups.meta,

  getTeam: idOrSlug => state => buildByIdOrSlugFromEntitiesStore(idOrSlug, 'group', state) || {},

  getNewsFeed: () => state => state.groups.newsFeedIds.map(id => build(state.entities, 'feedItem', id)),

  getCurrentUserTeams: () => (state) => {
    const {
      currentUserTeamIds,
      currentUserFollowedTeamIds,
      meta: {
        isLoadingCurrentUserTeams,
        currentUserTeamsLoadingError,
      },
    } = state.groups

    const myTeams = currentUserTeamIds.map(id => build(state.entities, 'group', id))
    const followedTeams = currentUserFollowedTeamIds.map(id => build(state.entities, 'group', id))

    return {
      myTeams,
      followedTeams,
      isLoadingCurrentUserTeams,
      currentUserTeamsLoadingError,
    }
  },

  getTeams: () => state => state.groups.teamIds?.map(id => build(state.entities, 'group', id)) || [],
}

export default {
  ...groupSlice,
  selectors,
  asyncActions,
}
