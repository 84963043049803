import React, { useEffect, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'

import CdnSvg from 'components/common/cdnSvg'
import ReactSelect from 'components/common/react_select'

const I18N = i18nPath('views.admin.ai.playground')

export const DEFAULT_TEMPERATURE = 0.5
export const DEFAULT_MODEL = 'anthropic.claude-v2:1'
export const DEFAULT_MAX_TOKENS = 2000
const OPEN_AI_MAX_TOKENS = 8192
const MAX_TOKENS = 20000
const copyIconPath = '/images/copyIcon.svg'

const MODEL_OPTIONS = [
  { value: 'anthropic.claude-v2:1', label: 'Claude v2.1' },
  { value: 'anthropic.claude-v2', label: 'Claude v2' },
  { value: 'anthropic.claude-instant-v1', label: 'Claude Instant' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  { value: 'gpt-4', label: 'GPT-4' },
  { value: 'gpt-4-1106-preview', label: 'GPT-4 Turbo' },
]

const PromptTabContent = ({
  prompt,
  setPrompt,
  customSystemMessage,
  setCustomSystemMessage,
  model,
  setModel,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  isSaving,
  onGenerateText,
}) => (
  <>
    <div className='mt-4'>
      <strong>{I18N('prompt')}</strong>
      <span className='pointer' onClick={() => navigator.clipboard.writeText(prompt + customSystemMessage)}>
        <CdnSvg src={copyIconPath} className='CopyIcon ml-2' />
      </span>
    </div>
    <div className='mt-2'>
      <textarea
        className='form-control'
        rows={5}
        value={prompt}
        onChange={e => setPrompt(e.target.value)}
      />
    </div>
    <details className='my-2'>
      <summary>{I18N('custom_system_message')}</summary>
      <textarea
        className='form-control mt-2'
        rows={10}
        value={customSystemMessage}
        onChange={e => setCustomSystemMessage(e.target.value)}
      />
    </details>
    <div className='mt-2'>
      <strong>{I18N('model_parameters')}</strong>
      <div className='d-flex align-items-center justify-content-center my-2'>
        <label className='form-label mb-0'>{I18N('model')}</label>
        <ReactSelect
          name='content_alignment'
          className='mx-2 w-100'
          value={MODEL_OPTIONS.find(option => model === option.value)}
          options={MODEL_OPTIONS}
          onChange={newModel => setModel(newModel.value)}
        />
        <label className='form-label mb-0'>{I18N('temperature')}</label>
        <input
          type='number'
          className='form-control mx-2 mb-0'
          value={temperature}
          min={0}
          max={2}
          step={0.1}
          onChange={e => setTemperature(e.target.value)}
        />
        <label className='form-label mb-0'>{I18N('max_tokens')}</label>
        <input
          type='number'
          className='form-control mx-2'
          value={maxTokens}
          min={1}
          max={model.includes('gpt') ? OPEN_AI_MAX_TOKENS : MAX_TOKENS}
          onChange={e => setMaxTokens(e.target.value)}
        />
      </div>
    </div>
    <Button
      className='mt-3'
      onClick={onGenerateText}
      disabled={isSaving || !prompt}
      showLoadingSpinner={isSaving}
    >
      {I18N('generate_text')}
    </Button>
  </>
)

export default PromptTabContent
