import React from 'react'

import DateRangePicker from 'components/form_fields/dateRangePicker'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import DatePicker from 'components/form_fields/datePicker'

const I18N = i18nPath('views.admin.metrics')

interface Props {
  metric: string
  filter: string
  setFilter: (filter: string) => void
  startDate: string
  setStartDate: (startDate?: string) => void
  endDate: string
  setEndDate: (endDate?: string) => void
}

const FilterSelector = ({
  metric,
  filter,
  setFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) => {
  const { appName } = useCurrentCompany()
  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange[0])
    setEndDate(dateRange[1])
  }

  const availableFilters = {
    articles: [
      { value: 'all', label: I18N('filters.articles.all', { appName }) },
      { value: 'published_at', hasDateRangePicker: true, label: I18N('filters.articles.published_at') },
    ],
    shoutouts: [
      { value: 'all', label: I18N('filters.shoutouts.all', { appName }) },
      { value: 'created_at', hasDateRangePicker: true, label: I18N('filters.shoutouts.created_at') },
    ],
    golinks: [
      { value: 'all', label: I18N('filters.golinks.all', { appName }) },
      { value: 'created_at', hasDateRangePicker: true, label: I18N('filters.golinks.created_at') },
    ],
    users: [
      { value: 'all', label: I18N('filters.users.all', { appName }) },
      { value: 'start_date', hasDateRangePicker: true, label: I18N('filters.users.start_date') },
      { value: 'end_date', hasDateRangePicker: true, label: I18N('filters.users.end_date') },
    ],
    birthdays: [
      { value: 'birthday', hasDateRangePicker: true, label: I18N('filters.birthdays.birthday') },
    ],
    work_anniversaries: [
      { value: 'work_anniversary', hasDateRangePicker: true, label: I18N('filters.work_anniversaries.work_anniversary') },
    ],
    new_employees: [
      { value: 'start_date', hasDateRangePicker: true, label: I18N('filters.new_employees.start_date') },
    ],
    all_companies_articles_sla_summary: [
      { value: 'published_at', hasDateRangePicker: true, label: I18N('filters.articles.published_at') },
    ],
    all_companies_articles_sla_detailed: [
      { value: 'published_at', hasDateRangePicker: true, label: I18N('filters.articles.published_at') },
    ],
    doordash_daily_users_by_department: [
      { value: 'doordash', hasDateRangePicker: true, label: I18N('filters.doordash.date_range') },
    ],
    doordash_all_users_at_date: [
      { value: 'doordash', hasDatePicker: true, label: I18N('filters.doordash.date') },
    ],
  }

  return (
    <div className='FilterSelector mt-4 w-100 w-lg-75'>
      {(availableFilters[metric] || []).map(({
        value, hasDateRangePicker, label, hasDatePicker,
      }) => (
        <React.Fragment key={`${metric}-${value}`}>
          <div className='form-check pl-0 mb-2'>
            <input
              id={`${metric}-${value}`}
              type='radio'
              checked={filter === value}
              onChange={() => setFilter(value)}
            />
            <label htmlFor={`${metric}-${value}`}>{label}</label>
          </div>
          {hasDateRangePicker && filter === value && (
            <div className='OnlyDate d-flex align-items-center mb-2'>
              <DateRangePicker
                dateRange={[startDate, endDate]}
                setDateRange={handleDateRangeChange}
              />
            </div>
          )}
          {hasDatePicker && filter === value && (
            <div className='OnlyDate d-flex align-items-center mb-2'>
              <DatePicker
                selected={endDate ? i18nMoment(endDate) : undefined}
                onChange={date => setEndDate(date ? date.format() : undefined)}
                isClearable
                showTimeSelect={false}
                dateFormat='LL'
                className='w-100 EndDate'
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default FilterSelector
