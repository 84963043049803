import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import FormCheck from 'components/form_fields/formCheck'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.step_templates')

const WeekendTriggerOptions = ({ workingCopy, updateWorkingCopy }) => {
  const { weekendTriggerShift } = workingCopy

  const onChange = ({ target }) => {
    if (target.checked) {
      updateWorkingCopy({ weekendTriggerShift: 'next_weekday' })
    } else {
      updateWorkingCopy({ weekendTriggerShift: null })
    }
  }

  return (
    <>
      <FormCheck
        name='trigger_on_weekdays_only'
        label={I18N('only_weekdays')}
        checked={!!weekendTriggerShift}
        onChange={onChange}
        id='trigger_on_weekdays_only'
        className='mb-3'
      />

      {!!weekendTriggerShift && (
        <>
          <p className='text-small text-secondary mb-0'>{I18N('only_weekdays_label')}</p>
          <RadioGroup
            className='d-flex align-items-center justify-content-start'
            name='weekend_trigger_shift'
            selectedValue={weekendTriggerShift}
            onChange={(value: string) => updateWorkingCopy({ weekendTriggerShift: value })}
          >
            <Radio className='mr-2' value='next_weekday' /> {I18N('next')}
            <Radio className='mr-2 ml-3' value='previous_weekday' /> {I18N('previous')}
          </RadioGroup>
        </>
      )}
    </>
  )
}

export default WeekendTriggerOptions
