import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import API from 'services/api'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import { LoadingContainer } from 'components/common/loadingContainer'
import { i18nPath } from 'utils/i18nHelpers'
import { showToastMessage } from 'redux/slices/toasts'
import RoundedAvatars from 'components/common/roundedAvatars'
import FileSelectDropzone from 'components/common/file_select_dropzone'

const I18N = i18nPath('views.admin.includes.audience_selector.csv_list_import')

const CsvAudienceUploader = ({
  users,
  setUsers,
  header = I18N('upload_a_list'),
  description = I18N('upload_a_list_description'),
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)

  const removeFile = () => {
    setFile(null)
    setUsers([])
  }

  const onDrop = async (files) => {
    if (files.length === 0) {
      return
    }

    setIsLoading(true)
    const newFile = files[0]
    setFile(newFile)
    const text = await newFile.text()

    try {
      const response = await API.users.fetchFromCSV({ csvBody: text })
      const results = denormalizedJsonApiResponse(response, 'simpleUser')
      if (results.length === 0) {
        dispatch(showToastMessage({ message: I18N('errors.no_results'), type: 'error' }))
      } else {
        setUsers(results)
      }
    } catch (e) {
      dispatch(showToastMessage({ message: e?.response?.data?.error?.message, type: 'error' }))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='CsvAudienceUploader'>
      <h4 className='mb-2'>
        {header}
      </h4>
      <p className='mb-3 text-secondary'>{description}</p>
      <LoadingContainer isLoading={isLoading}>
        <>
          {file ? (
            <>
              <h5>{I18N('selected_people')}</h5>
              <RoundedAvatars users={users} className='mb-3' shownAvatars={10} />
              <a className='text-small link-color' onClick={removeFile}>{I18N('select_different_file')}</a>
            </>
          ) : (
            <FileSelectDropzone
              data-testid='cy_dropzone_file_selector'
              onDrop={onDrop}
              accept='.csv'
              disabled={isLoading}
              className='CsvSelector-dropzone'
            >
              <span
                className='text-secondary'
                data-cy='upload-file-text'
                dangerouslySetInnerHTML={{ __html: I18N('drop_files_here') }}
              />
            </FileSelectDropzone>
          )}
        </>
      </LoadingContainer>
    </div>
  )
}


export default CsvAudienceUploader
