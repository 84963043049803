/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import EmployeeSearch from 'components/form_fields/employeeSearch'
import ReactSelect from 'components/common/react_select'
import { ButtonSmallNarrow } from 'components/common/buttons'
import FormCheck from 'components/form_fields/formCheck'
import Card from 'components/common/card'

import groupMembershipSlice, { buildMembershipPayload } from 'redux/slices/groupMemberships'

const I18N = i18nPath('views.admin.group_memberships_editor')

const GroupMembershipsEditor = ({ group }) => {
  const dispatch = useDispatch()
  const groupMemberships = useSelector(groupMembershipSlice.selectors.getMemberships())
  const { source, external: isExternal } = group

  useEffect(() => {
    dispatch(groupMembershipSlice.asyncActions.fetchAll(group.id))

    return (() => dispatch(groupMembershipSlice.actions.clear()))
  }, [])

  const createGroupMembership = (user) => {
    dispatch(groupMembershipSlice.asyncActions.addPerson(group, user))
  }

  return (
    <>
      <h6 className='text-secondary align-self-start'>{I18N('members')}</h6>
      <Card className='mb-4'>
        {!isExternal && (
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <EmployeeSearch onChange={createGroupMembership} selectedEmployee={null} />
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col-md-12'>
            {isExternal && (
              <div className='text-secondary text-small mt-1'>{I18n.t('views.admin.group_editor.external_source_helper', { externalSource: source })}</div>
            )}
            <table>
              <thead>
                <tr>
                  <th>{I18N('name')}</th>
                  <th>{I18N('assigned_on')}</th>
                  <th style={{ width: '7%' }}>{I18N('lead')}</th>
                  {group.groupType.useLabelsToCategorizeMembers && (
                    <>
                      <th>{I18N('group')}</th>
                      <th>{I18N('role')}</th>
                    </>
                  )}
                  <th style={{ width: '25%' }}>{I18N('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {groupMemberships.map(groupMembership => (
                  <GroupMembershipRow
                    key={`groupMembership-${groupMembership.id}`}
                    groupMembership={groupMembership}
                    isExternal={isExternal}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  )
}

const GroupMembershipRow = ({ groupMembership, isExternal }) => {
  const dispatch = useDispatch()
  const [workingCopy, setWorkingCopy] = useState(groupMembership)
  const areChangesPresent = !_.isEqual(buildMembershipPayload(groupMembership), buildMembershipPayload(workingCopy))

  const deleteGroupMembership = () => {
    dispatch(groupMembershipSlice.asyncActions.removePerson(groupMembership.group, groupMembership.user))
  }

  const updateGroupMembership = () => {
    const payload = buildMembershipPayload(workingCopy)
    dispatch(groupMembershipSlice.asyncActions.update(payload))
  }

  return (
    <tr>
      <td>
        {groupMembership.user.preferredFullName}
        {' '}
        {!groupMembership.user.active && (
          <span className='text-error'>
            {' '}
            {I18N('inactive_label')}
          </span>
        )}
      </td>
      <td>{i18nMoment(groupMembership.createdAt).format('ll')}</td>
      <td>
        <FormCheck
          checked={workingCopy.lead}
          onChange={e => setWorkingCopy({ ...workingCopy, lead: e.target.checked })}
        />
      </td>
      {groupMembership.group.groupType.useLabelsToCategorizeMembers && (
        <>
          <td>
            <ReactSelect
              id='cy_group_type_label_select'
              value={workingCopy.groupTypeLabel}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              onChange={label => setWorkingCopy({ ...workingCopy, groupTypeLabel: label })}
              isClearable
              options={groupMembership.group.groupType.groupTypeLabels}
            />
          </td>
          <td>
            <input
              value={workingCopy.role}
              className='w-100'
              onChange={e => setWorkingCopy({ ...workingCopy, role: e.target.value })}
              disabled={workingCopy.lead}
            />
          </td>
        </>
      )}
      <td>
        <div className='d-flex'>
          <ButtonSmallNarrow
            disabled={!areChangesPresent}
            className='mr-2'
            onClick={updateGroupMembership}
          >
            {I18n.t('views.common.save')}
          </ButtonSmallNarrow>
          <ButtonSmallNarrow
            variant='danger'
            onClick={deleteGroupMembership}
            disabled={isExternal}
          >
            {I18n.t('views.common.remove')}
          </ButtonSmallNarrow>
        </div>
      </td>
    </tr>
  )
}

export default GroupMembershipsEditor
