import { createSlice } from '@reduxjs/toolkit'
import entitySlice from 'redux/slices/entities'
import build from 'redux-object'
import { checkForError } from 'utils/errorHandling'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const buildDefaultPermissionPayload = (defaultPermission) => {
  const defaultPermissionAttrs = _.pick(defaultPermission, ['id', 'permission'])

  if (defaultPermission.targetingRules) {
    defaultPermissionAttrs.targetingRules = normalizeTargetingRules(defaultPermission.targetingRules)
  }

  return defaultPermissionAttrs
}

const initialState = {
  meta: {
    isSaving: false,
    error: null,
    isLoading: true,
    isUnauthorized: false,
  },
}

const defaultPermissionSlice = createSlice({
  name: 'defaultPermissions',
  initialState,
  reducers: {
    setIsSaving(state, action) {
      state.meta.isSaving = action.payload
    },

    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    setError: (state, action) => {
      const { hasError, error } = checkForError(action.payload)
      state.meta.error = error
      state.meta.isUnauthorized = hasError
    },

  },
})

_.assign(defaultPermissionSlice, {
  asyncActions: {
    fetch: permission => async (dispatch) => {
      try {
        const response = await API.admin.defaultPermissions.fetch(permission)
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        // If 404 then that means the permission does not exist yet
        if (!e.response.status === 404) {
          dispatch(showToastMessage({ message: 'error fetching', type: 'warn' }))

          dispatch(defaultPermissionSlice.actions.setError(e))
        }
      } finally {
        dispatch(defaultPermissionSlice.actions.setIsLoading(false))
      }
    },
    create: permissionParams => async (dispatch) => {
      try {
        const response = await API.admin.defaultPermissions.create(buildDefaultPermissionPayload(permissionParams))
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(defaultPermissionSlice.actions.setIsSaving(true))
      } catch (e) {
        dispatch(showToastMessage({ message: 'error updating', type: 'warn' }))
      } finally {
        dispatch(defaultPermissionSlice.actions.setIsSaving(false))
        dispatch(showToastMessage({ message: 'Successfully created permission targeting', type: 'success' }))
      }
    },
    update: permissionParams => async (dispatch) => {
      try {
        const response = await API.admin.defaultPermissions.update(buildDefaultPermissionPayload(permissionParams))
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(defaultPermissionSlice.actions.setIsSaving(true))
      } catch (e) {
        dispatch(showToastMessage({ message: 'error updating', type: 'warn' }))
      } finally {
        dispatch(defaultPermissionSlice.actions.setIsSaving(false))
        dispatch(showToastMessage({ message: 'Successfully updated permission targeting', type: 'success' }))
      }
    },
  },
})

_.assign(defaultPermissionSlice, {
  selectors: {
    getMetaData: () => state => state.defaultPermission.meta,

    getDefaultPermissionData: permission => (state) => {
      const defaultPermissions = state.entities.defaultPermission
      const permissionId = Object.keys(defaultPermissions || {}).find(
        id => defaultPermissions[id]?.attributes?.permission === permission
      )

      let result

      if (permissionId) {
        result = build(state.entities, 'defaultPermission', permissionId)
      } else {
        result = { permission, targetingRules: TARGET_ENTIRE_COMPANY }
      }

      return { defaultPermission: result }
    },
  },
})

export { initialState }
export default defaultPermissionSlice
