import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import userSlice from 'redux/slices/users'
import SmartTable from 'components/common/tables/smartTable'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import StatusLabel from 'components/common/status_label/'
import ActionsDropdown from './actionsDropdown'

const I18N = i18nPath('views.admin.users')

const defaultColumns = [
  {
    header: I18N('table_header.profile'),
    col: 'col-profile',
    id: 'id',
    style: { width: '7.5%' },
    accessor: d => (
      <Link to={`/profile/${d.username}`}>
        {' '}
        <EmployeeThumbnailPhoto employee={d} size='35px' />
      </Link>
    ),
  },
  {
    header: I18N('table_header.preferred_full_name'),
    col: 'col-name',
    id: 'first_name',
    style: { width: '22.5%' },
    accessor: d => <Link to={`/admin/users/${d.id}`}>{d.preferredFullName}</Link>,
  },
  {
    header: I18N('table_header.username'),
    col: 'col-username',
    id: 'username',
    style: { width: '12.5%' },
    accessor: d => <span className='overflow-wrap-break-word'>{d.username}</span>,
  },
  {
    header: I18N('table_header.emails'),
    col: 'col-email',
    style: { width: '22.5%' },
    accessor: d => <span className='overflow-wrap-break-word'>{[d.email].concat(d.emails).join(', ')}</span>,
  },
  {
    header: I18N('table_header.created_at'),
    col: 'col-created_at',
    id: 'created_at',
    style: { width: '15%' },
    accessor: d => i18nMoment(d.createdAt).format('ll'),
  },
  {
    header: I18N('table_header.status'),
    col: 'col-active',
    id: 'active',
    style: { width: '12.5%' },
    accessor: d => <UserActiveStatusLabel user={d} />,
  },
]

const newHiresPreboardingColumns = [
  {
    header: I18N('table_header.profile'),
    col: 'col-profile',
    id: 'id',
    style: { width: '7.5%' },
    accessor: d => (
      <Link to={`/profile/${d.username}`}>
        {' '}
        <EmployeeThumbnailPhoto employee={d} size='35px' />
      </Link>
    ),
  },
  {
    header: I18N('table_header.preferred_full_name'),
    col: 'col-name',
    id: 'first_name',
    style: { width: '22.5%' },
    accessor: d => <Link to={`/admin/users/${d.id}`}>{d.preferredFullName}</Link>,
  },
  {
    header: I18N('table_header.emails'),
    col: 'col-email',
    style: { width: '22.5%' },
    accessor: d => [d.personalEmail, d.email].filter(Boolean).join(', '),
  },
  {
    header: I18N('table_header.start_date'),
    col: 'col-start-date',
    id: 'start_date',
    style: { width: '13.75%' },
    accessor: d => d.startDate && i18nMoment(d.startDate).format('ll'),
  },
  {
    header: I18N('table_header.created_at'),
    col: 'col-created_at',
    id: 'created_at',
    style: { width: '13.75%' },
    accessor: d => i18nMoment(d.createdAt).format('ll'),
  },
  {
    header: I18N('table_header.status'),
    col: 'col-active',
    id: 'active',
    style: { width: '12.5%' },
    accessor: d => <UserActiveStatusLabel user={d} />,
  },
]

const actionNeededColumns = fetchUsers => [
  {
    header: I18N('table_header.profile'),
    col: 'col-profile',
    id: 'id',
    style: { width: '7.5%' },
    accessor: d => (
      <Link to={`/profile/${d.username}`}>
        {' '}
        <EmployeeThumbnailPhoto employee={d} size='35px' />
      </Link>
    ),
  },
  {
    header: I18N('table_header.preferred_full_name'),
    col: 'col-name',
    id: 'first_name',
    style: { width: '22.5%' },
    accessor: d => <Link to={`/admin/users/${d.id}`}>{d.preferredFullName}</Link>,
  },
  {
    header: I18N('table_header.emails'),
    col: 'col-email',
    style: { width: '22.5%' },
    accessor: d => [d.personalEmail, d.email].filter(Boolean).join(', '),
  },
  {
    header: I18N('table_header.start_date'),
    col: 'col-start-date',
    id: 'start_date',
    style: { width: '15%' },
    accessor: d => (d.startDate ? i18nMoment(d.startDate).format('ll') : <span className='text-error'>{I18N('missing')}</span>),
  },
  {
    header: I18N('table_header.created_at'),
    col: 'col-created_at',
    id: 'created_at',
    style: { width: '15%' },
    accessor: d => i18nMoment(d.createdAt).format('ll'),
  },
  {
    header: I18N('table_header.actions'),
    col: 'col-active',
    id: 'actions',
    style: { width: '10%' },
    accessor: d => <ActionsDropdown user={d} fetchUsers={fetchUsers} />,
  },
]

const UserActiveStatusLabel = ({ user }) => (
  <StatusLabel
    className='text-normal'
    color={user.active ? 'green' : 'red'}
  >
    {I18N(user.active ? 'active' : 'inactive')}
  </StatusLabel>
)

const UsersTable = ({ fetchUsers, selectedTab, hidden }) => {
  const users = useSelector(userSlice.selectors.getUsers())
  const { queryParams } = useSelector(userSlice.selectors.getAdminUserListMetaData())

  const {
    page, totalPages, total, perPage,
  } = queryParams

  const handlePaginationClick = (paginationParams) => {
    fetchUsers(paginationParams)
  }

  const handleSortChange = (sortParams) => {
    fetchUsers({ ...sortParams, page: 1 })
  }

  const selectedColumns = () => {
    if (selectedTab === 'action_needed') {
      return actionNeededColumns(fetchUsers)
    }

    if (selectedTab === 'new_hires_preboarding') {
      return newHiresPreboardingColumns
    }

    return defaultColumns
  }

  // hiding the table to avoid unnecessary re-renders on loads
  return (
    <div className={classNames({ 'd-none': hidden })}>
      <SmartTable
        data={users}
        className='white-bg-table'
        sortKey='created_at'
        sortedAsc={false}
        showPagination={page !== null && totalPages !== null}
        columns={selectedColumns()}
        page={page}
        pages={totalPages}
        totalCount={total}
        perPage={perPage}
        onPaginationClick={handlePaginationClick}
        onSortChange={handleSortChange}
      />
    </div>
  )
}

export default UsersTable
