import React, { useState } from 'react'
import useQueryParams from 'components/common/hooks/useQueryParams'
import isAndroid from 'pages/authentication/isAndroid'
import isIos from 'pages/authentication/isIos'
import openDeepLink from 'pages/authentication/openDeepLink'
import { ButtonLink } from 'components/common/buttons'
import { I18NCommon } from 'utils/i18nHelpers'
import ExternalLinkIcon from 'components/icons/externalLinkIcon'

const ANDROID_PRODUCTION_STORE_URL = 'https://play.google.com/store/apps/details?id=com.gocleary.cleary_mobile_app'
const IOS_PRODUCTION_STORE_URL = 'https://apps.apple.com/app/cleary/id6449472335'

const buildFinalUrl = (redirectUrl: string | undefined) => {
  let finalUrl = 'gocleary://'
  // expo url
  // let finalUrl = 'exp://192.168.3.5:19000'

  if (redirectUrl) {
    finalUrl = `${finalUrl}/push_url?url=${encodeURIComponent(redirectUrl)}`
    // expo url
    // finalUrl = `exp://192.168.3.5:19000/--/push_url?url=${encodeURIComponent(redirectUrl)}`
  }

  return finalUrl
}

const OpenInMobileAppButton = () => {
  const { redirectUrl } = useQueryParams()
  const [isOpeningInMobileApp, setIsOpeningInMobileApp] = useState(false)

  if (!isIos() && !isAndroid()) return null

  const storeUrl = isAndroid() ? ANDROID_PRODUCTION_STORE_URL : IOS_PRODUCTION_STORE_URL

  const openInMobile = () => {
    openDeepLink(buildFinalUrl(redirectUrl), {
      onFail: () => {
        // redirect to store
        window.location.href = storeUrl
      },
    })
  }

  return (
    <div className='text-center mt-3'>
      <ButtonLink
        onClick={(e) => {
          e.preventDefault()
          setIsOpeningInMobileApp(true)
          openInMobile()
        }}
        variant='link'
        showLoadingSpinner={isOpeningInMobileApp}
      >
        <span className='text-secondary'>
          {I18NCommon('open_in_app')} <ExternalLinkIcon className='ExternalLinkIcon' />
        </span>
      </ButtonLink>
    </div>
  )
}

export default OpenInMobileAppButton
